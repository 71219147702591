<div class="wrap" role="document" *ngIf="czyZaladowanoDane">
    <div class="content pb-5">
        <div class="container pt-5">
            <div class="ft__01 pt-4 pt-md-5 pb-0 pb-md-3 mt-3 text-center">Ustawienia powiadomień <a
                    class="ico_info ml-md-3" [routerLink]="" (click)="pokazPodpowiedzi()"></a></div>
            <div class="ft__16bold mt-3 mb-4 mb-md-5 text-center">Lista powiadomień</div>

            <div class="box box__r2 mb-3" *ngFor="let powiadomienie of listaPowiadomien">
                <div class="box__notification">
                    <button class="btn__trash" (click)="usunPowiadomienie(powiadomienie.id)"></button>
                    <div class="ft__16bold mb-2 text-left">{{powiadomienie.nazwa}}</div>
                    <a [routerLink]="">{{powiadomienie.email}}</a>
                    <a [routerLink]="">{{powiadomienie.telefon}}</a>
                    <!-- <p>{{pobierzTrescPowiadomienia(powiadomienie.tresc)}}</p> -->
                    <p innerHTML="{{ pobierzTrescPowiadomienia(powiadomienie.tresc) }}" class="piec-linii"></p>
                    <div class="text-right">
                        <a class="btn_text_green" [routerLink]="" (click)="edytujPowiadomienie(powiadomienie.id)">
                            Otwórz
                        </a>
                    </div>
                </div>
            </div>

            <div class="ng-star-inserted" *ngIf="!czySaWynikiNaLiscie(listaPowiadomien)">
                <div class="text-center pb-4"> </div>
                <p class="ft__02 text-center pb-2">Nie masz jeszcze żadnych powiadomień. Dodaj
                    pierwsze!
                </p>
                <button class="text-center btn__add2" (click)="dodajPowiadomienie()"><span>Nowe
                        powiadomienie</span></button>


            </div>


        </div>
    </div>
    <div class="posFixed">
        <button class="btn__add" (click)="dodajPowiadomienie()"><span>Nowe
                powiadomienie</span></button>
    </div>
</div>