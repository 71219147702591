<div class="modal-body">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss()"></button>

    <p class="text-center ft__05 mb-3 pb-0 pt-2 px-sm-5 mx-sm-5">
        Czy chcesz usunąć powiadomienie dla tej osoby?
    </p>

    <div class="text-center py-4">
        <img src="assets/img/ico_x.svg" alt="">
    </div>

    <div class="d-flex justify-content-around pb-3">
        <div class="itm">
            <button class="btn__primary btn__primary--cancel" (click)="usun()">
                Tak
            </button>
        </div>
        <div class="itm">
            <button class="btn__primary btn__primary--cancel" (click)="activeModal.dismiss()">
                Nie
            </button>
        </div>
    </div>

</div>