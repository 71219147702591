import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { DaneZalogowanegoUzytkownika } from 'src/app/sekcje/konto/modele/logowanie/daneZalogowanegoUzytkownika';
import { Ciasteczko } from '../modele/ciasteczka';
import { MenuService } from './menu.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private router: Router) { }

  logIn(ciasteczko: Ciasteczko, dane: DaneZalogowanegoUzytkownika) {
    this.ustawCiasteczko(ciasteczko, dane);
    if (dane.czyMaAbonament) {
      this.router.navigate([Link.LoggedInNav]);
    }
    else {
      this.router.navigate([Link.Abonamenty]);
    }

  }

  logOut() {
    this.wyczyscCiasteczka();
    this.router.navigate([Link.LogInFormNav]);
  }

  get isLoggedIn() {
    return this.czyZalogowany();
  }

  public getUserName(): string {
    var imie = this.pobierzCiasteczko<DaneZalogowanegoUzytkownika>(Ciasteczko.DaneZalogowanegoUzytkownika).imie;
    return imie ? imie : "";
  }
  public getUserLastName(): string {
    var nazwisko = this.pobierzCiasteczko<DaneZalogowanegoUzytkownika>(Ciasteczko.DaneZalogowanegoUzytkownika).nazwisko;
    return nazwisko ? nazwisko : "";
  }

  public czyZalogowany() {
    var istnieje = localStorage.getItem(Ciasteczko.DaneZalogowanegoUzytkownika.toString());

    if (!istnieje) {
      return false;
    }

    var uzytkownik = this.pobierzCiasteczko<DaneZalogowanegoUzytkownika>(Ciasteczko.DaneZalogowanegoUzytkownika);
    if (uzytkownik != null && uzytkownik.idUzytkownika != null && uzytkownik.token != null) {
      return true;
    }
    return false;
  }

  public pobierzDaneZalogowanegoUzytkownika() {
    return this.pobierzCiasteczko<DaneZalogowanegoUzytkownika>(Ciasteczko.DaneZalogowanegoUzytkownika);
  }

  public ustawCiasteczko(ciasteczko: Ciasteczko, dane: any) {
    var istnieja = localStorage.getItem(ciasteczko.toString());
    var wartoscJson = JSON.stringify(dane);
    if (istnieja) {
      localStorage.removeItem(ciasteczko.toString());
    }
    localStorage.setItem(ciasteczko.toString(), wartoscJson);
  }

  public pobierzCiasteczko<T>(ciasteczko: Ciasteczko): T {
    if (!localStorage.getItem(ciasteczko.toString())) {
      return null;
    }
    var wartoscJosn = localStorage.getItem(ciasteczko.toString());
    var obj = JSON.parse(wartoscJosn) as T;
    return obj;
  }

  public wyczyscCiasteczka() {
    localStorage.removeItem(Ciasteczko.DaneZalogowanegoUzytkownika.toString());
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private menuService: MenuService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.isLoggedIn;
    const isLoginForm = route.routeConfig?.path === Link.LogInFormPath;
    const isRegisterForm = route.routeConfig?.path === Link.RegisterPath;
    const isConfirmForm = route.routeConfig?.path === Link.ConfirmPath;
    const isRegisterSuccessForm = route.routeConfig?.path === Link.RegisterSuccessPath;
    const isNewPswdForm = route.routeConfig?.path === Link.NewPswdPath;
    const isChangePswdForm = route.routeConfig?.path === Link.ChangePswdPath;
    const isChangeEmailForm = route.routeConfig?.path === Link.ChangeEmailPath;
    const isConfirmActivity = route.routeConfig?.path === Link.ConfirmActivity;

    if ((isLoginForm || isRegisterForm || isConfirmForm || isRegisterSuccessForm || isNewPswdForm
      || isChangePswdForm || isChangeEmailForm || isConfirmActivity)) {
      this.menuService.schowajMenu();
    }
    else {
      this.menuService.pokazMenu();
    }

    if (isLoggedIn
      && (isLoginForm || isRegisterForm || isConfirmForm || isRegisterSuccessForm || isChangePswdForm)
    ) {
      //if (isLoggedIn && (isLoginForm || isRegisterForm || isConfirmForm || isRegisterSuccessForm || isNewPswdForm || isChangePswdForm)) {

      this.router.navigate([Link.Powiadomienia]);
      return false;
    }

    if (!isLoggedIn && !(isLoginForm || isRegisterForm || isConfirmForm || isRegisterSuccessForm || isNewPswdForm || isChangePswdForm || isChangeEmailForm)) {
      this.router.navigate([Link.LogInFormNav]);
    }

    return isLoggedIn || isLoginForm || isRegisterForm || isConfirmForm || isRegisterSuccessForm || isNewPswdForm || isChangePswdForm || isChangeEmailForm || isConfirmActivity;
  }
}

export enum Link {
  LoggedInNav = '/',
  LogInFormNav = '/zaloguj',
  LogInFormPath = 'zaloguj',
  RegisterPath = 'rejestracja',
  ConfirmPath = 'rejestracja-potwierdzenie/:kod',
  RegisterSuccessPath = 'rejestracja-powodzenie/:email',
  NewPswdPath = 'nowe-haslo-email/:token',
  ChangePswdPath = 'zmien-haslo',
  ChangeEmailPath = 'potwierdz-email/:token',
  ConfirmActivity = 'potwierdz-aktywnosc/:kod',
  UstawieniaProfilu = 'ustawienia-profilu',
  Powiadomienia = 'powiadomienia',
  Abonamenty = 'abonamenty'
}