import { RodzajLinku } from './rodzajLinku';

export class BladModelu {
    constructor(blad, wlasciwosc) {
        this.blad = blad;
        this.wlasciwosc = wlasciwosc;
    }
    blad: string;
    wlasciwosc: string;
    rodzajLinku?: RodzajLinku;
}