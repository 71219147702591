import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { RequestBazowy } from 'src/app/sekcje/@wspolne/modele/requestBazowy';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { PobierzTrescPoleceniaModel } from '../../modele/pobierzTrescPoleceniaModel';
import { PolecNasModel } from '../../modele/polecNasModel';
import { PolecService } from '../../serwisy/polec.service';

@Component({
  selector: 'app-polec-modal',
  templateUrl: './polec-modal.component.html',
  styleUrls: ['./polec-modal.component.css']
})
export class PolecModalComponent extends BazowyComponent implements OnInit {

  public model = new PolecNasModel();
  public czyZaladowanoDane: boolean;
  public tokenPotwierdzeniaAktywnosci: string;

  constructor(
    public polecSerwis: PolecService,
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    public komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.czyZalogowany()) {
        this.pobierzDaneDoEkranu();
      }
      else {
        this.pobierzDaneDoEkranuNiezalogowany();
      }

    });
  }
  pobierzDaneDoEkranuNiezalogowany() {
    var model = new PobierzTrescPoleceniaModel();
    model.tokenPotwierdzenia = this.tokenPotwierdzeniaAktywnosci;

    this.polecSerwis.pobierzTrescPotwierdzAktywnosc(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.model.tresc = rezultat.element;
        this.czyZaladowanoDane = true;
        return;
      } else {
        this.activeModal.dismiss();
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    }
    );
  }

  private pobierzDaneDoEkranu() {
    this.polecSerwis.pobierzTresc(new RequestBazowy()).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.model.tresc = rezultat.element;
        this.czyZaladowanoDane = true;
        return;
      } else {
        this.activeModal.dismiss();
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    }
    );
  }

  public pobierzDlugosc() {
    if (this.model.tresc == undefined) {
      return 0;
    }
    return this.model.tresc.length;
  }
  public wyslij(): void {
    if (!this.czyZalogowany()) {
      this.model.tokenPotwierdzenia = this.tokenPotwierdzeniaAktywnosci;
    }
    this.polecSerwis.wyslij(this.model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.komunikatSerwis.success(`<div class=" info__success info__success--smile"><div class="container"><div class="info__text bg-center">Poleciłeś aplikację znajomym. Dziękujemy.</div></div></div>`);
          this.activeModal.close(true);
          return;
        }
        else {
          if (this.bladModelu(rezultat)) {
            this.bledyWalidacji = rezultat.bledyModelu;
          }
          if (rezultat.wiadomosc) {
            this.wyswietlKomunikat(rezultat.wiadomosc, true);
          }
        }
      }
    );
  }

}
