<div class="modal-body">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss()"></button>

    <p class="text-center ft__05 mb-3 pb-0 pt-2">
        Zmiana numeru telefonu
    </p>
    <p class="text-center pb-3">Wprowadź numer telefonu komórkowego do komunikatów</p>

    <form action="">
        <div class="f-input pb-3" [ngClass]="czyBladWalidacyjny('numerTelefonu')?'error':''">
            <input type="text" placeholder="Nowy numer telefonu" name="numerTelefonu"
                [(ngModel)]="zmienNumerTelefonuFormularz.numerTelefonu" />
            <div class="pt-2" [hidden]="!czyBladWalidacyjny('numerTelefonu')">
                {{pobierzBladWalidacyjny("numerTelefonu")}}</div>
        </div>
        <div class="d-flex flex-column mt-3">
            <button class="btn__primary " (click)="onZapiszNowyNumerClick()">Zapisz</button>
            <button class="btn__cancel mt-3" (click)="activeModal.dismiss()">Zamknij</button>
        </div>
    </form>
</div>