import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { RequestBazowy } from 'src/app/sekcje/@wspolne/modele/requestBazowy';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { PolecNasModel } from 'src/app/sekcje/polec/modele/polecNasModel';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { WyslijFormularzKontaktowyModel } from '../../modele/wyslijFormularzKontaktowyModel';
import { KontaktService } from '../../serwisy/kontakt.service';

@Component({
  selector: 'app-formularz-kontaktowy-modal',
  templateUrl: './formularz-kontaktowy-modal.component.html',
  styleUrls: ['./formularz-kontaktowy-modal.component.css']
})
export class FormularzKontaktowyModalComponent extends BazowyComponent implements OnInit {

  public model = new WyslijFormularzKontaktowyModel();
  email: string;
  public czyZaladowanoDane: boolean;

  constructor(
    public kontaktSerwis: KontaktService,
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    public komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDaneDoEkranu();

    });
  }

  private pobierzDaneDoEkranu() {
    this.kontaktSerwis.pobierzDaneDoFormularza(new RequestBazowy()).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.model.imie = rezultat.element.imie;
        this.model.nazwisko = rezultat.element.nazwisko;
        this.email = rezultat.element.email;

        this.czyZaladowanoDane = true;
        return;
      } else {
        this.activeModal.dismiss();
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    }
    );
  }

  public wyslij(): void {
    this.kontaktSerwis.wyslij(this.model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat(`Formularz kontaktowy został wysłany`);
          this.activeModal.close(true);
          return;
        }
        else {
          if (this.bladModelu(rezultat)) {
            this.bledyWalidacji = rezultat.bledyModelu;
          }
          if (rezultat.wiadomosc) {
            this.wyswietlKomunikat(rezultat.wiadomosc, true);
          }
        }
      }
    );
  }
}
