<div class="modal-body">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss()"></button>

    <div class="ft__01--01 text-center pt-3 mb-2 text-gray">Potwierdź poprawność danych</div>


    <div class="mx-sm-5 mt-4 mb-4 pb-2">


        <div class="d-flex justify-content-between align-items-center mb-3">
            <span class="ft__radios">Email:</span>
            <span class="ft__radios fw-600">{{email}}</span>
        </div>
        <div *ngIf="telefon!=undefined&&telefon.length" class="d-flex justify-content-between align-items-center mb-3">
            <span class="ft__radios">Telefon:</span>
            <span class="ft__radios fw-600">{{telefon}}</span>
        </div>
    </div>


    <div class="d-flex flex-column mt-3 mt-sm-5">
        <button class="btn__primary " (click)="tak()">Tak</button>
        <button class="btn__cancel mt-3" (click)="activeModal.dismiss()">Nie</button>
    </div>

</div>