import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PobierzDaneDoEkranuAbonamentuRezultat } from 'src/app/modele/abonamenty/pobierzDaneDoEkranuAbonamentuRezultat';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { ModalOpcje } from 'src/app/sekcje/@wspolne/modele/modal-opcje.ogolne';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { RequestBazowy } from '../../@wspolne/modele/requestBazowy';
import { RodzajAbonamentu } from '../modele/rodzaj-abonamentu.model';
import { AbonamentyService } from '../serwisy/abonamenty.service';
import { PlatnosciService } from '../serwisy/platnosci.service';
import { AbonamentUsunSubskrypcjeModalComponent } from './abonament-usun-subskrypcje-modal/abonament-usun-subskrypcje-modal.component';

@Component({
    template: ''
})

export class AbonamentKomponentBazowy extends BazowyComponent {

    //public czyZmianaPlanu: boolean;
    public czyZaladowanoDane: boolean;
    public daneDoEkranu: PobierzDaneDoEkranuAbonamentuRezultat = new PobierzDaneDoEkranuAbonamentuRezultat();
    public wybranyAbonament: RodzajAbonamentu = new RodzajAbonamentu();
    public payuSdkForms: any;
    public secureForms: any;
    public cardNumber: any;
    public cardDate: any;
    public cardCvv: any;
    public tokenizeButton: any;
    public responseElement: any;
    public optionsForms: any = {
        cardIcon: true,
        style: {
            basic: {
                fontSize: '24px'
            }
        },
        placeholder: {
            number: '',
            date: 'MM/YY',
            cvv: ''
        },
        lang: 'pl'
    }

    constructor(public router: Router,
        public modalService: NgbModal,
        public loaderSerwis: LoaderService,
        public komunikatSerwis: ToastrService,
        public authService: AuthService,
        public abonamentSerwis: AbonamentyService,
        public platnosciService: PlatnosciService,
        public activeModalService: NgbActiveModal) {
        super(authService, modalService, loaderSerwis, komunikatSerwis, router);
    }


    public zrezygnujZSubskrypcji() {
        this.modalService.open(AbonamentUsunSubskrypcjeModalComponent, new ModalOpcje(true)).result.then((result) => {
            if (result) {
                this.pobierzDaneDoEkranu();
            }
        }, (reason) => { });
    }

    public pobierzDaneDoEkranu(): void {
        this.abonamentSerwis.pobierzDaneDoEkranuAbonamentu(new RequestBazowy()).subscribe((result) => {
            if (this.czyRezultatOk(result)) {
                this.daneDoEkranu = result.element;
                this.wybranyAbonament = this.daneDoEkranu.rodzajeAbonamentu.find(a => a.czestotliwosc == Czestotliwosc.Miesiac && a.pakietAbonamentu == PakietAbonamentu.Standard)
                this.czyZaladowanoDane = true;
                //this.czyZmianaPlanu = false;
            } else {
                this.wyswietlKomunikat(result.wiadomosc, true);
            }
        });
    }

    public pobierzOstatnieCyfryKarty(): string {
        if (!this.daneDoEkranu.karta.maskowanyNumerKarty || this.daneDoEkranu.karta.maskowanyNumerKarty.length < 4) {
            return "";
        }

        var ostatnieCzteryCyfry = "";
        for (let i = 4; i > 0; i--) {
            ostatnieCzteryCyfry += this.daneDoEkranu.karta.maskowanyNumerKarty.charAt(this.daneDoEkranu.karta.maskowanyNumerKarty.length - i);
        }
        return ostatnieCzteryCyfry;
    }

    pokazPrzyciskZmienPlan() {
        return this.daneDoEkranu.aktualnyPakiet != 3 && !this.daneDoEkranu.brakAbonamentu;
    }

    public pobierzJednostkeCzestotliwosci(): string {
        switch (this.wybranyAbonament.czestotliwosc) {
            case Czestotliwosc.Miesiac:
                return "miesiąc";
            case Czestotliwosc.Kwartal:
                return "kwartał";
            case Czestotliwosc.Rok:
                return "rok";
            case Czestotliwosc.TrzyLata:
                return "3 lata";
            default:
                return "";
        }
    }


}

export enum NumerKafelka {
    Standard = 0
}
export enum Czestotliwosc {
    Miesiac = "Miesiąc",
    Kwartal = "Kwartał",
    Rok = "Rok",
    TrzyLata = "Trzy lata"
}
export enum PakietAbonamentu {
    Standard = "Standard"
}