<nav class="navbar">
    <div class="navbar__container">
        <div class="navbrand">
            <a class="brand" routerLink="/">
                <img src="/assets/img/logo.svg">
            </a>
            <a class="brand_white" routerLink="/">
                <img src="/assets/img/logo_white.svg">
            </a>
            <div class="burger" id="burger">
                <span class="burger-open">
                    <svg height="16" width="24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#558564" fill-rule="evenodd">
                            <path d="M0 0h24v2H0zM0 7h24v2H0zM0 14h24v2H0z">
                            </path>
                        </g>
                    </svg>
                </span>
                <span class="burger-close">
                    <svg height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#558564"
                            d="M17.778.808l1.414 1.414L11.414 10l7.778 7.778-1.414 1.414L10 11.414l-7.778 7.778-1.414-1.414L8.586 10 .808 2.222 2.222.808 10 8.586 17.778.808z"
                            fill-rule="evenodd">
                        </path>
                    </svg>
                </span>
            </div>
        </div>
        <div class="menu__type" id="menu">
            <ul class="menu">
                <li class="menu-item" [routerLinkActive]="['active']">
                    <a class="menu-link" routerLink="/powiadomienia">Powiadomienia</a>
                </li>
                <li class="menu-item" [routerLinkActive]="['active']">
                    <a class="menu-link" routerLink="/konfiguracja">Konfiguracja</a>
                </li>
                <li class="menu-item" [routerLinkActive]="['active']">
                    <a class="menu-link" routerLink="/gdzie-co-mam">GdzieCoMam</a>
                </li>
                <li class="menu-item">
                    <a class="menu-link" [routerLink]="" (click)="pokazModalPolecenia()">Poleć nas</a>
                </li>

                <li class="menu-item only_mobile">
                    <a class="menu-link" routerLink="/abonamenty">
                        Abonamenty
                    </a>
                </li>
                <li class="menu-item only_mobile">
                    <a class="menu-link" [routerLink]="" (click)="pokazModalKontaktu()">
                        Kontakt
                    </a>
                </li>

                <li class="menu-item only_mobile">
                    <a class="menu-link" href="https://motoasysta.pl" target="_blank">
                        Motoasysta
                    </a>
                </li>

                <!-- <li class="menu-item" [routerLinkActive]="['active']">
                    <a class="menu-link" routerLink="/abonamenty">Abonamenty</a>
                </li> -->


                <li class="menu-item logout_mobile">
                    <a class="menu-link" routerLink="" (click)="authService.logOut()">Wyloguj</a>
                </li>
            </ul>
            <div class="menu__type__account">

                <img id="pimg" src="/assets/img/ico_person.svg" alt="">

                <div class="menu__type__account__name"><img id="mimg" src="/assets/img/ico_person.svg"
                        alt="">{{authService.getUserName()}}&nbsp;{{authService.getUserLastName()}}</div>

                <div class="profil_edit_desktop">
                    <li class="menu-item">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.7467 8.02667L15.9733 9.25333L3.89333 21.3333H2.66667V20.1067L14.7467 8.02667ZM19.5467 0C19.2133 0 18.8667 0.133333 18.6133 0.386667L16.1733 2.82667L21.1733 7.82667L23.6133 5.38667C24.1333 4.86667 24.1333 4.02667 23.6133 3.50667L20.4933 0.386667C20.2267 0.12 19.8933 0 19.5467 0ZM14.7467 4.25333L0 19V24H5L19.7467 9.25333L14.7467 4.25333Z"
                                fill="#5C80BC" />
                        </svg>
                        <a class="menu-link" routerLink="/ustawienia-profilu">Profil</a>
                    </li>
                    <li class="menu-item">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M26.8131 13.4668C26.928 13.0996 26.9908 12.7181 26.9998 12.3335V7.00016C26.9998 3.20016 21.2665 0.333496 13.6665 0.333496C6.06646 0.333496 0.33313 3.20016 0.33313 7.00016V12.3335C0.406629 13.2218 0.707798 14.0761 1.20759 14.8141C1.70738 15.5521 2.38892 16.1489 3.18646 16.5468C3.06404 16.9076 3.001 17.2859 2.9998 17.6668V23.0002C2.9998 26.8002 8.73313 29.6668 16.3331 29.6668C23.9331 29.6668 29.6665 26.8002 29.6665 23.0002V17.6668C29.5946 16.7798 29.2939 15.9266 28.7938 15.1905C28.2937 14.4543 27.6113 13.8605 26.8131 13.4668ZM13.6665 19.0002C18.7998 19.0002 23.0798 17.6668 25.3198 15.6668C26.3865 16.3068 26.9998 17.0002 26.9998 17.6668C26.9998 19.2935 22.8398 21.6668 16.3331 21.6668C9.82646 21.6668 5.78646 19.3602 5.66646 17.7335C8.24053 18.6123 10.9468 19.0408 13.6665 19.0002ZM13.6665 3.00016C20.1731 3.00016 24.3331 5.3735 24.3331 7.00016C24.3331 8.62683 20.1731 11.0002 13.6665 11.0002C7.1598 11.0002 2.9998 8.62683 2.9998 7.00016C2.9998 5.3735 7.1598 3.00016 13.6665 3.00016ZM2.9998 11.0802C6.2427 12.9249 9.93876 13.8212 13.6665 13.6668C17.3942 13.8212 21.0902 12.9249 24.3331 11.0802V12.3335C24.3331 13.9602 20.1731 16.3335 13.6665 16.3335C7.1598 16.3335 2.9998 13.9602 2.9998 12.3335V11.0802ZM16.3331 27.0002C9.82646 27.0002 5.66646 24.6268 5.66646 23.0002V21.7468C8.90937 23.5916 12.6054 24.4879 16.3331 24.3335C20.0608 24.4879 23.7569 23.5916 26.9998 21.7468V23.0002C26.9998 24.6268 22.8398 27.0002 16.3331 27.0002Z"
                                fill="#5C80BC" />
                        </svg>

                        <a class="menu-link" routerLink="/abonamenty">
                            Abonamenty
                        </a>
                    </li>
                    <li class="menu-item">
                        <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M24.6666 0.333252H3.33329C1.86663 0.333252 0.679959 1.53325 0.679959 2.99992L0.666626 18.9999C0.666626 20.4666 1.86663 21.6666 3.33329 21.6666H24.6666C26.1333 21.6666 27.3333 20.4666 27.3333 18.9999V2.99992C27.3333 1.53325 26.1333 0.333252 24.6666 0.333252ZM24.6666 18.9999H3.33329V5.66658L14 12.3333L24.6666 5.66658V18.9999ZM14 9.66658L3.33329 2.99992H24.6666L14 9.66658Z"
                                fill="#5C80BC" />
                        </svg>
                        <a class="menu-link" [routerLink]="" (click)="pokazModalKontaktu()">
                            Kontakt
                        </a>
                    </li>



                    <li class="menu-item">
                        <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.3334 18.6667L17.2 16.8L13.7334 13.3333L27.3334 13.3333V10.6667L13.7334 10.6667L17.2 7.2L15.3334 5.33333L8.66671 12L15.3334 18.6667ZM3.33337 2.66667L14 2.66667L14 0L3.33337 0C1.86671 0 0.666706 1.2 0.666706 2.66667L0.666706 21.3333C0.666706 22.8 1.86671 24 3.33337 24L14 24V21.3333L3.33337 21.3333L3.33337 2.66667Z"
                                fill="#5C80BC" />
                        </svg>
                        <a class="menu-link pointer" routerLink="" (click)="authService.logOut()">Wyloguj</a>
                    </li>
                    <li class="menu-item">
                        <img src="/assets/img/motoasysta_logo.svg" alt="" style="width:28px ;cursor: pointer;" />
                        <a class="menu-link" href="https://motoasysta.pl" target="_blank">
                            Motoasysta
                        </a>
                    </li>
                </div>
                <div class="edit_profil_mobile">
                    <a routerLink="/ustawienia-profilu">
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.06 6.02L11.98 6.94L2.92 16H2V15.08L11.06 6.02ZM14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0ZM11.06 3.19L0 14.25V18H3.75L14.81 6.94L11.06 3.19Z"
                                fill="white" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</nav>