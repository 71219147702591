import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { AktywujKodRabatowyModel } from '../../modele/aktywujKodRabatowyModel';
import { AbonamentyService } from '../../serwisy/abonamenty.service';

@Component({
  selector: 'app-abonament-aktywuj-kod-rabatowy-modal',
  templateUrl: './abonament-aktywuj-kod-rabatowy-modal.component.html',
  styleUrls: ['./abonament-aktywuj-kod-rabatowy-modal.component.css']
})
export class AbonamentAktywujKodRabatowyModalComponent extends BazowyComponent implements OnInit {

  model: AktywujKodRabatowyModel = new AktywujKodRabatowyModel();

  constructor(
    public abonamentSerwis: AbonamentyService,
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    public komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
    setTimeout(() => {

    });
  }


  aktywuj() {
    this.abonamentSerwis.aktywujKodRabatowy(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat("Kod rabatowy został aktywowany");
        this.activeModal.close(true);
        return;
      }
      else if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }
      this.wyswietlKomunikat(rezultat.wiadomosc, true);
    });
  }

}
