import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ZarejestrujModel } from 'src/app/sekcje/konto/modele/rejestracja/zarejestrujModel';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ToastrService } from 'ngx-toastr';
import { RejestracjaService } from 'src/app/sekcje/konto/serwisy/rejestracja.service';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';

@Component({
  selector: 'app-zarejestruj-przez-email',
  templateUrl: './zarejestruj-przez-email.component.html'
})
export class ZarejestrujPrzezEmailComponent extends BazowyComponent implements OnInit {

  public zarejestrujModel = new ZarejestrujModel();

  constructor(
    public authService: AuthService, 
    public router: Router, 
    private rejestracjaSerwis: RejestracjaService, 
    modalService: NgbModal, 
    loaderSerwis: LoaderService, 
    private komunikatSerwis: ToastrService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
    // if (this.czyZalogowany()) {
    //   this.router.navigate(['visits']);
    // }
  }

  ngOnInit(): void {
  }

  public zarejestruj() {
    try {
      this.rejestracjaSerwis.zarejestruj(this.zarejestrujModel).subscribe(
        (rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.router.navigateByUrl('/rejestracja-powodzenie/' + this.zarejestrujModel.email, { skipLocationChange: true });
            return;
          }
          if (this.bladModelu(rezultat)) {
            this.bledyWalidacji = rezultat.bledyModelu;
            return;
          }
          this.wyswietlKomunikat(rezultat.wiadomosc, true);
        }
      );
    } catch (blad) {

    }

  }

}
