<div class="modal-content">
    <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.dismiss()"></button>

        <p class="text-center ft__05 mb-3 pb-0 pt-2">
            Aktywuj kod rabatowy
        </p>

        <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('kod')?'error':''">
            <input type="text" placeholder="Kod rabatowy" name="kod" [(ngModel)]="model.kod">
            <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('kod')"> {{
                pobierzBladWalidacyjny("kod")
                }}</div>
        </div>

        <button class="btn__primary mt-4 mb-4" (click)="aktywuj()">
            Aktywuj
        </button>
    </div>
</div>