<div class="modal-content">
    <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.dismiss()"></button>

        <p class="text-center ft__05 mb-3 pb-0 pt-2">Wykupienie abonamentu</p>
        <p class="text-center ft__16bold">{{wybranyAbonament.pakietAbonamentu}}</p>
        <p>Abonament odnawiany cyklicznie co <strong>{{pobierzJednostkeCzestotliwosci()}}</strong></p>
        <p>Kwota do zapłaty <strong>{{formatujCene(wybranyAbonament.cena)}} /
                {{pobierzJednostkeCzestotliwosci()}}</strong></p>
        <div class="py-4">
            <section _ngcontent-rob-c115="" class="container-card">
                <div _ngcontent-rob-c115="" class="card-container">
                    <aside _ngcontent-rob-c115="">Numer Karty</aside>
                    <div _ngcontent-rob-c115="" id="payu-card-number"
                        class="payu-card-form payu-secure-form payu-secure-form-empty">
                    </div>
                    <div _ngcontent-rob-c115="" class="card-details clearfix">
                        <div _ngcontent-rob-c115="" class="expiration">
                            <aside _ngcontent-rob-c115="">Ważna do</aside>
                            <div _ngcontent-rob-c115="" id="payu-card-date"
                                class="payu-card-form payu-secure-form payu-secure-form-empty">
                            </div>
                        </div>
                        <div _ngcontent-rob-c115="" class="cvv">
                            <aside _ngcontent-rob-c115="">CVV</aside>
                            <div _ngcontent-rob-c115="" id="payu-card-cvv"
                                class="payu-card-form payu-secure-form payu-secure-form-empty">
                            </div>
                        </div>
                    </div>
                </div>
                <div _ngcontent-rob-c115="" id="responseTokenize" style="color: black !important;"></div>
            </section>
        </div>


        <div class="text-center">
            <small>Nie przechowujemy danych Twojej karty. Informacje podane w formularzu zostaną przekazane do systemu
                płatności.</small>
        </div>

        <div class="d-flex flex-column pb-3 pt-3">
            <div class="itm pb-2">
                <button id="tokenizeButton" _ngcontent-rob-c115="" class="btn__primary" (click)="tokenizuj()">
                    Przejdź do płatności
                </button>
            </div>
            <div class="itm">
                <button class="btn__primary btn__primary--cancel" (click)="activeModal.dismiss()">
                    Zamknij
                </button>
            </div>
        </div>

    </div>
</div>