import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { ModalOpcje } from 'src/app/sekcje/@wspolne/modele/modal-opcje.ogolne';
import { RequestBazowy } from 'src/app/sekcje/@wspolne/modele/requestBazowy';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { AbonamentyService } from '../../serwisy/abonamenty.service';
import { PlatnosciService } from '../../serwisy/platnosci.service';
import { AbonamentAktywujKodRabatowyModalComponent } from '../abonament-aktywuj-kod-rabatowy-modal/abonament-aktywuj-kod-rabatowy-modal.component';
import { AbonamentWykupNowyAbonamentModalComponent } from '../abonament-wykup-nowy-abonament-modal/abonament-wykup-nowy-abonament-modal.component';
import { AbonamentKomponentBazowy, Czestotliwosc, NumerKafelka, PakietAbonamentu } from '../abonamentKomponentBazowy';
@Component({
  selector: 'app-abonamenty',
  templateUrl: './abonamenty.component.html',
  styleUrls: ['./abonamenty.component.css']
})
export class AbonamentyComponent extends AbonamentKomponentBazowy implements OnInit, AfterViewInit {


  public czyMenuKartyAktywne: boolean;
  //public stanyKafelkow: Array<boolean>;
  private subscription: Subscription;
  // public czyZmianaAbonamentuParametr: boolean;

  constructor(public router: Router,
    public modalService: NgbModal,
    public loaderSerwis: LoaderService,
    public komunikatSerwis: ToastrService,
    public authService: AuthService,
    public abonamentSerwis: AbonamentyService,
    public platnosciService: PlatnosciService,
    public activeModalService: NgbActiveModal,
    private activatedRoute: ActivatedRoute) {
    super(router, modalService, loaderSerwis, komunikatSerwis, authService, abonamentSerwis,
      platnosciService, activeModalService);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDaneDoEkranu();
      //this.stanyKafelkow = new Array<boolean>(false, false, false, false, true);
      //this.czyZmianaAbonamentuParametr = this.activatedRoute.snapshot.paramMap.get('czyZmienPlan') == "true";

    });

  }

  ngOnDestroy() {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

  // public onKafelekClick(numerKafelka: NumerKafelka): void {
  //   if (this.czyKafelekAktywny(numerKafelka)) {
  //     return;
  //   }
  //   for (var i = 0; i < this.stanyKafelkow.length; i++) {
  //     if (i == numerKafelka) {
  //       this.stanyKafelkow[i] = true;
  //     } else {
  //       this.stanyKafelkow[i] = false;
  //     }
  //   }
  //   if (this.daneDoEkranu.rodzajeAbonamentu) {
  //     switch (numerKafelka) {
  //       case NumerKafelka.Standard:
  //         this.wybranyAbonament = this.daneDoEkranu.rodzajeAbonamentu.find(a => a.czestotliwosc == Czestotliwosc.Miesiac && a.pakietAbonamentu == PakietAbonamentu.Standard)
  //         return;
  //     }
  //   }
  // }

  public wyswietlFormularzPlatnosci(): void {
    if (!this.czyWybranoAbonament) {
      return;
    }
    this.modalReference = this.modalService.open(AbonamentWykupNowyAbonamentModalComponent, new ModalOpcje(true));
    this.modalReference.componentInstance.wybranyAbonament = this.wybranyAbonament;

    this.modalReference.result.then((result) => {
      if (result) {
        this.odswiezEkranPoWykupieniuAbonamentu();
      }
    }, (reason) => { });
  }


  // public czyKafelekAktywny(numerKafelka: NumerKafelka): boolean {
  //   return this.stanyKafelkow[numerKafelka];
  // }

  public pobierzTekstZCena(numerKafelka: NumerKafelka) {
    return `${this.formatujCene(this.wybranyAbonament.cena)}/${this.pobierzJednostkeCzestotliwosci()}`;
    // if (this.daneDoEkranu.rodzajeAbonamentu && this.wybranyAbonament) {
    //   if (this.czyKafelekAktywny(numerKafelka)) {
    //     return `${this.formatujCene(this.wybranyAbonament.cena)}/${this.pobierzJednostkeCzestotliwosci()}`;
    //   } else {
    //     return this.pobierzMinimalnaCeneAbonamentu(numerKafelka);
    //   }
    // } else if (!this.wybranyAbonament && this.daneDoEkranu.rodzajeAbonamentu) {
    //   return this.pobierzMinimalnaCeneAbonamentu(numerKafelka);
    // }
  }

  public czyWybranoAbonament(czestotliwosc: string, pakietAbonamentu: string): boolean {
    if (this.wybranyAbonament) {
      return this.wybranyAbonament.czestotliwosc === czestotliwosc && this.wybranyAbonament.pakietAbonamentu === pakietAbonamentu;
    }
    return false;
  }

  public wybierzAbonament(czestotliwosc: string, pakietAbonamentu: string): void {
    if (this.daneDoEkranu.rodzajeAbonamentu) {
      this.wybranyAbonament = this.daneDoEkranu.rodzajeAbonamentu.find(a => a.czestotliwosc == czestotliwosc && a.pakietAbonamentu == pakietAbonamentu);
    }
  }



  public pokazMenuKarty(): void {
    this.czyMenuKartyAktywne = true;
  }

  public schowajMenuKarty() {
    this.czyMenuKartyAktywne = false;
  }


  // public zmienPlan() {
  //   this.czyZmianaPlanu = true;
  // }

  // public anulujZmianePlanu() {
  //   this.czyZmianaPlanu = false;
  //   this.czyZmianaAbonamentuParametr = false;
  //   window.history.pushState('', '', '/abonamenty');
  // }

  // private formatujCeneAbonamentu(cena: number): string {
  //   return cena % 1
  //     ? cena.toString()
  //     : cena.toFixed(2);
  // }



  // private pobierzMinimalnaCeneAbonamentu(numerKafelka: NumerKafelka): string {
  //   switch (numerKafelka) {
  //     case NumerKafelka.Standard:
  //       return `od ${this.formatujCene(this.daneDoEkranu.rodzajeAbonamentu.find(a => a.czestotliwosc == Czestotliwosc.Miesiac && a.pakietAbonamentu == PakietAbonamentu.Standard).cena)}/mc`;

  //     // case NumerKafelka.Standard5:
  //     //   return `od ${this.formatujCene(this.daneDoEkranu.rodzajeAbonamentu.find(a => a.czestotliwosc == Czestotliwosc.Miesiac && a.pakietAbonamentu == PakietAbonamentu.Standard5).cena)}/mc`;
  //     // case NumerKafelka.Standard10:
  //     //   return `od ${this.formatujCene(this.daneDoEkranu.rodzajeAbonamentu.find(a => a.czestotliwosc == Czestotliwosc.Miesiac && a.pakietAbonamentu == PakietAbonamentu.Standard10).cena)}/mc`;
  //     // case NumerKafelka.Standard20:
  //     //   return `od ${this.formatujCene(this.daneDoEkranu.rodzajeAbonamentu.find(a => a.czestotliwosc == Czestotliwosc.Miesiac && a.pakietAbonamentu == PakietAbonamentu.Standard20).cena)}/mc`;
  //     // case NumerKafelka.Standard100:
  //     //   return `od ${this.formatujCene(this.daneDoEkranu.rodzajeAbonamentu.find(a => a.czestotliwosc == Czestotliwosc.Miesiac && a.pakietAbonamentu == PakietAbonamentu.Standard100).cena)}/mc`;
  //   }
  // }
  // private pobierzMinimalnaCeneAbonamentuDoZmianyPlanu(numerKafelka: NumerKafelka): string {
  //   switch (numerKafelka) {
  //     case NumerKafelka.Standard:
  //       return `od ${this.formatujCene(this.daneDoEkranu.rodzajeAbonamentuDoZmianyPlanu.find(a => a.czestotliwosc == Czestotliwosc.Miesiac && a.pakietAbonamentu == PakietAbonamentu.Standard).cena)}/mc`;

  //     // case NumerKafelka.Standard5:
  //     //   return `od ${this.formatujCene(this.daneDoEkranu.rodzajeAbonamentuDoZmianyPlanu.find(a => a.czestotliwosc == Czestotliwosc.Miesiac && a.pakietAbonamentu == PakietAbonamentu.Standard5).cena)}/mc`;
  //     // case NumerKafelka.Standard10:
  //     //   return `od ${this.formatujCene(this.daneDoEkranu.rodzajeAbonamentuDoZmianyPlanu.find(a => a.czestotliwosc == Czestotliwosc.Miesiac && a.pakietAbonamentu == PakietAbonamentu.Standard10).cena)}/mc`;
  //     // case NumerKafelka.Standard20:
  //     //   return `od ${this.formatujCene(this.daneDoEkranu.rodzajeAbonamentuDoZmianyPlanu.find(a => a.czestotliwosc == Czestotliwosc.Miesiac && a.pakietAbonamentu == PakietAbonamentu.Standard20).cena)}/mc`;
  //     // case NumerKafelka.Standard100:
  //     //   return `od ${this.formatujCene(this.daneDoEkranu.rodzajeAbonamentuDoZmianyPlanu.find(a => a.czestotliwosc == Czestotliwosc.Miesiac && a.pakietAbonamentu == PakietAbonamentu.Standard100).cena)}/mc`;
  //   }
  // }


  public czyPakietDostepny(pakiet: string) {
    return this.daneDoEkranu.rodzajeAbonamentu.find(m => m.pakietAbonamentu == pakiet);
  }

  public czyPakietDostepnyDoZmianyPlanu(pakiet: string) {
    return this.daneDoEkranu.rodzajeAbonamentuDoZmianyPlanu.find(m => m.pakietAbonamentu == pakiet);
  }

  // public wyswietlFormularzPlatnosciZaZmianePlanu(pakiet: string) {
  //   this.modalReference = this.modalService.open(AbonamentZmienPlanModalComponent, new ModalOpcje(true));
  //   this.modalReference.componentInstance.karta = this.daneDoEkranu.karta;
  //   this.modalReference.componentInstance.pakietDoZmiany = PakietAbonamentu[pakiet];

  //   this.modalReference.result.then((result) => {
  //     if (result) {
  //       this.odswiezEkranPoWykupieniuAbonamentu();
  //     }
  //   }, (reason) => { });
  // }

  public ponowPlatnosc() {
    this.platnosciService.ponowPlatnosc(new RequestBazowy()).subscribe((result) => {
      if (this.czyRezultatOk(result)) {
        if (result.element.adresDoPrzekierowania) {
          window.location.href = result.element.adresDoPrzekierowania;
        } else {
          this.wyswietlKomunikat("Nowy abonament zostanie aktywowany po ustanowieniu płatności w PayU.");
          this.odswiezEkranPoWykupieniuAbonamentu();
        }
      } else {
        this.wyswietlKomunikat(result.wiadomosc, true);
      }
    });
  }

  odswiezEkranPoWykupieniuAbonamentu() {
    const source = interval(3000);
    this.subscription = source.subscribe(val => {
      this.pobierzDaneDoEkranu();

      if (!this.daneDoEkranu.brakAbonamentu) {
        this.subscription.unsubscribe();

        // this.czyZmianaAbonamentuParametr = false;
        window.history.pushState('', '', '/abonamenty');
      }
    }
    );


  }

  aktywujKodRabatowy() {
    this.modalReference = this.modalService.open(AbonamentAktywujKodRabatowyModalComponent, new ModalOpcje(true));

    this.modalReference.result.then((result) => {
      if (result) {
        this.pobierzDaneDoEkranu();
      }
    }, (reason) => { });
  }
}

