import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PobierzDaneDoRozszerzeniaAbonamentuRezultat } from 'src/app/modele/abonamenty/pobierzDaneDoRozszerzeniaAbonamentuRezultat';
import { SerwisBazowy } from 'src/app/serwisy/bazowy.service';
import { PojedynczyElementResponse } from '../../@wspolne/modele/pojedynczyElement';
import { RequestBazowy } from '../../@wspolne/modele/requestBazowy';
import { AuthService } from '../../@wspolne/serwisy/auth.service';
import { ErrorService } from '../../@wspolne/serwisy/error.service';
import { OplacZmianeAbonamentuModel } from '../modele/oplacZmianeAbonamentuModel';
import { OplacZmianeAbonamentuRezultat } from '../modele/oplacZmianeAbonamentuRezultat';
import { PayUKarta } from '../modele/pay-ukarta.model';
import { PobierzDaneDoZmianyAbonamentuModel } from '../modele/pobierzDaneDoZmianyAbonamentuModel';
import { PonowPlatnoscRezultat } from '../modele/ponowPlatnoscRezultat';
import { RozpocznijSubskrypcjeRequest } from '../modele/rozpocznij-subskrypcje-request.model';
import { RozpocznijSubskrypcjeResponse } from '../modele/rozpocznij-subskrypcje-response.model';

@Injectable({
  providedIn: 'root'
})
export class PlatnosciService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService) {
    super(httpClient, authService, errorService);
  }

  public pobierzIdSklepu(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, PojedynczyElementResponse<string>>(`${this.AdresApi}${PlatnosciAdresy.Controller}/${PlatnosciAdresy.PobierzIdSklepu}`, model);
  }

  public rozpocznijSubskrypcje(model: RozpocznijSubskrypcjeRequest) {
    return this.wykonajAkcje<RozpocznijSubskrypcjeRequest, PojedynczyElementResponse<RozpocznijSubskrypcjeResponse>>(`${this.AdresApi}${PlatnosciAdresy.Controller}/${PlatnosciAdresy.RozpocznijSubskrypcje}`, model);
  }

  public pobierzKarte(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, PojedynczyElementResponse<PayUKarta>>(`${this.AdresApi}${PlatnosciAdresy.Controller}/${PlatnosciAdresy.PobierzKarte}`, model);
  }
  public pobierzDaneDoZmianyPlanu(model: PobierzDaneDoZmianyAbonamentuModel) {
    return this.wykonajAkcje<PobierzDaneDoZmianyAbonamentuModel, PojedynczyElementResponse<PobierzDaneDoRozszerzeniaAbonamentuRezultat>>
      (`${this.AdresApi}${PlatnosciAdresy.Controller}/${PlatnosciAdresy.PobierzDaneDoZmianyAbonamentu}`, model);
  }

  public oplacZmianeAbonamentu(model: OplacZmianeAbonamentuModel) {
    return this.wykonajAkcje<OplacZmianeAbonamentuModel, PojedynczyElementResponse<OplacZmianeAbonamentuRezultat>>
      (`${this.AdresApi}${PlatnosciAdresy.Controller}/${PlatnosciAdresy.OplacZmianeAbonamentu}`, model);
  }

  public ponowPlatnosc(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, PojedynczyElementResponse<PonowPlatnoscRezultat>>
      (`${this.AdresApi}${PlatnosciAdresy.Controller}/${PlatnosciAdresy.PonowPlatnosc}`, model);
  }

}
enum PlatnosciAdresy {
  Controller = "Platnosc",
  PobierzIdSklepu = "PobierzIdSklepu",
  RozpocznijSubskrypcje = "RozpocznijSubskrypcje",
  PobierzKarte = "PobierzKarte",
  PobierzDaneDoZmianyAbonamentu = "PobierzDaneDoZmianyAbonamentu",
  OplacZmianeAbonamentu = "OplacZmianeAbonamentu",
  PonowPlatnosc = "PonowPlatnosc"
}
