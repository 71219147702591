import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { IdModel } from 'src/app/sekcje/@wspolne/modele/id.model';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { PowiadomieniaService } from '../../serwisy/powiadomienia.service';

@Component({
  selector: 'app-powiadomienia-usun-modal',
  templateUrl: './powiadomienia-usun-modal.component.html',
  styleUrls: ['./powiadomienia-usun-modal.component.css']
})
export class PowiadomieniaUsunModalComponent extends BazowyComponent implements OnInit {

  public id: string;

  constructor(
    public powiadomieniaSerwis: PowiadomieniaService,
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
  }

  public usun() {
    var model = new IdModel();
    model.id = this.id;

    this.powiadomieniaSerwis.usunPowiadomienie(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {

          this.wyswietlKomunikat('Powiadomienie zostało usunięte');
          this.activeModal.close(true);
          return;
        }
        else {
          if (rezultat.wiadomosc) {
            this.wyswietlKomunikat(rezultat.wiadomosc, true);
          }
        }
      }
    );
  }

}
