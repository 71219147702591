import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Nawigacja } from 'src/app/sekcje/@wspolne/modele/nawigacja';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponentZMenu } from '../../bazowyZMenu.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent extends BazowyComponentZMenu implements OnInit {

  @Input() menuInput: Nawigacja = new Nawigacja();
  @Output() wsteczEvent = new EventEmitter<any>();
  @Output() zapiszEvent = new EventEmitter<any>();

  constructor(public authService: AuthService, public router: Router, modalService: NgbModal, loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService) {
    super(authService, modalService, loaderSerwis, router, komunikatSerwis);

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.menu = this.menuInput;
    });
  }

  wstecz() {
    this.wsteczEvent.emit();
  }
  zapisz() {
    this.zapiszEvent.emit();
  }
}
