<app-naglowek-non-auth></app-naglowek-non-auth>
<div class="wrap" role="document">
    <div class="content">
        <div class="container">
            <div class="ft__01 text-center pb-30 pt-2">Zarejestruj się</div>
            <form action="">
                <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('email')?'error':''">
                    <input placeholder="Email" type="text" name="email" [(ngModel)]="zarejestrujModel.email">
                    <div class="error pt-3 text-right" [hidden]="!czyBladWalidacyjny('email')">
                        {{ pobierzBladWalidacyjny("email") }}
                    </div>
                </div>
                <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('haslo')?'error':''">
                    <input placeholder="Hasło" type="password" name="haslo" [(ngModel)]="zarejestrujModel.haslo">
                    <div class="error pt-3 text-right" [hidden]="!czyBladWalidacyjny('haslo')">
                        {{ pobierzBladWalidacyjny("haslo") }}
                    </div>
                </div>
                <p class="ft__04 mb-3 login_info login_info--icoFix">Hasło powinno mieć co najmniej 8 znaków, cyfrę,
                    małe i wielkie litery oraz znaki specjalne.</p>

                <div class="border_info mb-4">
                    <div class="chb">
                        <input id="ccd" name="akceptacjaRegulaminu" type="checkbox" value="1"
                            [(ngModel)]="zarejestrujModel.akceptacjaRegulaminu" />
                        <label class="ft__04" for="ccd">
                            Akceptuję <a class="ft__03" href="https://gdziecojest.pl/regulamin" target="_blank">warunki
                            </a>aplikacji GdzieCoJest.pl
                        </label>
                    </div>
                    <div class="error pt-3 text-right" [hidden]="!czyBladWalidacyjny('akceptacjaRegulaminu')">
                        {{ pobierzBladWalidacyjny("akceptacjaRegulaminu") }}
                    </div>
                </div>
                <div class="f-item">
                    <button class="btn__primary" (click)="zarejestruj()">Zarejestruj</button>
                </div>
            </form>
            <div class="box mt-4">
                <p class="text-center ft__04">Jeżeli jesteś już zarejestrowany:</p>
                <a class="btn__register" routerLink="/login">Zaloguj się</a>
            </div>
        </div>
    </div>
</div>