import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { Ciasteczko } from 'src/app/sekcje/@wspolne/modele/ciasteczka';
import { ModalOpcje } from 'src/app/sekcje/@wspolne/modele/modal-opcje.ogolne';
import { RequestBazowy } from 'src/app/sekcje/@wspolne/modele/requestBazowy';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { WeryfikujKodSmsDoZmianyNumeruTelefonuFormularz } from 'src/app/sekcje/konto/modele/profil/weryfikuj-kod-sms-do-zmiany-numeru-telefonu-formularz.model';
import { ZmienNumerTelefonuFormularz } from 'src/app/sekcje/konto/modele/profil/zmien-numer-telefonu-formularz.model';
import { ProfilService } from 'src/app/sekcje/konto/serwisy/profil.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';


@Component({
  selector: 'app-zmiana-numeru-telefonu-kod-sms-modal',
  templateUrl: './zmiana-numeru-telefonu-kod-sms-modal.component.html',
  styleUrls: ['./zmiana-numeru-telefonu-kod-sms-modal.component.css']
})
export class ZmianaNumeruTelefonuKodSmsModalComponent extends BazowyComponent implements OnInit {

  public weryfikujKodSmsDoZmianyNumeruTelefonuFormularz = new WeryfikujKodSmsDoZmianyNumeruTelefonuFormularz();

  constructor(
    private profilSerwis: ProfilService,
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService,
    public activeModal: NgbActiveModal) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
  }

  public onWeryfikujKodSms(): void {
    this.profilSerwis.WeryfikujKodSmsDoZmianyNumeruTelefonu(this.weryfikujKodSmsDoZmianyNumeruTelefonuFormularz).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat('Numer telefonu został zmieniony.');
          this.activeModal.close();
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    );
  }

}
