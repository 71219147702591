<div class="modal-content" *ngIf="!ukryjModal">
    <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="zamknijModal()"></button>

        <p class="text-center ft__05 mb-4 pb-0 pt-2">
            Edytuj powiadomienie
        </p>
        <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('nazwa')?'error':''">
            <input placeholder="Nazwa powiadomienia" type="text" name="nazwa" [(ngModel)]="model.nazwa"
                (change)="inputChanged()" />

            <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('nazwa')"> {{ pobierzBladWalidacyjny("nazwa")
                }}</div>
        </div>
        <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('email')?'error':''">
            <input placeholder="Email" type="text" name="email" [(ngModel)]="model.email" (change)="inputChanged()" />

            <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('email')"> {{ pobierzBladWalidacyjny("email")
                }}</div>
        </div>
        <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('telefon')?'error':''">
            <input placeholder="Telefon komórkowy" type="text" name="telefon" [(ngModel)]="model.telefon"
                (change)="inputChanged()" />

            <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('telefon')"> {{
                pobierzBladWalidacyjny("telefon") }}</div>
        </div>
        <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('tresc')?'error':''">
            <textarea class="ft__tarea" id="" name="tresc" [(ngModel)]="model.tresc" placeholder="Treść powiadomienia"
                rows="8" (change)="inputChanged()"></textarea>
            <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('tresc')"> {{ pobierzBladWalidacyjny("tresc")
                }}</div>
        </div>
        <button class="btn__primary mb-3 " (click)="zapisz()">
            Zapisz
        </button>
        <button class="btn__primary btn__primary--cancel" (click)="zamknijModal()">
            Zamknij
        </button>
    </div>
</div>