<div class="modal-content" *ngIf="czyZaladowanoDane">
    <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.dismiss()"></button>

        <p class="text-center ft__05 mb-3 pb-0 pt-2">
            Formularz kontaktowy
        </p>

        <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('imie')?'error':''">
            <input type="text" placeholder="Imię" name="imie" [(ngModel)]="model.imie">
            <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('imie')"> {{ pobierzBladWalidacyjny("imie")}}
            </div>
        </div>

        <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('nazwisko')?'error':''">
            <input type="text" placeholder="Nazwisko" name="nazwisko" [(ngModel)]="model.nazwisko">
            <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('nazwisko')"> {{
                pobierzBladWalidacyjny("nazwisko")
                }}</div>
        </div>

        <div class="f-item pb-4">
            <input type="text" placeholder="Email" name="email" [(ngModel)]="email" disabled>
        </div>



        <div class="f-item mb-3" [ngClass]="czyBladWalidacyjny('tresc')?'error':''">
            <textarea class="ft__n" cols="30" id="" name="tresc" [(ngModel)]="model.tresc" rows="9"
                placeholder="Treść"></textarea>
            <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('tresc')"> {{ pobierzBladWalidacyjny("tresc")
                }}</div>
        </div>

        <button class="btn__primary mb-4" (click)="wyslij()">
            Wyślij
        </button>
        <button class="btn__primary btn__primary--cancel" (click)="activeModal.dismiss()">
            Anuluj
        </button>
    </div>
</div>