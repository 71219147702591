import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { IdModel } from 'src/app/sekcje/@wspolne/modele/id.model';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { PowiadomieniaService } from '../../serwisy/powiadomienia.service';

@Component({
  selector: 'app-powiadomienia-zamknij-edycje-modal',
  templateUrl: './powiadomienia-zamknij-edycje-modal.component.html',
  styleUrls: ['./powiadomienia-zamknij-edycje-modal.component.css']
})
export class PowiadomieniaZamknijEdycjeModalComponent extends BazowyComponent implements OnInit {

  public id: string;

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
  }

  public tak() {
    this.activeModal.close(true);
  }

  public nie() {
    this.activeModal.close(false);
  }
}
