import { RequestBazowy } from "../../@wspolne/modele/requestBazowy";

export class ZapiszGdzieCoMamModel extends RequestBazowy {
    pole1: string;
    pole2: string;
    pole3: string;
    pole4: string;
    pole5: string;
    pole6: string;
    pole7: string;
    pole8: string;
    pole9: string;
    pole10: string;
    pole11: string;
    pole12: string;
    pole13: string;
    pole14: string;
    pole15: string;
}