import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RequestBazowy } from 'src/app/sekcje/@wspolne/modele/requestBazowy';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { RozpocznijSubskrypcjeRequest } from '../../modele/rozpocznij-subskrypcje-request.model';
import { AbonamentyService } from '../../serwisy/abonamenty.service';
import { PlatnosciService } from '../../serwisy/platnosci.service';
import { AbonamentKomponentBazowy } from '../abonamentKomponentBazowy';

@Component({
  selector: 'app-abonament-wykup-nowy-abonament-modal',
  templateUrl: './abonament-wykup-nowy-abonament-modal.component.html',
  styleUrls: ['./abonament-wykup-nowy-abonament-modal.component.css']
})
export class AbonamentWykupNowyAbonamentModalComponent extends AbonamentKomponentBazowy implements OnInit {

  public rozpocznijSubskrypcjeFormularz = new RozpocznijSubskrypcjeRequest();

  public payuSdkForms: any;
  public secureForms: any;
  public cardNumber: any;
  public cardDate: any;
  public cardCvv: any;
  public tokenizeButton: any;
  public responseElement: any;
  public optionsForms: any = {
    cardIcon: true,
    style: {
      basic: {
        fontSize: '24px'
      }
    },
    placeholder: {
      number: '',
      date: 'MM/YY',
      cvv: ''
    },
    lang: 'pl'
  }

  constructor(public router: Router,
    public modalService: NgbModal,
    public loaderSerwis: LoaderService,
    public komunikatSerwis: ToastrService,
    public authService: AuthService,
    public abonamentSerwis: AbonamentyService,
    public platnoscieSerwis: PlatnosciService,
    public activeModal: NgbActiveModal) {
    super(router, modalService, loaderSerwis, komunikatSerwis, authService, abonamentSerwis,
      platnoscieSerwis, activeModal);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.inicjalizujFormularz();
    });
  }
  ngOnDestroy(): void {
    this.cardNumber?.remove();
    this.cardDate?.remove();
    this.cardCvv?.remove();
  }


  public tokenizuj(): void {
    this.responseElement.innerText = ''
    this.payuSdkForms.tokenize('MULTI').then((result) => {
      if (result.status === 'SUCCESS') {
        this.rozpocznijSubskrypcje(result.body.token);
      } else {
        this.renderError(result.error.messages);
      }
    });
  }


  public renderError(errors): void {
    var messages = [];
    errors.forEach(function (error) {
      messages.push(error.message);
    });
    this.wyswietlKomunikat(messages.join(', '), true);
  }


  private inicjalizujFormularz() {
    this.platnoscieSerwis.pobierzIdSklepu(new RequestBazowy()).subscribe((response) => {
      if (this.czyRezultatOk(response)) {
        this.payuSdkForms = PayU(response.element);
        this.secureForms = this.payuSdkForms.secureForms();

        this.cardNumber = this.secureForms.add('number', this.optionsForms);
        this.cardDate = this.secureForms.add('date', this.optionsForms);
        this.cardCvv = this.secureForms.add('cvv', this.optionsForms);

        this.cardNumber.render('#payu-card-number');
        this.cardDate.render('#payu-card-date');
        this.cardCvv.render('#payu-card-cvv');

        this.tokenizeButton = document.getElementById('tokenizeButton');
        this.responseElement = document.getElementById('responseTokenize');
      }
    });
  }

  private rozpocznijSubskrypcje(tokenKarty: string): void {
    this.rozpocznijSubskrypcjeFormularz.tokenKarty = tokenKarty;
    this.rozpocznijSubskrypcjeFormularz.rodzajAbonamentuId = this.wybranyAbonament.id;
    this.platnoscieSerwis.rozpocznijSubskrypcje(this.rozpocznijSubskrypcjeFormularz).subscribe((result) => {
      if (this.czyRezultatOk(result)) {
        if (result.element.redirectUrl) {
          window.location.href = result.element.redirectUrl;
          this.activeModal.close(true);
        } else {
          this.wyswietlKomunikat("Nowy abonament zostanie aktywowany po ustanowieniu płatności w PayU.");
          this.activeModal.close(true);
        }
      } else {
        this.wyswietlKomunikat(result.wiadomosc, true);
      }
    });
  }

}
