import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PobierzDaneDoRozszerzeniaAbonamentuRezultat } from 'src/app/modele/abonamenty/pobierzDaneDoRozszerzeniaAbonamentuRezultat';
import { RequestBazowy } from 'src/app/sekcje/@wspolne/modele/requestBazowy';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { OplacZmianeAbonamentuModel } from '../../modele/oplacZmianeAbonamentuModel';
import { PayUKarta } from '../../modele/pay-ukarta.model';
import { PobierzDaneDoZmianyAbonamentuModel } from '../../modele/pobierzDaneDoZmianyAbonamentuModel';
import { RodzajAbonamentu } from '../../modele/rodzaj-abonamentu.model';
import { RozpocznijSubskrypcjeRequest } from '../../modele/rozpocznij-subskrypcje-request.model';
import { AbonamentyService } from '../../serwisy/abonamenty.service';
import { PlatnosciService } from '../../serwisy/platnosci.service';
import { AbonamentKomponentBazowy, PakietAbonamentu } from '../abonamentKomponentBazowy';

@Component({
  selector: 'app-abonament-zmien-plan-modal',
  templateUrl: './abonament-zmien-plan-modal.component.html',
  styleUrls: ['./abonament-zmien-plan-modal.component.css']
})
export class AbonamentZmienPlanModalComponent extends AbonamentKomponentBazowy implements OnInit {

  public karta: PayUKarta;
  public pakietDoZmiany: PakietAbonamentu;
  public daneDoEkranuZmianyPlanu: PobierzDaneDoRozszerzeniaAbonamentuRezultat = new PobierzDaneDoRozszerzeniaAbonamentuRezultat();

  constructor(public router: Router,
    public modalService: NgbModal,
    public loaderSerwis: LoaderService,
    public komunikatSerwis: ToastrService,
    public authService: AuthService,
    public abonamentSerwis: AbonamentyService,
    public platnoscieSerwis: PlatnosciService,
    public activeModal: NgbActiveModal) {
    super(router, modalService, loaderSerwis, komunikatSerwis, authService, abonamentSerwis,
      platnoscieSerwis, activeModal);
  }

  ngOnInit(): void {
    setTimeout(() => {
      //this.pobierzDaneDoZmianyPlanu();
    });
  }
  ngOnDestroy(): void {
    this.cardNumber?.remove();
    this.cardDate?.remove();
    this.cardCvv?.remove();
  }



  // private inicjalizujFormularz() {
  //   if (this.karta) {
  //     return;
  //   }
  //   this.platnoscieSerwis.pobierzIdSklepu(new RequestBazowy()).subscribe((response) => {
  //     if (this.czyRezultatOk(response)) {
  //       this.payuSdkForms = PayU(response.element);
  //       this.secureForms = this.payuSdkForms.secureForms();

  //       this.cardNumber = this.secureForms.add('number', this.optionsForms);
  //       this.cardDate = this.secureForms.add('date', this.optionsForms);
  //       this.cardCvv = this.secureForms.add('cvv', this.optionsForms);

  //       this.cardNumber.render('#payu-card-number');
  //       this.cardDate.render('#payu-card-date');
  //       this.cardCvv.render('#payu-card-cvv');

  //       this.tokenizeButton = document.getElementById('tokenizeButton');
  //       this.responseElement = document.getElementById('responseTokenize');
  //     }
  //   });
  // }

  // private pobierzDaneDoZmianyPlanu() {
  //   var model = new PobierzDaneDoZmianyAbonamentuModel();
  //   model.nowyPlan = this.pobierzPakietAbonamentu();
  //   this.platnoscieSerwis.pobierzDaneDoZmianyPlanu(model).subscribe((response) => {
  //     if (this.czyRezultatOk(response)) {
  //       this.daneDoEkranuZmianyPlanu = response.element;
  //       this.czyZaladowanoDane = true;
  //       this.inicjalizujFormularz();
  //     }
  //     else {
  //       this.wyswietlKomunikat(response.wiadomosc, true);
  //       this.activeModal.dismiss();
  //     }
  //   });
  // }

  // public przejdzDoPlatnosci(tokenKarty: string) {
  //   var model = new OplacZmianeAbonamentuModel();
  //   model.tokenKarty = tokenKarty;
  //   model.nowyPlan = this.pobierzPakietAbonamentu();

  //   this.platnoscieSerwis.oplacZmianeAbonamentu(model).subscribe((result) => {
  //     if (this.czyRezultatOk(result)) {
  //       if (result.element.adresDoPrzekierowania) {
  //         window.location.href = result.element.adresDoPrzekierowania;
  //         this.activeModal.close(true);
  //       } else {
  //         this.wyswietlKomunikat("Nowy abonament zostanie aktywowany po ustanowieniu płatności w PayU.");
  //         this.activeModal.close(true);
  //       }
  //     } else {
  //       this.wyswietlKomunikat(result.wiadomosc, true);
  //     }
  //   });
  // }

  // public tokenizuj(): void {
  //   this.responseElement.innerText = ''
  //   this.payuSdkForms.tokenize('MULTI').then((result) => {
  //     if (result.status === 'SUCCESS') {
  //       this.przejdzDoPlatnosci(result.body.token);
  //     } else {
  //       this.renderError(result.error.messages);
  //     }
  //   });
  // }


  // public renderError(errors): void {
  //   var messages = [];
  //   errors.forEach(function (error) {
  //     messages.push(error.message);
  //   });
  //   this.wyswietlKomunikat(messages.join(', '), true);
  // }

  // public pobierzOstatnieCyfryKarty() {
  //   if (!this.karta || !this.karta.maskowanyNumerKarty || this.karta.maskowanyNumerKarty.length < 4) {
  //     return "";
  //   }

  //   var ostatnieCzteryCyfry = "";
  //   for (let i = 4; i > 0; i--) {
  //     ostatnieCzteryCyfry += this.karta.maskowanyNumerKarty.charAt(this.karta.maskowanyNumerKarty.length - i);
  //   }
  //   return ostatnieCzteryCyfry;

  // }

  // public pobierzMiesiacKarty() {
  //   if (!this.karta || !this.karta.miesiacWygasniecia) {
  //     return "";
  //   }

  //   return this.karta.miesiacWygasniecia;
  // }

  // public pobierzRokKarty() {
  //   if (!this.karta || !this.karta.rokWygasniecia) {
  //     return "";
  //   }

  //   return this.karta.rokWygasniecia;
  // }

  // public pobierzUrlIkonyDostawcyKarty() {
  //   if (!this.karta || !this.karta.urlMiniaturyDostawcyKarty) {
  //     return "";
  //   }

  //   return this.karta.urlMiniaturyDostawcyKarty;
  // }

  // private pobierzPakietAbonamentu() {
  //   switch (this.pakietDoZmiany) {
  //     case PakietAbonamentu.Standard5:
  //       return 0;
  //     case PakietAbonamentu.Standard10:
  //       return 1;
  //     case PakietAbonamentu.Standard20:
  //       return 2;
  //     case PakietAbonamentu.Standard100:
  //       return 3;
  //     default:
  //       break;
  //   }
  // }

}
