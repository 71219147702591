import { Component, OnInit } from '@angular/core';
import { BazowyComponent } from '../../../@wspolne/komponenty/bazowy.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ZmienHasloModel } from 'src/app/sekcje/konto/modele/zmianaHasla/zmienHasloModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ToastrService } from 'ngx-toastr';
import { ZmienHasloService } from 'src/app/sekcje/konto/serwisy/zmien-haslo.service';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';

@Component({
  selector: 'app-zmiana-hasla-nowe-haslo',
  templateUrl: './zmiana-hasla-nowe-haslo.component.html'
})
export class ZmianaHaslaNoweHasloComponent extends BazowyComponent implements OnInit {

  public token: string;
  public zmienHasloModel = new ZmienHasloModel();

  constructor(
    public authService: AuthService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private zmienHasloSerwis: ZmienHasloService,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);

  }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    if (!this.token) {
      this.router.navigate(['zaloguj']);
    }
  }

  public zmienHaslo() {
    this.zmienHasloModel.kod = this.token;
    this.zmienHasloSerwis.zmienHaslo(this.zmienHasloModel).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        if (this.czyZalogowany()) {
          this.wyswietlKomunikat('Hasło do konta zostało zmienione');
        }
        else {
          this.wyswietlKomunikat('Hasło do konta zostało zmienione. Zaloguj się.');
        }
        //this.authService.wyczyscCiasteczka();
        this.router.navigate(['zaloguj']);
        return;
      } else {
        this.bledyWalidacji = rezultat.bledyModelu;
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    });
  }
}
