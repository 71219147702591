import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { RequestBazowy } from 'src/app/sekcje/@wspolne/modele/requestBazowy';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ZapiszGdzieCoMamModel } from '../../modele/zapiszGdzieCoMamModel';
import { NotatkiService } from '../../serwisy/notatki.service';
@Component({
  selector: 'app-notatki',
  templateUrl: './notatki.component.html',
  styleUrls: ['./notatki.component.css']
})
export class NotatkiComponent extends BazowyComponent implements OnInit {

  public czyZaladowanoDane: boolean = false;
  public model: ZapiszGdzieCoMamModel = new ZapiszGdzieCoMamModel();

  constructor(
    private notatkiSerwis: NotatkiService,
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDaneDoEkranu();

    });
  }

  private pobierzDaneDoEkranu() {
    this.notatkiSerwis.pobierzDaneDoEkranu(new RequestBazowy()).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.model.pole1 = rezultat.element.pole1;
        this.model.pole2 = rezultat.element.pole2;
        this.model.pole3 = rezultat.element.pole3;
        this.model.pole4 = rezultat.element.pole4;
        this.model.pole5 = rezultat.element.pole5;
        this.model.pole6 = rezultat.element.pole6;
        this.model.pole7 = rezultat.element.pole7;
        this.model.pole8 = rezultat.element.pole8;
        this.model.pole9 = rezultat.element.pole9;
        this.model.pole10 = rezultat.element.pole10;
        this.model.pole11 = rezultat.element.pole11;
        this.model.pole12 = rezultat.element.pole12;
        this.model.pole13 = rezultat.element.pole13;
        this.model.pole14 = rezultat.element.pole14;
        this.model.pole15 = rezultat.element.pole15;

        this.czyZaladowanoDane = true;
        return;
      } else {
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    }
    );
  }

  public zapisz() {
    this.notatkiSerwis.zapisz(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat("Dane zostały zapisane");
        return;
      } else {
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    }
    );
  }


}
