<div class="wrap pt-5" role="document">
    <div class="content pt-5 mt-2">
        <div class="container">
            <p>Karty works!</p>
            <section class="container">
                <div class="card-container">
                    <aside>Numer Karty</aside>
                    <div class="payu-card-form" id="payu-card-number"></div>

                    <div class="card-details clearfix">
                        <div class="expiration">
                            <aside>Ważna do</aside>
                            <div class="payu-card-form" id="payu-card-date"></div>
                        </div>

                        <div class="cvv">
                            <aside>CVV</aside>
                            <div class="payu-card-form" id="payu-card-cvv"></div>
                        </div>
                    </div>
                </div>
                <button id="tokenizeButton" (click)="tokenizuj()">Tokenizuj</button>

                <div id="responseTokenize" style="color: black !important;">token</div>
            </section>
        </div>
    </div>
</div>
<select [(ngModel)]="wybranyAbonament" class="form-control">
    <option *ngFor="let rodzajAbonamentu of rodzajeAbonamentow" [ngValue]="rodzajAbonamentu">{{ rodzajAbonamentu.pakietAbonamentu }} ({{rodzajAbonamentu.czestotliwosc}}, {{ rodzajAbonamentu.cena }})</option>
</select>