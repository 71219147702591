<!-- <div class="modal-dialog modal-dialog-centered" role="document"> -->
    <div class="modal-content">
        <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()"></button>

            <div class="ft__01 text-center pt-3 text-gray">Zmiana adresu email</div>
            <p class="ft__07 text-center text-gray mt-3 mb-4 pb-2">Wprowadź nowy adres email</p>

            <form action="">
                <div class="f-input pb-3" [ngClass]="czyBladWalidacyjny('adresEmail')?'error':''">

                    <input type="text" placeholder="Email" name="email" [(ngModel)]="zmienAdresEmailFormularz.adresEmail" />

                    <div class="pt-2" [hidden]="!czyBladWalidacyjny('adresEmail')"> {{ pobierzBladWalidacyjny("adresEmail")
                        }}</div>

                </div>
                <div class="d-flex flex-column mt-3">
                    <button class="btn__primary" (click)="onZapiszNowyEmailClick()">Zapisz</button> <!-- disbled -->
                    <button class="btn__cancel mt-3" (click)="activeModal.dismiss()">Anuluj</button>
                </div>
            </form>
        </div>
    </div>
<!-- </div> -->