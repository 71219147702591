<div class="wrap pt-5" role="document" *ngIf="czyZaladowanoDane">
    <div class="content pt-5 mt-2">
        <div class="container-fluid mw690">
            <div class="text-center ft__01 mb-4">
                Ustawienia komunikatów
            </div>
            <p class="text-center ft__s18 ft__s18--01 pb-3">Zdefiniuj, kiedy chcesz otrzymywać <br>przypomnienia
                potwierdzenia obecności?</p>


            <div class="d-flex flex-wrap flex-md-row align-items-center pb-4">
                <div class="ft__16bold ft__16bold--1 text-left">Pierwsze przypomnienie po</div>
                <div class="px-2 py-1 py-md-0" [ngClass]="czyBladWalidacyjny('pierwszePrzypomnienie')?'error':''"><input
                        class="inInput" placeholder="ilość dni" type="number" name="pierwszePrzypomnienie"
                        [(ngModel)]="model.pierwszePrzypomnienie" appTylkoPelneLiczby min="5" max="90" /></div>
                <div class="ft__16bold ft__16bold--1 text-left">dniach od ostatniego logowania.</div>
                <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('pierwszePrzypomnienie')"> {{
                    pobierzBladWalidacyjny("pierwszePrzypomnienie")
                    }}</div>
            </div>

            <div class="d-flex flex-wrap flex-md-row align-items-center pb-4">
                <div class="ft__16bold ft__16bold--1 text-left">Drugie przypomnienie po</div>
                <div class="px-2 py-1 py-md-0" [ngClass]="czyBladWalidacyjny('drugiePrzypomnienie')?'error':''"><input
                        class="inInput" placeholder="ilość dni" type="number" name="drugiePrzypomnienie"
                        [(ngModel)]="model.drugiePrzypomnienie" appTylkoPelneLiczby /></div>
                <div class="ft__16bold ft__16bold--1 text-left">dniach od pierwszego przypomnienia</div>
                <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('drugiePrzypomnienie')"> {{
                    pobierzBladWalidacyjny("drugiePrzypomnienie")
                    }}</div>
            </div>

            <div class="d-flex flex-wrap flex-md-row align-items-center pb-4">
                <div class="ft__16bold ft__16bold--1 text-left">Trzecie przypomnienie po</div>
                <div class="px-2 py-1 py-md-0" [ngClass]="czyBladWalidacyjny('trzeciePrzypomnienie')?'error':''"><input
                        class="inInput" placeholder="ilość dni" type="number" name="trzeciePrzypomnienie"
                        [(ngModel)]="model.trzeciePrzypomnienie" appTylkoPelneLiczby /></div>
                <div class="ft__16bold ft__16bold--1 text-left">dniach od drugiego przypomnienia</div>
                <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('trzeciePrzypomnienie')"> {{
                    pobierzBladWalidacyjny("trzeciePrzypomnienie")
                    }}</div>
            </div>
        </div>
    </div>
    <div class="conf-p-clear pt-4 pt-sm-5 mt-3"></div>
    <div class="conf-p btn__bg mt-2">
        <div class="container-progress btn__bg--progress justify-content-center justify-content-md-end">
            <div class="btn__bg--progress ">
                <button class="btn__primary" (click)="zapisz()">
                    Zapisz
                </button>
            </div>
        </div>
    </div>

</div>