import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogowanieComponent } from './sekcje/konto/komponenty/logowanie/logowanie.component';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider, AmazonLoginProvider, } from 'angularx-social-login';
import { FormsModule } from '@angular/forms';
import { ZmianaHaslaEmailComponent } from './sekcje/konto/komponenty/zmiana-hasla-email/zmiana-hasla-email.component';
import { ZmianaHaslaNoweHasloComponent } from './sekcje/konto/komponenty/zmiana-hasla-nowe-haslo/zmiana-hasla-nowe-haslo.component';
//import { PotwierdzKontoComponent } from './komponenty/konto/rejestracja/potwierdz-konto/potwierdz-konto.component';
// import { PodziekowanieZaRejestracjeComponent } from './komponenty/konto/rejestracja/podziekowanie-za-rejestracje/podziekowanie-za-rejestracje.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from './serwisy/ogolne/loader.service';
import { ToastContainerModule, ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { KalendarzModalContent } from './komponenty/kalendarz/kalendarz-modal';
import { KalendarzService } from './serwisy/ogolne/kalendarz.service';
import { MenuComponent } from './komponenty/menu/menu/menu.component';
import { GodzinyModalComponent } from './komponenty/godziny/godziny-modal/godziny-modal.component';
import { AutosizeModule } from 'ngx-autosize';
import { HttpRequestInterceptor } from './sekcje/@wspolne/serwisy/http-request.interceptor';
import { AppInjectorModule } from './app.injector.module';
import { AbonamentyComponent } from './sekcje/abonamenty/komponenty/abonamenty/abonamenty.component';
import { NawigacjaComponent } from './sekcje/@wspolne/komponenty/nawigacja/nawigacja.component';
import { ZarejestrujPrzezEmailComponent } from './sekcje/konto/komponenty/rejestracja/zarejestruj-przez-email/zarejestruj-przez-email.component';
import { NaglowekNonAuthComponent } from './sekcje/konto/komponenty/naglowek-non-auth/naglowek-non-auth.component';
import { PotwierdzenieRejestracjiComponent } from './sekcje/konto/komponenty/rejestracja/potwierdzenie-rejestracji/potwierdzenie-rejestracji.component';
import { PodziekowanieZaRejestracjeComponent } from './sekcje/konto/komponenty/rejestracja/podziekowanie-za-rejestracje/podziekowanie-za-rejestracje.component';
import { AuthService } from './sekcje/@wspolne/serwisy/auth.service';
import { ErrorService } from './sekcje/@wspolne/serwisy/error.service';
import { UstawieniaProfiluComponent } from './sekcje/konto/komponenty/profil/ustawienia-profilu/ustawienia-profilu.component';
import { ZmianaEmailModalComponent } from './sekcje/konto/komponenty/profil/zmiana-email-modal/zmiana-email-modal.component';
import { PotwierdzEmailComponent } from './sekcje/konto/komponenty/profil/potwierdz-email/potwierdz-email.component';
import { MenuService } from './sekcje/@wspolne/serwisy/menu.service';
import { KonfiguracjaComponent } from './sekcje/konfiguracja/komponenty/konfiguracja/konfiguracja.component';
import { FakturyComponent } from './sekcje/faktury/komponenty/faktury/faktury.component';
import { InneComponent } from './sekcje/inne/komponenty/inne/inne.component';
import { KartaComponent } from './sekcje/abonamenty/komponenty/karta/karta.component';
import { PowiadomieniaComponent } from './sekcje/powiadomienia/komponenty/powiadomienia/powiadomienia.component';
import { PowiadomieniaPodpowiedzModalComponent } from './sekcje/powiadomienia/komponenty/powiadomienia-podpowiedz-modal/powiadomienia-podpowiedz-modal.component';
import { PowiadomieniaDodajModalComponent } from './sekcje/powiadomienia/komponenty/powiadomienia-dodaj-modal/powiadomienia-dodaj-modal.component';
import { PowiadomieniaEdytujModalComponent } from './sekcje/powiadomienia/komponenty/powiadomienia-edytuj-modal/powiadomienia-edytuj-modal.component';
import { PowiadomieniaPotwierdzDaneModalComponent } from './sekcje/powiadomienia/komponenty/powiadomienia-potwierdz-dane-modal/powiadomienia-potwierdz-dane-modal.component';
import { PowiadomieniaUsunModalComponent } from './sekcje/powiadomienia/komponenty/powiadomienia-usun-modal/powiadomienia-usun-modal.component';
import { TylkoPelneLiczbyDirective } from './sekcje/dyrektywy/tylko-pelne-liczby.directive';
import { PotwierdzAktywnoscComponent } from './sekcje/konto/komponenty/potwierdz-aktywnosc/potwierdz-aktywnosc.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { NotatkiComponent } from './sekcje/notatki/komponenty/notatki/notatki.component';
import { PolecModalComponent } from './sekcje/polec/komponenty/polec-modal/polec-modal.component';
import { PotwierdzKodSmsModalComponent } from './sekcje/konto/komponenty/profil/potwierdz-kod-sms-modal/potwierdz-kod-sms-modal.component';
import { ZmianaNumeruTelefonuNumerModalComponent } from './sekcje/konto/komponenty/profil/zmiana-numeru-telefonu/zmiana-numeru-telefonu-numer-modal/zmiana-numeru-telefonu-numer-modal.component';
import { ZmianaNumeruTelefonuKodSmsModalComponent } from './sekcje/konto/komponenty/profil/zmiana-numeru-telefonu/zmiana-numeru-telefonu-kod-sms-modal/zmiana-numeru-telefonu-kod-sms-modal.component';
import { UsunKontoModalComponent } from './sekcje/konto/komponenty/profil/usun-konto-modal/usun-konto-modal.component';
import { AbonamentUsunSubskrypcjeModalComponent } from './sekcje/abonamenty/komponenty/abonament-usun-subskrypcje-modal/abonament-usun-subskrypcje-modal.component';
import { AbonamentWykupNowyAbonamentModalComponent } from './sekcje/abonamenty/komponenty/abonament-wykup-nowy-abonament-modal/abonament-wykup-nowy-abonament-modal.component';
import { AbonamentZmienPlanModalComponent } from './sekcje/abonamenty/komponenty/abonament-zmien-plan-modal/abonament-zmien-plan-modal.component';
import { ZmienEmailAnulujSubskrypcjeModalComponent } from './sekcje/konto/komponenty/profil/zmien-email-anuluj-subskrypcje-modal/zmien-email-anuluj-subskrypcje-modal.component';
import { PowiadomieniaWyczerpanoLimitModalComponent } from './sekcje/powiadomienia/komponenty/powiadomienia-wyczerpano-limit-modal/powiadomienia-wyczerpano-limit-modal.component';
import { PowiadomieniaZamknijEdycjeModalComponent } from './sekcje/powiadomienia/komponenty/powiadomienia-zamknij-edycje-modal/powiadomienia-zamknij-edycje-modal.component';
import { FormularzKontaktowyModalComponent } from './sekcje/kontakt/komponenty/formularz-kontaktowy-modal/formularz-kontaktowy-modal.component';
import { AbonamentAktywujKodRabatowyModalComponent } from './sekcje/abonamenty/komponenty/abonament-aktywuj-kod-rabatowy-modal/abonament-aktywuj-kod-rabatowy-modal.component';


const googleLoginOptions = {
  scope: 'profile email'
};
@NgModule({
  declarations: [
    AppComponent,
    LogowanieComponent,
    ZmianaHaslaEmailComponent,
    ZmianaHaslaNoweHasloComponent,
    PodziekowanieZaRejestracjeComponent,
    KalendarzModalContent,
    MenuComponent,
    GodzinyModalComponent,
    AbonamentyComponent,
    NawigacjaComponent,
    ZarejestrujPrzezEmailComponent,
    NaglowekNonAuthComponent,
    PotwierdzenieRejestracjiComponent,
    UstawieniaProfiluComponent,
    ZmianaEmailModalComponent,
    PotwierdzEmailComponent,
    KonfiguracjaComponent,
    FakturyComponent,
    InneComponent,
    KartaComponent,
    PowiadomieniaComponent,
    PowiadomieniaPodpowiedzModalComponent,
    PowiadomieniaDodajModalComponent,
    PowiadomieniaEdytujModalComponent,
    PowiadomieniaPotwierdzDaneModalComponent,
    PowiadomieniaUsunModalComponent,
    TylkoPelneLiczbyDirective,
    PotwierdzAktywnoscComponent,
    NotatkiComponent,
    PolecModalComponent,
    PotwierdzKodSmsModalComponent,
    ZmianaNumeruTelefonuNumerModalComponent,
    ZmianaNumeruTelefonuKodSmsModalComponent,
    UsunKontoModalComponent,
    AbonamentUsunSubskrypcjeModalComponent,
    AbonamentWykupNowyAbonamentModalComponent,
    AbonamentZmienPlanModalComponent,
    ZmienEmailAnulujSubskrypcjeModalComponent,
    PowiadomieniaWyczerpanoLimitModalComponent,
    PowiadomieniaZamknijEdycjeModalComponent,
    FormularzKontaktowyModalComponent,
    AbonamentAktywujKodRabatowyModalComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    SocialLoginModule,
    FormsModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      enableHtml: true,
      maxOpened: 1
    }),
    AutosizeModule,
    AppInjectorModule,
    ClickOutsideModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider("857055553691-8n8a3u3cvusc1n06jdc2i6m1n5csfkh9.apps.googleusercontent.com", googleLoginOptions)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('164879282266968'),
          }
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorService
    },
    CookieService,
    LoaderService,
    KalendarzService,
    AuthService,
    MenuService,
    NgbActiveModal,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
