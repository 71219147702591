<app-naglowek-non-auth></app-naglowek-non-auth>
<div class="wrap" role="document">
    <div class="content">

        <div class="container">
            <div class="ft__01 text-center pb-4 pt-2">
                Zmiana hasła
            </div>
            <form (ngSubmit)="zmienHaslo()">
                <p class="ft__06 text-center pb-2">Wprowadź email podany w czasie <br>rejestracji, aby ustawić nowe hasło.</p>
                <!-- GCOJ-9-05 -->
                <!--p class="ft__06 text-center pb-2">Wprowadź nowe hasło do aplikacji <br>GdzieCoJest.pl</p-->
                <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('email')?'error':''">
                    <input placeholder="Email" type="email" name="email" [(ngModel)]="zmienHasloModel.email" />
                    <!-- GCOJ-9-03 -->
                    <!--div class="error pt-1">Niepoprawny adres email</div-->
                    <div class="error pt-3 text-right" [hidden]="!czyBladWalidacyjny('email')">
                        {{ pobierzBladWalidacyjny("email") }}
                    </div>
                </div>

                <!-- GCOJ-9-05 -->
                <!--p class="ft__04 mb-2 pb-2 login_info">
                    Hasło powinno zawierać co najmniej 8 znaków, cyfrę, małe i wielkie litery oraz znaki specjalne
                </p-->
                <!-- GCOJ-9-04 -->
                <!--p class="ft__04 mb-2 pb-2 login_info login_info__error">
                    Hasło powinno zawierać co najmniej 8 znaków, cyfrę, małe i wielkie litery oraz znaki specjalne
                </p-->

                <div class="f-item">
                    <button class="btn__primary">Zmień hasło</button>
                </div>

                <hr class="mt-4 mb-3">
                <p class="text-center">
                    Pamiętasz hasło? <a class="text-green fw-600" routerLink="/login">Zaloguj się</a>.
                </p>

            </form>
        </div>
    </div>
</div>