import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-powiadomienia-potwierdz-dane-modal',
  templateUrl: './powiadomienia-potwierdz-dane-modal.component.html',
  styleUrls: ['./powiadomienia-potwierdz-dane-modal.component.css']
})
export class PowiadomieniaPotwierdzDaneModalComponent implements OnInit {

  public telefon: string;
  public email: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  public tak() {
    this.activeModal.close(true);
  }
}
