import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SerwisBazowy } from 'src/app/serwisy/bazowy.service';
import { PojedynczyElementResponse } from '../../@wspolne/modele/pojedynczyElement';
import { RequestBazowy } from '../../@wspolne/modele/requestBazowy';
import { ResponseBazowy } from '../../@wspolne/modele/responseBazowy';
import { AuthService } from '../../@wspolne/serwisy/auth.service';
import { ErrorService } from '../../@wspolne/serwisy/error.service';
import { PobierzGdzieCoMamRezultat } from '../modele/pobierzGdzieCoMamRezultat';
import { ZapiszGdzieCoMamModel } from '../modele/zapiszGdzieCoMamModel';

@Injectable({
  providedIn: 'root'
})
export class NotatkiService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService) {
    super(httpClient, authService, errorService);
  }

  pobierzDaneDoEkranu(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, PojedynczyElementResponse<PobierzGdzieCoMamRezultat>>
      (this.AdresApi + "Notatki/PobierzDaneDoEkranu", model);

  }

  zapisz(model: ZapiszGdzieCoMamModel) {
    return this.wykonajAkcje<ZapiszGdzieCoMamModel, ResponseBazowy>
      (this.AdresApi + "Notatki/Zapisz", model);

  }
}
