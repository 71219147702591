import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-powiadomienia-wyczerpano-limit-modal',
  templateUrl: './powiadomienia-wyczerpano-limit-modal.component.html',
  styleUrls: ['./powiadomienia-wyczerpano-limit-modal.component.css']
})
export class PowiadomieniaWyczerpanoLimitModalComponent extends BazowyComponent implements OnInit {

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService,
    public activeModal: NgbActiveModal) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
  }

  public przejdzDoAbonamentow(): void {
    this.router.navigateByUrl('abonamenty/true');
    this.activeModal.dismiss();
  }


}
