import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { WyslijKodWeryfikacyjnyPonownieModel } from '../../../modele/rejestracja/wyslijKodWeryfikacyjnyPonownieModel';
import { RejestracjaService } from '../../../serwisy/rejestracja.service';

@Component({
  selector: 'app-podziekowanie-za-rejestracje',
  templateUrl: './podziekowanie-za-rejestracje.component.html'
})
export class PodziekowanieZaRejestracjeComponent extends BazowyComponent implements OnInit {

  public email: string;

  constructor(    
    public authService: AuthService,
    public router: Router, 
    private rejestracjaSerwis: RejestracjaService,
    private activatedRoute: ActivatedRoute, 
    modalService: NgbModal, 
    loaderSerwis: LoaderService, 
    private komunikatSerwis: ToastrService) {
      super(authService, modalService, loaderSerwis, komunikatSerwis, router);
    }

  ngOnInit(): void {
    this.email = this.activatedRoute.snapshot.paramMap.get('email');
  }

  public wyslijLinkPonownie() {
    try {
      var model = new WyslijKodWeryfikacyjnyPonownieModel();
      model.email = this.email;

      this.rejestracjaSerwis.wyslijLinkPonownie(model).subscribe(
        (rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.wyswietlKomunikat("Wysłano link aktywacyjny")
            return;
          }
          this.wyswietlKomunikat(rezultat.wiadomosc, true);
          return;
        }
      );
    } catch (blad) {
    }

  }

}
