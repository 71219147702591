import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { WeryfikujKodSmsDoZmianyNumeruTelefonuFormularz } from 'src/app/sekcje/konto/modele/profil/weryfikuj-kod-sms-do-zmiany-numeru-telefonu-formularz.model';
import { ProfilService } from 'src/app/sekcje/konto/serwisy/profil.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-usun-konto-modal',
  templateUrl: './usun-konto-modal.component.html',
  styleUrls: ['./usun-konto-modal.component.css']
})
export class UsunKontoModalComponent extends BazowyComponent implements OnInit {

  public weryfikujKodSmsDoZmianyNumeruTelefonuFormularz = new WeryfikujKodSmsDoZmianyNumeruTelefonuFormularz();

  constructor(
    private profilSerwis: ProfilService,
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService,
    public activeModal: NgbActiveModal) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
  }

  public usun(): void {
    this.activeModal.close(true);
  }

}
