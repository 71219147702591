import { PakietAbonamentu } from "src/app/sekcje/abonamenty/komponenty/abonamentKomponentBazowy";
import { PayUKarta } from "src/app/sekcje/abonamenty/modele/pay-ukarta.model";
import { RodzajAbonamentu } from "src/app/sekcje/abonamenty/modele/rodzaj-abonamentu.model";


export class PobierzDaneDoEkranuAbonamentuRezultat {
    ileDniZostalo: number;
    dataWaznosciAbonamentu: Date;
    pakietStandardCena: number;
    pakietPremiumCena: number;
    aktualnyPakiet: number;
    okresTestowy: boolean;
    sformatowanaDataWaznosciAbonamentu: string;
    opisAktualnegoPakietu: string;
    karta: PayUKarta;
    rodzajeAbonamentu: Array<RodzajAbonamentu>;
    rodzajeAbonamentuDoZmianyPlanu: Array<RodzajAbonamentu>;
    brakAbonamentu: boolean;
    czyNieUdaloSiePobracPlatnosci: boolean;
}