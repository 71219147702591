<app-naglowek-non-auth></app-naglowek-non-auth>
<div class="wrap" role="document">
    <div class="content">
        <div class="container">
            <p class="text-center pt-4 mb-0 mb-md-2 fw-600 ft__06">Dziękujemy za rejestrację w GdzieCoJest.pl. </p>
            <p class="text-center ft__06 mb-0">Na Twój adres email przesłaliśmy link do aktywacji konta.</p>
            <div class="text-center py-4">
                <img src="/assets/img/ico_sent.svg" alt="" />
            </div>
            <div class="text-center ft__06 pb-3">
                Mail weryfikacyjny nie dotarł? <br>
                <a class="text-green fw-600" [routerLink]="['./']" (click)="wyslijLinkPonownie()">Wyślij link ponownie</a>
            </div>
            <div class="box mt-4">
                <p class="text-center ft__04">Jeżeli jesteś już zarejestrowany:</p>
                <a class="btn__register" routerLink="/login">Zaloguj się</a>
            </div>
        </div>
    </div>
</div>