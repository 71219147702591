<div *ngIf="czyZaladowanoDane">
    <div *ngIf="!czyNieuzupelnionoDaneKonta()">
        <div class="wrap pt-5" role="document">
            <div class="content pt-5 mt-2">
                <div class="container">
                    <div class="text-center ft__20bold">Profil użytkownika</div>
                    <div class="d-flex justify-content-around align-items-center mt-4 mb-4 pt-2">
                        <div class="pers d-flex justify-content-start align-items-center">
                            <img id="pimg" src="/assets/img/ico_person.svg" alt="">
                            <div class="menu__type__account__name">
                                {{authService.getUserName()}}&nbsp;{{authService.getUserLastName()}}
                            </div>
                        </div>
                        <a [routerLink]="" (click)="usunKonto()" title="Usuń konto">
                            <svg width="22" height="24" viewBox="0 0 22 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14 0H8C7.20435 0 6.44129 0.316071 5.87868 0.87868C5.31607 1.44129 5 2.20435 5 3V4H1C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6H2.054L2.9 21.167C2.94273 21.9334 3.27756 22.6542 3.83561 23.1812C4.39365 23.7081 5.13246 24.0012 5.9 24H16.108C16.8755 24.0012 17.6143 23.7081 18.1724 23.1812C18.7304 22.6542 19.0653 21.9334 19.108 21.167L19.946 6H21C21.2652 6 21.5196 5.89464 21.7071 5.70711C21.8946 5.51957 22 5.26522 22 5C22 4.73478 21.8946 4.48043 21.7071 4.29289C21.5196 4.10536 21.2652 4 21 4H17V3C17 2.20435 16.6839 1.44129 16.1213 0.87868C15.5587 0.316071 14.7956 0 14 0V0ZM7 3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3V4H7V3ZM17.106 21.056C17.0917 21.3114 16.98 21.5516 16.794 21.7272C16.608 21.9028 16.3618 22.0004 16.106 22H5.892C5.63621 22.0004 5.38998 21.9028 5.20398 21.7272C5.01797 21.5516 4.90632 21.3114 4.892 21.056L4.057 6H17.943L17.106 21.056Z"
                                    fill="#558564" />
                            </svg>
                        </a>
                    </div>
                    <div class="f-input mb-3 pt-3" [ngClass]="czyBladWalidacyjny('imie')?'error':''">
                        <input type="text" name="model.imie" [(ngModel)]="model.imie" placeholder="Imię" />
                        <div class="error pt-3 text-right" [hidden]="!czyBladWalidacyjny('imie')">
                            {{ pobierzBladWalidacyjny("imie") }}
                        </div>
                    </div>
                    <div class="f-input mb-3 pb-2" [ngClass]="czyBladWalidacyjny('nazwisko')?'error':''">
                        <input type="text" name="model.nazwisko" [(ngModel)]="model.nazwisko" placeholder="Nazwisko" />
                        <div class="error pt-3 text-right" [hidden]="!czyBladWalidacyjny('nazwisko')">
                            {{ pobierzBladWalidacyjny("nazwisko") }}
                        </div>
                    </div>
                    <div class="f-input mb-3 pb-2 por">
                        <div class="itm w-100 "><input type="text" name="ds" [value]="daneKonta.email"
                                placeholder="E-mail" disabled="" /></div>
                        <button type="button" class="btn__edit" (click)="onZmienEmailBtnClick()"></button>
                    </div>
                    <div class="f-input mb-3 pb-2 por">
                        <div class="itm w-100"><input type="text" name="ds1" [value]="daneKonta.numerTelefonu"
                                placeholder="Telefon" disabled="" /></div>
                        <button type="button" class="btn__edit" (click)="onZmienNumerTelefonuBtnClick()"></button>
                    </div>
                    <div class="text-center pt-3 pb-3">
                        <a class="ft__16blod text-green" [routerLink]="" (click)="onZmienHasloClick()">Zmień hasło</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="conf-p-clear pt-4 pt-sm-5 mt-3"></div>
        <div class="conf-p btn__bg mt-2">
            <div class="container-progress btn__bg--progress justify-content-center justify-content-md-end">
                <div class="btn__bg--progress ">
                    <button type="button" class="btn__primary" (click)="zapisz()">Zapisz</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="czyNieuzupelnionoDaneKonta()">

        <div class="wrap pt-5" role="document">
            <div class="content pt-5 mt-2">
                <div class="container">
                    <div class="text-center ft__20bold">Profil użytkownika</div>
                    <div class="d-flex justify-content-around align-items-center mt-4 mb-4 pt-2">
                        <div class="pers d-flex justify-content-start align-items-center">
                            <img id="pimg" src="/assets/img/ico_person.svg" alt="">
                            <div class="menu__type__account__name">
                                {{authService.getUserName()}}&nbsp;{{authService.getUserLastName()}}
                            </div>
                        </div>
                        <a [routerLink]="" (click)="usunKonto()" title="Usuń konto">
                            <svg width="22" height="24" viewBox="0 0 22 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14 0H8C7.20435 0 6.44129 0.316071 5.87868 0.87868C5.31607 1.44129 5 2.20435 5 3V4H1C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6H2.054L2.9 21.167C2.94273 21.9334 3.27756 22.6542 3.83561 23.1812C4.39365 23.7081 5.13246 24.0012 5.9 24H16.108C16.8755 24.0012 17.6143 23.7081 18.1724 23.1812C18.7304 22.6542 19.0653 21.9334 19.108 21.167L19.946 6H21C21.2652 6 21.5196 5.89464 21.7071 5.70711C21.8946 5.51957 22 5.26522 22 5C22 4.73478 21.8946 4.48043 21.7071 4.29289C21.5196 4.10536 21.2652 4 21 4H17V3C17 2.20435 16.6839 1.44129 16.1213 0.87868C15.5587 0.316071 14.7956 0 14 0V0ZM7 3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3V4H7V3ZM17.106 21.056C17.0917 21.3114 16.98 21.5516 16.794 21.7272C16.608 21.9028 16.3618 22.0004 16.106 22H5.892C5.63621 22.0004 5.38998 21.9028 5.20398 21.7272C5.01797 21.5516 4.90632 21.3114 4.892 21.056L4.057 6H17.943L17.106 21.056Z"
                                    fill="#558564" />
                            </svg>
                        </a>
                    </div>
                    <div class="f-input mb-3 pt-3" [ngClass]="czyBladWalidacyjny('imie')?'error':''">
                        <input type="text" name="uzupelnijDaneModel.imie" [(ngModel)]="uzupelnijDaneModel.imie"
                            placeholder="Imię" />
                        <div class="error pt-3 text-right" [hidden]="!czyBladWalidacyjny('imie')">
                            {{ pobierzBladWalidacyjny("imie") }}
                        </div>
                    </div>
                    <div class="f-input mb-3 pb-2" [ngClass]="czyBladWalidacyjny('nazwisko')?'error':''">
                        <input type="text" name="uzupelnijDaneModel.nazwisko" [(ngModel)]="uzupelnijDaneModel.nazwisko"
                            placeholder="Nazwisko" />
                        <div class="error pt-3 text-right" [hidden]="!czyBladWalidacyjny('nazwisko')">
                            {{ pobierzBladWalidacyjny("nazwisko") }}
                        </div>
                    </div>
                    <div class="f-input mb-3 pb-2" [ngClass]="czyBladWalidacyjny('telefon')?'error':''">
                        <input type="text" name="uzupelnijDaneModel.telefon" [(ngModel)]="uzupelnijDaneModel.telefon"
                            placeholder="Telefon" />
                        <div class="error pt-3 text-right" [hidden]="!czyBladWalidacyjny('telefon')">
                            {{ pobierzBladWalidacyjny("telefon") }}
                        </div>
                    </div>

                    <div class="f-input mb-3 pb-2 por">
                        <div class="itm w-100 "><input type="text" name="ds" [value]="daneKonta.email"
                                placeholder="E-mail" disabled="" /></div>
                        <button type="button" class="btn__edit" (click)="onZmienEmailBtnClick()"></button>
                    </div>

                    <div class="text-center pt-3 pb-3">
                        <a class="ft__16blod text-green" [routerLink]="" (click)="onZmienHasloClick()">Zmień hasło</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="conf-p-clear pt-4 pt-sm-5 mt-3"></div>
        <div class="conf-p btn__bg mt-2">
            <div class="container-progress btn__bg--progress justify-content-center justify-content-md-end">
                <div class="btn__bg--progress ">
                    <button type="button" class="btn__primary" (click)="uzupelnijDaneKonta()">Zapisz</button>
                </div>
            </div>
        </div>

    </div>
</div>