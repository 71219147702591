import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SerwisBazowy } from 'src/app/serwisy/bazowy.service';
import { PojedynczyElementResponse } from '../../@wspolne/modele/pojedynczyElement';
import { RequestBazowy } from '../../@wspolne/modele/requestBazowy';
import { ResponseBazowy } from '../../@wspolne/modele/responseBazowy';
import { AuthService } from '../../@wspolne/serwisy/auth.service';
import { ErrorService } from '../../@wspolne/serwisy/error.service';
import { PobierzTrescPoleceniaModel } from '../modele/pobierzTrescPoleceniaModel';
import { PolecNasModel } from '../modele/polecNasModel';

@Injectable({
  providedIn: 'root'
})
export class PolecService extends SerwisBazowy {

  pobierzTrescPotwierdzAktywnosc(model: PobierzTrescPoleceniaModel) {
    return this.wykonajAkcje<PobierzTrescPoleceniaModel, PojedynczyElementResponse<string>>
      (this.AdresApi + "Polec/PobierzTrescPotwierdzAktywnosc", model);
  }

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService) {
    super(httpClient, authService, errorService);
  }

  pobierzTresc(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, PojedynczyElementResponse<string>>
      (this.AdresApi + "Polec/PobierzTresc", model);

  }

  wyslij(model: PolecNasModel) {
    return this.wykonajAkcje<PolecNasModel, ResponseBazowy>
      (this.AdresApi + "Polec/Wyslij", model);

  }
}
