import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ZmienHasloMailModel } from 'src/app/sekcje/konto/modele/zmianaHasla/zmienHasloMailModel';
import { ZmienHasloModel } from 'src/app/sekcje/konto/modele/zmianaHasla/zmienHasloModel';
import { SerwisBazowy } from 'src/app/serwisy/bazowy.service';
import { RequestBazowy } from '../../@wspolne/modele/requestBazowy';
import { ResponseBazowy } from '../../@wspolne/modele/responseBazowy';
import { AuthService } from '../../@wspolne/serwisy/auth.service';
import { ErrorService } from '../../@wspolne/serwisy/error.service';

@Injectable({
  providedIn: 'root'
})
export class ZmienHasloService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient, 
    authService: AuthService,
    errorService: ErrorService) {
    super(httpClient, authService, errorService);
  }

  zmienHasloMail(model: ZmienHasloMailModel) {
    return this.wykonajAkcje<ZmienHasloMailModel, ResponseBazowy>(this.AdresApi + "ZmienHaslo/ZmienHasloMail", model);

  }

  zmienHaslo(model: ZmienHasloModel) {
    return this.wykonajAkcje<ZmienHasloModel, ResponseBazowy>(this.AdresApi + "ZmienHaslo/ZmienHaslo", model);

  }
  zmienHasloZalogowanyMail(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, ResponseBazowy>(this.AdresApi + "ZmienHaslo/ZmienHasloZalogowanyMail", model);

  }
}
