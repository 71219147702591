import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogowanieComponent } from './sekcje/konto/komponenty/logowanie/logowanie.component'
// import { PodziekowanieZaRejestracjeComponent } from './komponenty/konto/rejestracja/podziekowanie-za-rejestracje/podziekowanie-za-rejestracje.component';
import { ZmianaHaslaEmailComponent } from './sekcje/konto/komponenty/zmiana-hasla-email/zmiana-hasla-email.component';
import { ZmianaHaslaNoweHasloComponent } from './sekcje/konto/komponenty/zmiana-hasla-nowe-haslo/zmiana-hasla-nowe-haslo.component';
import { AuthGuardService, AuthService } from './sekcje/@wspolne/serwisy/auth.service';
import { AbonamentyComponent } from './sekcje/abonamenty/komponenty/abonamenty/abonamenty.component';
import { ZarejestrujPrzezEmailComponent } from './sekcje/konto/komponenty/rejestracja/zarejestruj-przez-email/zarejestruj-przez-email.component';
import { PotwierdzenieRejestracjiComponent } from './sekcje/konto/komponenty/rejestracja/potwierdzenie-rejestracji/potwierdzenie-rejestracji.component';
import { PodziekowanieZaRejestracjeComponent } from './sekcje/konto/komponenty/rejestracja/podziekowanie-za-rejestracje/podziekowanie-za-rejestracje.component';
import { UstawieniaProfiluComponent } from './sekcje/konto/komponenty/profil/ustawienia-profilu/ustawienia-profilu.component';
import { PotwierdzEmailComponent } from './sekcje/konto/komponenty/profil/potwierdz-email/potwierdz-email.component';
import { KonfiguracjaComponent } from './sekcje/konfiguracja/komponenty/konfiguracja/konfiguracja.component';
import { FakturyComponent } from './sekcje/faktury/komponenty/faktury/faktury.component';
import { InneComponent } from './sekcje/inne/komponenty/inne/inne.component';
import { PowiadomieniaComponent } from './sekcje/powiadomienia/komponenty/powiadomienia/powiadomienia.component';
import { PotwierdzAktywnoscComponent } from './sekcje/konto/komponenty/potwierdz-aktywnosc/potwierdz-aktywnosc.component';
import { NotatkiComponent } from './sekcje/notatki/komponenty/notatki/notatki.component';

const routes: Routes = [
  { path: '', redirectTo: '/powiadomienia', pathMatch: 'full' },
  { path: 'zaloguj', component: LogowanieComponent, canActivate: [AuthGuardService] },
  { path: 'abonamenty', component: AbonamentyComponent, canActivate: [AuthGuardService] },
  { path: 'abonamenty/:czyZmienPlan', component: AbonamentyComponent, canActivate: [AuthGuardService] },
  { path: 'rejestracja', component: ZarejestrujPrzezEmailComponent, canActivate: [AuthGuardService] },
  { path: 'rejestracja-powodzenie/:email', component: PodziekowanieZaRejestracjeComponent, canActivate: [AuthGuardService] },
  { path: 'rejestracja-potwierdzenie/:kod', component: PotwierdzenieRejestracjiComponent, canActivate: [AuthGuardService] },
  { path: 'zmien-haslo', component: ZmianaHaslaEmailComponent, canActivate: [AuthGuardService] },
  { path: 'nowe-haslo-email/:token', component: ZmianaHaslaNoweHasloComponent, canActivate: [AuthGuardService] },
  { path: 'ustawienia-profilu', component: UstawieniaProfiluComponent, canActivate: [AuthGuardService] },
  { path: 'potwierdz-email/:token', component: PotwierdzEmailComponent, canActivate: [AuthGuardService] },
  { path: 'konfiguracja', component: KonfiguracjaComponent, canActivate: [AuthGuardService] },
  { path: 'faktury', component: FakturyComponent, canActivate: [AuthGuardService] },
  { path: 'inne', component: InneComponent, canActivate: [AuthGuardService] },
  { path: 'powiadomienia', component: PowiadomieniaComponent, canActivate: [AuthGuardService] },
  { path: 'potwierdz-aktywnosc/:kod', component: PotwierdzAktywnoscComponent },
  { path: 'gdzie-co-mam', component: NotatkiComponent, canActivate: [AuthGuardService] },


  // { path: 'register', component: RejestracjaComponent },
  // { path: 'change-password', component: ZmianaHaslaEmailComponent },
  // { path: 'new-password/:token', component: ZmianaHaslaNoweHasloComponent },
  // { path: 'confirm/:kod', component: PotwierdzKontoComponent },
  // { path: 'register-success/:email', component: PodziekowanieZaRejestracjeComponent },
  // { path: 'subscription', component: AbonamentComponent },
  // { path: 'subscription/:komunikat/:id', component: AbonamentComponent },
  // { path: 'subscription-plan', component: AbonamentPlanComponent },
  // { path: 'subscription-sms', component: AbonamentSmsComponent },
  // { path: 'no-subscription', component: BrakAbonamentuComponent },

  { path: '**', redirectTo: '/zaloguj', pathMatch: 'full' },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuardService],
  exports: [RouterModule]
})
export class AppRoutingModule { }
