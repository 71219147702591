import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PobierzDaneDoEkranuAbonamentuRezultat } from 'src/app/modele/abonamenty/pobierzDaneDoEkranuAbonamentuRezultat';
import { PobierzDaneDoRozszerzeniaAbonamentuRezultat } from 'src/app/modele/abonamenty/pobierzDaneDoRozszerzeniaAbonamentuRezultat';
import { SerwisBazowy } from 'src/app/serwisy/bazowy.service';
import { ListaWynikowResponse } from '../../@wspolne/modele/listaWynikowResponse';
import { PojedynczyElementResponse } from '../../@wspolne/modele/pojedynczyElement';
import { RequestBazowy } from '../../@wspolne/modele/requestBazowy';
import { ResponseBazowy } from '../../@wspolne/modele/responseBazowy';
import { AuthService } from '../../@wspolne/serwisy/auth.service';
import { ErrorService } from '../../@wspolne/serwisy/error.service';
import { AktywujKodRabatowyModel } from '../modele/aktywujKodRabatowyModel';
import { RodzajAbonamentu } from '../modele/rodzaj-abonamentu.model';

@Injectable({
  providedIn: 'root'
})
export class AbonamentyService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService) {
    super(httpClient, authService, errorService);
  }

  public PobierzRodzajeAbonamentow(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, ListaWynikowResponse<RodzajAbonamentu>>(`${this.AdresApi}${AbonamentAdres.Controller}/${AbonamentAdres.PobierzRodzajeAbonamentow}`, model);
  }
  public test(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, ResponseBazowy>(`${this.AdresApi}${AbonamentAdres.Controller}/Test`, model);
  }

  public pobierzDaneDoEkranuAbonamentu(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, PojedynczyElementResponse<PobierzDaneDoEkranuAbonamentuRezultat>>
      (`${this.AdresApi}${AbonamentAdres.Controller}/${AbonamentAdres.PobierzDaneDoEkranuAbonamentu}`, model);
  }

  public zrezygnujZSubskrypcji(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, ResponseBazowy>
      (`${this.AdresApi}${AbonamentAdres.Controller}/${AbonamentAdres.ZrezygnujZSubskrypcji}`, model);
  }

  public aktywujKodRabatowy(model: AktywujKodRabatowyModel) {
    return this.wykonajAkcje<AktywujKodRabatowyModel, ResponseBazowy>
      (`${this.AdresApi}${AbonamentAdres.Controller}/${AbonamentAdres.AktywujKodRabatowy}`, model);
  }
}

enum AbonamentAdres {
  Controller = "Abonament",
  PobierzRodzajeAbonamentow = "PobierzRodzajeAbonamentow",
  PobierzDaneDoEkranuAbonamentu = "PobierzDaneDoEkranuAbonamentu",
  ZrezygnujZSubskrypcji = "ZrezygnujZSubskrypcji",
  AktywujKodRabatowy = "AktywujKodRabatowy"
}