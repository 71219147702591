import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { Ciasteczko } from 'src/app/sekcje/@wspolne/modele/ciasteczka';
import { ModalOpcje } from 'src/app/sekcje/@wspolne/modele/modal-opcje.ogolne';
import { RequestBazowy } from 'src/app/sekcje/@wspolne/modele/requestBazowy';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { ZmienNumerTelefonuFormularz } from 'src/app/sekcje/konto/modele/profil/zmien-numer-telefonu-formularz.model';
import { ProfilService } from 'src/app/sekcje/konto/serwisy/profil.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-zmiana-numeru-telefonu-numer-modal',
  templateUrl: './zmiana-numeru-telefonu-numer-modal.component.html',
  styleUrls: ['./zmiana-numeru-telefonu-numer-modal.component.css']
})
export class ZmianaNumeruTelefonuNumerModalComponent extends BazowyComponent implements OnInit {

  public zmienNumerTelefonuFormularz = new ZmienNumerTelefonuFormularz();

  constructor(
    private profilSerwis: ProfilService,
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService,
    public activeModal: NgbActiveModal) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
  }

  public onZapiszNowyNumerClick(): void {
    this.bledyWalidacji = undefined;
    this.profilSerwis.ZmienNumerTelefonuIWyslijKodSms(this.zmienNumerTelefonuFormularz).subscribe(
      (rezultat) => {
        this.schowajLadowacz();
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat('Kod sms został wysłany na wskazany numer telefonu.');
          this.activeModal.close();
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    );

  }
}
