import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { delay } from 'rxjs/operators';
import { BazowyComponent } from './sekcje/@wspolne/komponenty/bazowy.component';
import { BazowyComponentZMenu } from './komponenty/bazowyZMenu.component';
import { AuthService } from './sekcje/@wspolne/serwisy/auth.service';
import { LoadingService } from './sekcje/@wspolne/serwisy/loading.service';
import { LoaderService } from './serwisy/ogolne/loader.service';
import { MenuService } from './sekcje/@wspolne/serwisy/menu.service';
import { Konfiguracja } from 'src/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends BazowyComponent {
  title = 'GdzieCoJest.pl';

  czyPokazLadowacz: boolean;

  constructor(
    public menuService: MenuService,
    public authService: AuthService,
    modalService: NgbModal,
    public loaderService: LoaderService,
    komunikatSerwis: ToastrService,
    router: Router,
    private authSerwis: AuthService,
    private loadingService: LoadingService) {
    super(authService, modalService, loaderService, komunikatSerwis, router);
  }
  ngOnInit() {
    this.listenToLoading();
    var config = new Konfiguracja();
    this.loadScript(config.AdresSdkPayU);
  }

  public isAuthorized(): boolean {
    return this.authSerwis.isLoggedIn;
  }

  /**
* Listen to the loadingSub property in the LoadingService class. This drives the
* display of the loading spinner.
*/
  private listenToLoading(): void {
    this.loadingService.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.czyPokazLadowacz = loading;
      });
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
}
