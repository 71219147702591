import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { Ciasteczko } from 'src/app/sekcje/@wspolne/modele/ciasteczka';
import { ModalOpcje } from 'src/app/sekcje/@wspolne/modele/modal-opcje.ogolne';
import { RequestBazowy } from 'src/app/sekcje/@wspolne/modele/requestBazowy';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EdytujDaneKontaModel } from '../../../modele/profil/edytujDaneKontaModel';
import { PobierzDaneKontaRezultat } from '../../../modele/profil/pobierzDaneKontaRezultat';
import { UzupelnijDaneKontaModel } from '../../../modele/profil/uzupelnijDaneKontaModel';
import { ZmienAdresEmailFormularz } from '../../../modele/profil/zmien-adres-email-formularz.model';
import { ProfilService } from '../../../serwisy/profil.service';
import { ZmienHasloService } from '../../../serwisy/zmien-haslo.service';
import { PotwierdzKodSmsModalComponent } from '../potwierdz-kod-sms-modal/potwierdz-kod-sms-modal.component';
import { UsunKontoModalComponent } from '../usun-konto-modal/usun-konto-modal.component';
import { ZmianaEmailModalComponent } from '../zmiana-email-modal/zmiana-email-modal.component';
import { ZmianaNumeruTelefonuKodSmsModalComponent } from '../zmiana-numeru-telefonu/zmiana-numeru-telefonu-kod-sms-modal/zmiana-numeru-telefonu-kod-sms-modal.component';
import { ZmianaNumeruTelefonuNumerModalComponent } from '../zmiana-numeru-telefonu/zmiana-numeru-telefonu-numer-modal/zmiana-numeru-telefonu-numer-modal.component';
import { ZmienEmailAnulujSubskrypcjeModalComponent } from '../zmien-email-anuluj-subskrypcje-modal/zmien-email-anuluj-subskrypcje-modal.component';

@Component({
  selector: 'app-ustawienia-profilu',
  templateUrl: './ustawienia-profilu.component.html',
  // host: { 'class': 'container' }

})
export class UstawieniaProfiluComponent extends BazowyComponent implements OnInit {
  public model: EdytujDaneKontaModel = new EdytujDaneKontaModel();
  public uzupelnijDaneModel: UzupelnijDaneKontaModel = new UzupelnijDaneKontaModel();
  public daneKonta: PobierzDaneKontaRezultat = new PobierzDaneKontaRezultat();
  public zmienAdresEmailFormularz = new ZmienAdresEmailFormularz();
  public czyZaladowanoDane: boolean = false;
  public wyslanoProsbeOZmianeHasla: boolean = false;
  constructor(
    private profilService: ProfilService,
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService,
    private zmienHasloSerwis: ZmienHasloService,
    private activeModal: NgbModal) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDaneDoEkranu();
      this.activeModal.dismissAll();
    });
  }

  private pobierzDaneDoEkranu() {
    this.profilService.pobierzDaneKonta(new RequestBazowy()).subscribe((rezultat) => {
      this.czyZaladowanoDane = true;
      if (this.czyRezultatOk(rezultat)) {
        this.daneKonta = rezultat.element;
        this.model.imie = rezultat.element.imie;
        this.model.nazwisko = rezultat.element.nazwisko;
        this.zmienAdresEmailFormularz.adresEmail = rezultat.element.email;
        return;
      } else {
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    }
    );
  }

  public zapisz() {
    this.profilService.edytujDaneKonta(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat("Dane zostały zapisane");
        var daneUzytkownika = this.pobierzDaneZalogowanegoUzytkownika();
        daneUzytkownika.imie = rezultat.element.imie;
        daneUzytkownika.nazwisko = rezultat.element.nazwisko;
        this.ustawCiasteczko(Ciasteczko.DaneZalogowanegoUzytkownika, daneUzytkownika);
        this.bledyWalidacji = null;
        return;
      } else if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }
      this.wyswietlKomunikat(rezultat.wiadomosc, true);
    }
    );
  }

  public uzupelnijDaneKonta() {
    this.profilService.uzupelnijDaneKonta(this.uzupelnijDaneModel).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.bledyWalidacji = null;
        this.modalReference = this.modalService.open(PotwierdzKodSmsModalComponent, new ModalOpcje(true, 'static'));
        this.modalReference.componentInstance.model = this.uzupelnijDaneModel;

        this.modalReference.result.then((czyZapisano) => {
          if (czyZapisano) {
            this.wyswietlKomunikat("Dane zostały zapisane");
            var daneUzytkownika = this.pobierzDaneZalogowanegoUzytkownika();
            daneUzytkownika.imie = this.uzupelnijDaneModel.imie;
            daneUzytkownika.nazwisko = this.uzupelnijDaneModel.nazwisko;
            this.ustawCiasteczko(Ciasteczko.DaneZalogowanegoUzytkownika, daneUzytkownika);
            this.bledyWalidacji = null;
            this.router.navigate(['konfiguracja']);
          }
        }, onRejected => {
          this.uzupelnijDaneModel.czyWpisywanieKodu = false;
        });


        return;
      } else if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }
      this.wyswietlKomunikat(rezultat.wiadomosc, true);
    }
    );
  }

  public onZmienNumerTelefonuBtnClick(): void {
    const zmianaNumeruTelefonuModalRef = this.modalService.open(ZmianaNumeruTelefonuNumerModalComponent, new ModalOpcje(true));
    zmianaNumeruTelefonuModalRef.result.then(() => {
      const weryfikujKodSmsModalRef = this.modalService.open(ZmianaNumeruTelefonuKodSmsModalComponent, new ModalOpcje(true, 'static', false));
      weryfikujKodSmsModalRef.result.then(() => {
        this.pobierzDaneDoEkranu();
      }, onRejected => { });
    }, onRejected => { });
  }

  public onZmienEmailBtnClick(): void {

    if (this.daneKonta.czyMaAktywnaSubskrypcje) {
      this.modalService.open(ZmienEmailAnulujSubskrypcjeModalComponent,
        new ModalOpcje(true, 'static')).result.then((tak) => {
          if (tak) {
            const zmianaEmailModalRef = this.modalService.open(ZmianaEmailModalComponent, new ModalOpcje(true, 'static'));
            zmianaEmailModalRef.result.then((result) => {
              this.wyswietlKomunikat('Link do zmiany adresu email został wysłany.');
            }, onRejected => { });
          }
        }, onRejected => { });

    }
    else {
      const zmianaEmailModalRef = this.modalService.open(ZmianaEmailModalComponent, new ModalOpcje(true, 'static'));
      zmianaEmailModalRef.result.then((result) => {
        this.wyswietlKomunikat('Link do zmiany adresu email został wysłany.');
      }, onRejected => { });
    }


  }

  public onZmienHasloClick() {
    // this.wyslanoProsbeOZmianeHasla = true;
    // setTimeout(() => {
    //   this.wyslanoProsbeOZmianeHasla = false;
    // }, 4000);

    this.zmienHasloSerwis.zmienHasloZalogowanyMail(new RequestBazowy()).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlInformacje('Prośba o zmianę hasła została wysłana. Na adres email zarejestrowany w systemie otrzymasz email z linkiem umożliwiającym ustawienie nowego hasła.');
          return;
        }
        else {
          this.wyswietlKomunikat(rezultat.wiadomosc, true);
        }
      }
    );
  }

  public czyNieuzupelnionoDaneKonta() {
    return this.daneKonta == undefined || this.daneKonta.imie == undefined || !this.daneKonta.imie.length
      || this.daneKonta.nazwisko == undefined || !this.daneKonta.nazwisko.length
      || this.daneKonta.numerTelefonu == undefined || !this.daneKonta.numerTelefonu.length
  }

  public usunKonto() {
    const zmianaEmailModalRef = this.modalService.open(UsunKontoModalComponent, new ModalOpcje(true, 'static'));
    zmianaEmailModalRef.result.then((tak) => {
      if (!tak) {
        return;
      }

      this.profilService.usunKonto(new RequestBazowy()).subscribe(
        (rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.wyswietlInformacje('Konto zostało usunięte');
            this.authService.logOut();
            return;
          }
          else {
            this.wyswietlKomunikat(rezultat.wiadomosc, true);
          }
        }
      );

    }, onRejected => { });
  }

}
