import { Component, OnInit } from '@angular/core';
import { DaneZalogowanegoUzytkownika } from '../../konto/modele/logowanie/daneZalogowanegoUzytkownika';
import { BladModelu } from '../modele/bladModelu';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from '../../../serwisy/ogolne/loader.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ResponseBazowy } from '../modele/responseBazowy';
import { StatusOperacji } from '../modele/statusOperacji';
import { Ciasteczko } from '../modele/ciasteczka';
import { AuthService } from '../serwisy/auth.service';

@Component({
    template: ''
})
export abstract class BazowyComponent {

    public bledyWalidacji: BladModelu[] = new Array();
    public modalReference: NgbModalRef;

    constructor(public authService: AuthService, public modalService: NgbModal, public loaderSerwis:
        LoaderService, private komunikatySerwis: ToastrService, public router: Router) {
    }

    public wyswietlKomunikat(komunikat: string, czyBlad?: boolean) {
        czyBlad = czyBlad == undefined ? false : czyBlad;
        if (komunikat == undefined || komunikat.length == 0) {
            return;
        } // css'y nie chcą działać
        if (czyBlad) {
            this.komunikatySerwis.error(`<div class="info__error"><div class="container"><div class="info__text bg-center">` + komunikat + `</div></div></div>`); //todo: css z ikoną błędu analogicznie do info__text
            //this.komunikatySerwis.error(komunikat);
        }
        else {
            this.komunikatySerwis.success(`<div class=" info__success"><div class="container"><div class="info__text bg-center">` + komunikat + `</div></div></div>`);
            // this.komunikatySerwis.success(`<div class="container"><div class="info__text">` + komunikat + `</div></div>`);
            //this.komunikatySerwis.success(komunikat);
        }
    }

    public wyswietlInformacje(tresc: string) {
        if (tresc == undefined || tresc.length == 0) {
            return;
        }
        this.komunikatySerwis.info(`<div class="container"><div class="info__text">` + tresc + `</div></div>`);
        //this.komunikatySerwis.info(`<div class="komunikat_info ft__info">` + tresc + `</div>`);
        //this.komunikatySerwis.info(tresc);
    }

    // public czyRezultatBledny(rezultat: ResponseBazowy) {

    //     return rezultat.statusOperacji != StatusOperacji.oK
    // }

    public czyRezultatOk(rezultat: ResponseBazowy) {

        if (rezultat.czyWylogowac) {
            this.wyczyscCiasteczka();
            this.router.navigate(['zaloguj']);
            return;
        }
        if (rezultat.czyBrakAbonamentu) {
            this.router.navigate(['abonamenty']);
            this.wyswietlInformacje("Wykup abonament aby móc korzystać z aplikacji.");
            return;
        }
        if (rezultat.czyNieuzupelnionyProfil) {
            this.router.navigate(['ustawienia-profilu']);
            this.wyswietlInformacje("Uzupełnij dane konta aby móc korzystać z aplikacji.");
            return;
        }
        return rezultat.statusOperacji == StatusOperacji.oK
    }

    public bladModelu(rezultat: ResponseBazowy) {
        return rezultat.statusOperacji == StatusOperacji.bladWalidacji || rezultat.statusOperacji == StatusOperacji.bladWalidacjiLink;
    }

    public ustawCiasteczko(ciasteczko: Ciasteczko, dane: any) {
        this.authService.ustawCiasteczko(ciasteczko, dane);
    }

    public pobierzCiasteczko<T>(ciasteczko: Ciasteczko): T {
        return this.authService.pobierzCiasteczko<T>(ciasteczko);
    }

    public pobierzDaneZalogowanegoUzytkownika() {
        return this.pobierzCiasteczko<DaneZalogowanegoUzytkownika>(Ciasteczko.DaneZalogowanegoUzytkownika);
    }

    public czyZalogowany() {
        return this.authService.czyZalogowany();
    }


    public pobierzToken() {
        if (!this.czyZalogowany()) {
            return null;
        }
        var uzytkownik = this.pobierzDaneZalogowanegoUzytkownika();

        return uzytkownik.token;
    }

    public pobierzIdUzytkownika() {
        if (!this.czyZalogowany()) {
            return null;
        }
        var uzytkownik = this.pobierzDaneZalogowanegoUzytkownika();

        return uzytkownik.idUzytkownika;
    }

    public wyczyscCiasteczka() {
        this.authService.wyczyscCiasteczka();
    }

    public czyBladWalidacyjny(wlasciwosc: string) {
        if (this.bledyWalidacji == undefined) {
            return false;
        }
        return this.bledyWalidacji.find(m => m.wlasciwosc.toLowerCase() == wlasciwosc.toLowerCase());
    }

    public czyBladWalidacyjnyZLinkiem(wlasciwosc: string) {
        if (this.bledyWalidacji == undefined) {
            return false;
        }
        return this.bledyWalidacji.find(m => m.wlasciwosc.toLowerCase() == wlasciwosc.toLowerCase() && m.rodzajLinku != null);
    }

    public pobierzBladWalidacyjny(wlasciwosc: string) {
        if (this.bledyWalidacji == undefined) {
            return "";
        }
        var blad = this.bledyWalidacji.find(m => m.wlasciwosc.toLowerCase() == wlasciwosc.toLowerCase());

        if (blad == undefined) {
            return "";
        }
        return blad.blad;
    }

    public czySaWynikiNaLiscie(listaElementow: any[]) {
        if (listaElementow == null) {
            return false;
        }
        return listaElementow.length != 0;
    }

    public pokazLadowacz() {
        this.loaderSerwis.pokazLadowacz(true);
    }

    public schowajLadowacz() {
        this.loaderSerwis.pokazLadowacz(false);
    }
    public pokazModal(content) {
        this.modalReference = this.modalService.open(content, { centered: true });
    }
    public pokazModalCustom(content, options) {
        this.modalReference = this.modalService.open(content, options);
    }
    public schowjModal() {
        this.modalService.dismissAll();
    }

    public kopiujObiekt<T>(obiekt: any): T {
        return JSON.parse(JSON.stringify(obiekt)) as T;
    }

    foramtujDateTimezone(data: Date) {
        var timeZoneDifference = (data.getTimezoneOffset() / 60) * -1;
        data.setTime(data.getTime() + (timeZoneDifference * 60) * 60 * 1000);
        return data;
    }

    formatujCene(cena: number) {
        if (!cena) {
            return "";
        }
        return cena.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' });
    }

    ngAfterViewInit(): void {
        const burgerMenu = document.getElementById("burger");
        const navbarMenu = document.getElementById("menu");
        const navbrandMenu = document.querySelector(".navbrand");
        // Responsive Navbar Toggle
        if (burgerMenu !== null) {
            navbarMenu.classList.remove("active");
            burgerMenu.classList.remove("active");
            navbrandMenu.classList.remove("active");
        }
    }
}