import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from '../serwisy/ogolne/loader.service';
import { BazowyComponent } from '../sekcje/@wspolne/komponenty/bazowy.component';
import { Router } from '@angular/router';
import { NawigacjaService } from '../serwisy/ogolne/nawigacja.service';

import { ToastrService } from 'ngx-toastr';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Ekran } from '../sekcje/@wspolne/modele/ekran';
import { Nawigacja } from '../sekcje/@wspolne/modele/nawigacja';
import { Rola } from '../sekcje/@wspolne/modele/rola';
import { AuthService } from '../sekcje/@wspolne/serwisy/auth.service';

@Component({
    template: ''
})
export class BazowyComponentZMenu extends BazowyComponent {

    menu: Nawigacja;
    czyPokazacMenuMobile: boolean;

    constructor(public authService: AuthService, modalService: NgbModal, loaderSerwis: LoaderService, public router: Router,
        komunikatSerwis: ToastrService) {
        super(authService, modalService, loaderSerwis, komunikatSerwis, router);
    }



    /**
    * wylogujSie
    */
    public wylogujSie() {
        this.wyczyscCiasteczka();
        this.router.navigate(['zaloguj']);
    }

    public pokazMenuMobile() {
        this.czyPokazacMenuMobile = !this.czyPokazacMenuMobile;
    }

    public czyAdministrator(): boolean {
        if (this.menu == undefined) {
            return false;
        }
        return this.menu.czyAdministrator;
    }

    public czyLekarz() {
        if (this.menu == undefined) {
            return false;
        }
        return this.menu.rolaUzytkownika == Rola.lekarz;
    }

    public czyEkranAbonamentow() {
        if (this.menu == undefined) {
            return false;
        }
        return this.menu.ekran == Ekran.abonamenty;
    }
    public czyEkranFaktur() {
        if (this.menu == undefined) {
            return false;
        }
        return this.menu.ekran == Ekran.faktury;
    }
    public czyEkranKonfiguracji() {
        if (this.menu == undefined) {
            return false;
        }
        return this.menu.ekran == Ekran.konfiguracja;
    }
    public czyEkranPacjentow() {
        if (this.menu == undefined) {
            return false;
        }
        return this.menu.ekran == Ekran.pacjenci;
    }
    public czyEkranRejestruWizyt() {
        if (this.menu == undefined) {
            return false;
        }
        return this.menu.ekran == Ekran.rejestrWizyt;
    }

    public czyPrzyciskWstecz() {
        if (this.menu == undefined || !this.menu.czyPrzyciskWstecz) {
            return false;
        }
        return this.menu.czyPrzyciskWstecz;
    }
    public czyPrzyciskZapisz() {
        if (this.menu == undefined || !this.menu.czyPrzyciskZapisz) {
            return false;
        }
        return this.menu.czyPrzyciskZapisz;
    }

    public pobierzNazweUzytkownika() {
        var uzytkownik = this.pobierzDaneZalogowanegoUzytkownika();
        if (uzytkownik == undefined || this.menu == undefined) {
            return "";
        }
        this.menu.rolaUzytkownika = uzytkownik.rola;
        this.menu.nazwaUzytkownika = uzytkownik.imie + " " + uzytkownik.nazwisko;
        this.menu.czyAdministrator = uzytkownik.czyAdministrator;

        return this.menu.nazwaUzytkownika;
    }
}
