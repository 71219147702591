import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DaneZalogowanegoUzytkownika, PotwierdzKonto } from 'src/app/sekcje/konto/modele/logowanie/daneZalogowanegoUzytkownika';
import { RejestracjaPotwierdzKontoModel } from 'src/app/sekcje/konto/modele/rejestracja/rejestracjaPotwierdzKontoModel';
import { WyslijKodWeryfikacyjnyPonownieModel } from 'src/app/sekcje/konto/modele/rejestracja/wyslijKodWeryfikacyjnyPonownieModel';
import { ZarejestrujModel } from 'src/app/sekcje/konto/modele/rejestracja/zarejestrujModel';
import { SerwisBazowy } from 'src/app/serwisy/bazowy.service';
import { PojedynczyElementResponse } from '../../@wspolne/modele/pojedynczyElement';
import { ResponseBazowy } from '../../@wspolne/modele/responseBazowy';
import { AuthService } from '../../@wspolne/serwisy/auth.service';
import { ErrorService } from '../../@wspolne/serwisy/error.service';

@Injectable({
  providedIn: 'root'
})
export class RejestracjaService extends SerwisBazowy {


  constructor(
    httpClient: HttpClient, 
    authService: AuthService,
    errorService: ErrorService) {
    super(httpClient, authService, errorService);
  }

  public zarejestruj(model: ZarejestrujModel) {
    return this.wykonajAkcje<ZarejestrujModel, ResponseBazowy>(`${this.AdresApi}${RejestracjaUrl.Controller}/${RejestracjaUrl.Zarejestruj}`, model);
  }

  public potwierdzKonto(model: RejestracjaPotwierdzKontoModel) {
    return this.wykonajAkcje<RejestracjaPotwierdzKontoModel, PojedynczyElementResponse<PotwierdzKonto>>(this.AdresApi + "Rejestracja/PotwierdzKonto", model);
  }
  wyslijLinkPonownie(model: WyslijKodWeryfikacyjnyPonownieModel) {
    return this.wykonajAkcje<WyslijKodWeryfikacyjnyPonownieModel, ResponseBazowy>(this.AdresApi + "Rejestracja/WyslijKodWeryfikacyjnyPonownie", model);
  }
}

export enum RejestracjaUrl {
  Controller = 'Rejestracja',
  Zarejestruj = 'Zarejestruj'
}
