<div class="wrap pt-5" role="document" *ngIf="czyZaladowanoDane">
    <div class="content pt-5 mt-3">
        <div class="container-fluid mw690">
            <div class="text-center ft__01 mb-4">
                GdzieCoMam
            </div>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3 ">Gdzie jest testament:</div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole1"></textarea>


            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">Nazwy firm ubezpieczeniowych, w których mam
                polisy
                na życie i ich numery:</div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole2"></textarea>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">Nazwy banków, w których mam pieniądze:</div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole3"></textarea>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">Nazwy domu maklerskiego, w którym mam
                rachunki
                maklerskie:</div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole4"></textarea>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">Nazwy TFI i nr rejestrów:</div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole5"></textarea>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">Nazwy spółek i nr KRS, w których mam udziały:
            </div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole6"></textarea>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">Nazwy firm, w których mam depozyty metali
                szlachetnych:</div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole7"></textarea>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">Adresy moich nieruchomości i nr ksiąg
                wieczystych:
            </div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole8"></textarea>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">Gdzie są zarejestrowane konta do kryptowalut
                i
                klucze do nich:</div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole9"></textarea>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">Komu pożyczyłem pieniądze i ile:</div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole10"></textarea>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">Komu jestem winien pieniądze, ile i kiedy
                oddać:
            </div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole11"></textarea>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">Jakie ważne umowy i z kim mam podpisane oraz
                czy
                powinny być kontynuowane:</div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole12"></textarea>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">O jakich ważnych sprawach powinni inni się
                dowiedzieć (np. opłaty itp.):</div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole13"></textarea>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">Do kogo moi bliscy powinni się zwrócić w
                różnych
                sprawach:</div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole14"></textarea>

            <div _ngcontent-urh-c122="" class="ft__02 pb-2 pl-3  mt-3">Inne:</div>
            <textarea cols="30" rows="2" [(ngModel)]="model.pole15"></textarea>


        </div>
    </div>
    <div class="conf-p-clear pt-4 pt-sm-5 mt-3"></div>
    <div class="conf-p btn__bg mt-2">
        <div class="container-progress btn__bg--progress justify-content-center justify-content-md-end">
            <div class="btn__bg--progress ">
                <button class="btn__primary" (click)="zapisz()">
                    <!-- remove disbled -->
                    Zapisz
                </button>
            </div>
        </div>
    </div>
</div>