import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { PotwierdzKonto } from 'src/app/sekcje/konto/modele/logowanie/daneZalogowanegoUzytkownika';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { RejestracjaPotwierdzKontoModel } from '../../../modele/rejestracja/rejestracjaPotwierdzKontoModel';
import { RejestracjaService } from '../../../serwisy/rejestracja.service';
import { Ciasteczko } from 'src/app/sekcje/@wspolne/modele/ciasteczka';

@Component({
  selector: 'app-potwierdzenie-rejestracji',
  templateUrl: './potwierdzenie-rejestracji.component.html'
})
export class PotwierdzenieRejestracjiComponent extends BazowyComponent implements OnInit {

  public przeniesNaEkranZmianyHasla: boolean;
  public kodDoZmianyHasla: string;
  public kod: string;
  private potwierdzKontoModel: PotwierdzKonto;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private rejestracjaSerwis: RejestracjaService,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);

  }

  ngOnInit(): void {
    this.przeniesNaEkranZmianyHasla = false;
    this.kod = this.activatedRoute.snapshot.paramMap.get('kod');
    if (!this.kod) {
      throw new Error("Nieprawidłowy kod potwierdzający.");
    }
    setTimeout(() => {
      this.potwierdzKonto();
    });
  }

  private potwierdzKonto() {
    try {
      var model = new RejestracjaPotwierdzKontoModel();
      model.kod = this.kod;
      this.rejestracjaSerwis.potwierdzKonto(model).subscribe(
        (rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            if (rezultat.element.czyPrzeniescDoEkranuZmianyHasla === true) {
              this.przeniesNaEkranZmianyHasla = true;
              this.kodDoZmianyHasla = rezultat.element.kodDoZmianyHasla;
              return;
            }
            else {
              this.potwierdzKontoModel = rezultat.element;
              return;
            }
          }
          this.router.navigate(['zaloguj']);
        }
      );
    } catch (blad) {
    }
  }

  public przejdzDoAplikacji() {
    this.authService.logIn(Ciasteczko.DaneZalogowanegoUzytkownika, this.potwierdzKontoModel);
  }
}
