<app-naglowek-non-auth></app-naglowek-non-auth>
<div class="wrap" role="document">
    <div class="content">
        <div class="container">
            <div class="ft__01 text-center pb-30 pt-2">Zaloguj się</div>
            <form action="">
                <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('email')?'error':''">
                    <input placeholder="Email" name="email" [(ngModel)]="zalogujModel.email" type="text" />
                    <div class="error pb-3" [hidden]="!czyBladWalidacyjny('email')">
                        {{ pobierzBladWalidacyjny("email") }}
                    </div>
                </div>
                <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('haslo')?'error':''">
                    <input placeholder="Hasło" type="password" name="haslo" [(ngModel)]="zalogujModel.haslo" />
                    <div class="error pb-3"
                        [hidden]="!czyBladWalidacyjny('haslo')||czyBladWalidacyjnyZLinkiem('haslo')">
                        {{ pobierzBladWalidacyjny("haslo") }}
                    </div>
                </div>
                <p class="ft__04 my-2 pb-2 login_info" [hidden]="!czyBladWalidacyjnyZLinkiem('haslo')">
                    Konto jest nieaktywne. Kliknij link przesłany na Twój adres e-mail, aby aktywować konto lub
                    <a class="text-green" routerLink="" (click)="wyslijLinkPonownie()">wyślij link ponownie</a>
                </p>
                <div class="f-item">
                    <button class="btn__primary" [routerLink]="" (click)="zaloguj()">Zaloguj</button>
                </div>
                <div class="f-item pt-2 pb-1 mb-1 mt-2 text-center">
                    <a class="ft__03" routerLink="/zmien-haslo">Nie pamiętam hasła</a>
                </div>                
                <div class="border_info mb-4 text-center">
                    <label class="ft__04" for="ccd">
                        Korzystanie z aplikacji oznacza akceptację <a class="ft__03" href="http://gdziecojest.pl/regulamin" target="_blank">regulaminu</a>.
                    </label>
                    <div class="chb">
                        <!-- <input id="ccd" name="akceptacjaRegulaminu" type="checkbox" value="1" [(ngModel)]="zarejestrujModel.akceptacjaRegulaminu"/> -->
                        <!-- <label class="ft__04" for="ccd">
                            Akceptuję <a class="ft__03" routerLink="http://www.gdziecojest.pl/regulamin" target="_blank">warunki </a>aplikacji GdzieCoJest.pl
                        </label> -->
                    </div>
                    <!-- <div class="error pt-3 text-right" [hidden]="!czyBladWalidacyjny('akceptacjaRegulaminu')">
                        {{ pobierzBladWalidacyjny("akceptacjaRegulaminu") }}
                    </div> -->
                </div>
                <hr class="mb-3">
            </form>
            <div class="box mt-4">
                <p class="text-center ft__04">Nie masz jeszcze konta?</p>
                <button type="button" class="btn__register" routerLink="/rejestracja">Zarejestruj się</button>
                <p class="text-center ft__04 mt-3">lub</p>
                <button type="button" class="btn__fb" (click)="zalogujPrzezFb(fbModal)">Zaloguj przez Facebook</button>
                <button type="button" class="btn__g mt-3" (click)="zalogujPrzezGoogle()">Zaloguj się przez
                    Google</button>
            </div>
        </div>
    </div>
</div>

<ng-template #fbModal let-modal>
    <form (ngSubmit)="dokonczLogowaniePrzezFb()">
        <div class="modal-body">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button" (click)="modal.dismiss('')">
            </button>
            <p class="text-center ft__01">
                Logowanie przez <br>Facebook
            </p>
            <p class="ft__06 text-center pb-2">Podaj adres email, aby zakończyć aktywację konta
                GdzieCoJest.pl
            </p>

            <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('email')?'error':''">
                <input placeholder="E-mail" type="text" name="email" [(ngModel)]="dokonczLogowaniePrzezFbModel.email">
            </div>
            <div class="error pb-3" [hidden]="!czyBladWalidacyjny('email')  ">
                {{ pobierzBladWalidacyjny("email") }}
            </div>
            <button class="btn__primary mb-3">
                Zapisz
            </button>
        </div>
    </form>
</ng-template>