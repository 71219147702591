import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DaneZalogowanegoUzytkownika } from '../sekcje/konto/modele/logowanie/daneZalogowanegoUzytkownika';
import { Konfiguracja } from 'src/config';
import { Ciasteczko } from '../sekcje/@wspolne/modele/ciasteczka';
import { RequestBazowy } from '../sekcje/@wspolne/modele/requestBazowy';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../sekcje/@wspolne/serwisy/error.service';
import { AuthService } from '../sekcje/@wspolne/serwisy/auth.service';

export class SerwisBazowy {
    AdresApi: string;
    HttpHeaders: HttpHeaders;

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private errorService: ErrorService) {
        //this.AdresApi = environment.api;
        var config = new Konfiguracja();
        this.AdresApi = config.AdresApi;
        this.HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    /**
     * wykonajAkcje
     */
    public wykonajAkcje<T extends RequestBazowy, TR>(adres: string, model: T): Observable<TR> {
        model.adresStrony = location.origin;
        model.jezyk = "pl-PL";

        var daneUzytkownika = this.pobierzCiasteczko<DaneZalogowanegoUzytkownika>(Ciasteczko.DaneZalogowanegoUzytkownika);

        if (daneUzytkownika != null) {
            model.token = daneUzytkownika.token;
            model.idUzytkownika = daneUzytkownika.idUzytkownika;
        }

        return this.httpClient.post<TR>(adres, model,
            {
                headers: this.HttpHeaders
            }).pipe(
                catchError(this.errorService.handleError)
            );
    }
    //todo: rozważyć akcję dla geta
    // public wykonajAkcjeGet<T extends RequestBazowy,TR>(adres:string,model :T):Observable<TR> 
    // {
    //     model.adresStrony="http://localhost:4200";        
    //     return this.httpClient.get<TR>(adres);
    // }

    public pobierzCiasteczko<DaneZalogowanegoUzytkownika>(ciasteczko: Ciasteczko) {
        return this.authService.pobierzDaneZalogowanegoUzytkownika();
    }
}