import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { RequestBazowy } from 'src/app/sekcje/@wspolne/modele/requestBazowy';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { RodzajAbonamentu } from '../../modele/rodzaj-abonamentu.model';
import { RozpocznijSubskrypcjeRequest } from '../../modele/rozpocznij-subskrypcje-request.model';
import { AbonamentyService } from '../../serwisy/abonamenty.service';
import { PlatnosciService } from '../../serwisy/platnosci.service';

@Component({
  selector: 'app-karta',
  templateUrl: './karta.component.html',
  styleUrls: ['./karta.component.css']
})
export class KartaComponent extends BazowyComponent implements OnInit, AfterViewInit, OnDestroy {

  public optionsForms: any = {
    cardIcon: true,
    style: {
      basic: {
        fontSize: '24px'
      }
    },
    placeholder: {
      number: '',
      date: 'MM/YY',
      cvv: ''
    },
    lang: 'pl'
  }

  public tokenizeButton: any;
  public responseElement: any;
  public payuSdkForms: any;
  public secureForms: any;
  public cardNumber: any;
  public cardDate: any;
  public cardCvv: any;

  public rodzajeAbonamentow: Array<RodzajAbonamentu>;
  public wybranyAbonament: RodzajAbonamentu;
  public rozpocznijSubskrypcjeFormularz = new RozpocznijSubskrypcjeRequest();
  

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    authService: AuthService,
    private platnosciSerwis: PlatnosciService,
    private abonamentSerwis: AbonamentyService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }
  ngOnDestroy(): void {
    // this.payuSdkForms = null;
    // this.secureForms = null;
    this.cardNumber?.remove();
    this.cardDate?.remove();
    this.cardCvv?.remove();
  }
  ngAfterViewInit(): void {
    this.inicjalizujFormularz();
  }

  private inicjalizujFormularz() {
    this.platnosciSerwis.pobierzIdSklepu(new RequestBazowy()).subscribe((response) => {
      if (this.czyRezultatOk(response)) {
        //inicjalizacja SDK poprzez podanie POS ID oraz utworzenie obiektu secureForms
        //this.payuSdkForms = PayU('411960'); //z przykladu 393823; wprowadzone z konta sandbox, SM prod 4117434
        this.payuSdkForms = PayU(response.element); //z przykladu 393823; wprowadzone z konta sandbox, SM prod 4117434
        this.secureForms = this.payuSdkForms.secureForms();

        //utworzenie formularzy podając ich typ oraz opcje
        this.cardNumber = this.secureForms.add('number', this.optionsForms);
        this.cardDate = this.secureForms.add('date', this.optionsForms);
        this.cardCvv = this.secureForms.add('cvv', this.optionsForms);

        //renderowanie formularzy
        // if(!this.cardNumber){
        this.cardNumber.render('#payu-card-number');
        // }
        // if(!this.cardDate){
        this.cardDate.render('#payu-card-date');
        // }
        // if(!this.cardCvv){
        this.cardCvv.render('#payu-card-cvv');
        // }

        this.tokenizeButton = document.getElementById('tokenizeButton');
        this.responseElement = document.getElementById('responseTokenize');
      }
    });
  }

  ngOnInit(): void {
    this.pobierzRodzajeAbonamentow();
  }

  public tokenizuj(): void {
    this.responseElement.innerText = ''

    this.payuSdkForms.tokenize('MULTI').then((result) => {
      if(result.status === 'SUCCESS'){
        this.renderSuccess(this.responseElement, result.body.token) //tutaj wstaw przekazanie tokena do back-endu
        this.rozpocznijSubskrypcje(result.body.token);
      }else {
        this.renderError(this.responseElement, result.error.messages); //sprawdź typ błędu oraz komunikaty i wyświetl odpowiednią informację użytkownikowi
      }
    });
  }

  public renderError(element, errors): void {
    element.className = 'response-error';
    var messages = [];
    errors.forEach(function (error) {
      messages.push(error.message);
    });
    element.innerText = messages.join(', ');
  }

  public renderSuccess(element, msg): void {
    element.className = 'response-success';
    element.innerText = msg;
  };

  private pobierzRodzajeAbonamentow(): void{
    this.abonamentSerwis.PobierzRodzajeAbonamentow(new RequestBazowy()).subscribe((result) => {
      if(this.czyRezultatOk(result)){
        this.rodzajeAbonamentow = result.listaElementow;
      }else{
        this.wyswietlKomunikat(result.wiadomosc, true);
      }
    });
  }

  private rozpocznijSubskrypcje(tokenKarty: string): void{
    this.rozpocznijSubskrypcjeFormularz.tokenKarty = tokenKarty;
    this.rozpocznijSubskrypcjeFormularz.rodzajAbonamentuId = this.wybranyAbonament.id;
    this.platnosciSerwis.rozpocznijSubskrypcje(this.rozpocznijSubskrypcjeFormularz).subscribe((result) => {
      if(this.czyRezultatOk(result)){
        console.log(result);
        this.wyswietlKomunikat("Płatność przebiegła pomyślnie.")
      }else{
        console.log(result);
        this.wyswietlKomunikat(result.wiadomosc, true);
      }
    });
  }

}
