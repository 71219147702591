<div class="modal-content" *ngIf="czyZaladowanoDane">
    <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.dismiss()"></button>

        <p class="text-center ft__05 mb-3 pb-0 pt-2">
            Poleć nas
        </p>
        <div class="f-item" [ngClass]="czyBladWalidacyjny('tresc')?'error':''">
            <textarea class="ft__n" cols="30" id="" name="tresc" [(ngModel)]="model.tresc" rows="9"
                maxlength="500"></textarea>
            <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('tresc')"> {{ pobierzBladWalidacyjny("tresc")
                }}</div>
            <div class="text-right text-lg ft__04 mt-1">{{pobierzDlugosc()}}/500</div>
        </div>
        <p class="ft__n14 text-center mt-2 pb-2">Zaproś przyjaciół do korzystania z aplikacji</p>
        <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('email1')?'error':''">
            <input type="text" placeholder="Email" name="email1" [(ngModel)]="model.email1">
            <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('email1')"> {{ pobierzBladWalidacyjny("email1")
                }}</div>
        </div>
        <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('email2')?'error':''">
            <input type="text" placeholder="Email" name="email2" [(ngModel)]="model.email2">
            <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('email2')"> {{ pobierzBladWalidacyjny("email2")
                }}</div>
        </div>
        <div class="f-item pb-4" [ngClass]="czyBladWalidacyjny('email3')?'error':''">
            <input type="text" placeholder="Email" name="email3" [(ngModel)]="model.email3">
            <div class="error pt-2 text-right" *ngIf="czyBladWalidacyjny('email3')"> {{ pobierzBladWalidacyjny("email3")
                }}</div>
        </div>
        <button class="btn__primary mb-4" (click)="wyslij()">
            Wyślij
        </button>
    </div>
</div>