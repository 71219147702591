<div class="modal-content">
    <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.dismiss()"></button>

        <div class="modal_content">
            <p><strong>Szanowny Użytkowniku,</strong></p>
            <p>Podstawowym celem tworzenia powiadomienia jest przygotowanie informacji dla wybranych osób.</p>
            <p>Powiadomienie może zawierać informację z następujących obszarów:</p>
            <ul>
                <li>gdzie jest testament</li>
                <li>nazwy firm ubezpieczeniowych, w których są polisy na życie + nr tych polis</li>
                <li>nazwy banków, w których mamy jakiekolwiek środki</li>
                <li>nazwa domu maklerskiego, w którym są prowadzone rachunki maklerskie</li>
                <li>nazwa TFI + nr rejestru</li>
                <li>udziały w spółkach (firmach) – nr KRS tych spółek</li>
                <li>nazwy firm, w których są depozyty metali szlachetnych (nie zawsze fizyczne)</li>
                <li>adresy nieruchomości + nr ksiąg wieczystych</li>
                <li>gdzie są zarejestrowane konta do kryptowalut + klucze do nich</li>
                <li>komu zostały pożyczone pieniądze (od kogo odzyskać)</li>
                <li>komu powinniśmy oddać pieniądze</li>
                <li>jakie umowy mamy podpisane, które powinny być kontynuowane a które nie</li>
                <li>instrukcje dotyczących spraw, którymi nadal należy się zajmować (np. opłaty – gdzie, co, kiedy)</li>
                <li>do kogo zwrócić się o pomoc w sprawach w różnych</li>
                <li>itp.</li>
            </ul>
            <p>GdzieCoJest.pl zapewnia najwyższe możliwe standardy bezpieczeństwa.</p>
            <p>Nie mamy jednak wpływu na jaki adres mailowy powiadomienie jest wysyłane, kto ma dostęp do poczty
                elektronicznej,
                na jakim serwerze jest umiejscowiona skrzynka pocztowa. Dlatego w powiadomieniach należy UNIKAĆ
                podawania danych
                wrażliwych typu hasła, PINy, informacje ułatwiające dostęp, które mogłyby ułatwić komukolwiek
                nieuprawnione
                przejęcie informacji, praw lub rzeczy.</p>
            <p><strong>Powiadomienie nie jest w żadnym wypadku testamentem ani go nie zastępuje.</strong></p>
        </div>
        <button class="btn__primary btn__primary--cancel" (click)="activeModal.dismiss()">
            Przejdź do powiadomień
        </button>
    </div>
</div>