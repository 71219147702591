import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { ModalOpcje } from 'src/app/sekcje/@wspolne/modele/modal-opcje.ogolne';
import { RequestBazowy } from 'src/app/sekcje/@wspolne/modele/requestBazowy';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EdytujPowiadomienieModel } from '../../modele/edytujPowiadomienieModel';
import { PowiadomienieUzytkownika } from '../../modele/powiadomienieUzytkownika';
import { PowiadomieniaService } from '../../serwisy/powiadomienia.service';
import { PowiadomieniaDodajModalComponent } from '../powiadomienia-dodaj-modal/powiadomienia-dodaj-modal.component';
import { PowiadomieniaEdytujModalComponent } from '../powiadomienia-edytuj-modal/powiadomienia-edytuj-modal.component';
import { PowiadomieniaPodpowiedzModalComponent } from '../powiadomienia-podpowiedz-modal/powiadomienia-podpowiedz-modal.component';
import { PowiadomieniaUsunModalComponent } from '../powiadomienia-usun-modal/powiadomienia-usun-modal.component';
import { PowiadomieniaWyczerpanoLimitModalComponent } from '../powiadomienia-wyczerpano-limit-modal/powiadomienia-wyczerpano-limit-modal.component';


@Component({
  selector: 'app-powiadomienia',
  templateUrl: './powiadomienia.component.html',
  styleUrls: ['./powiadomienia.component.css']
})
export class PowiadomieniaComponent extends BazowyComponent implements OnInit {

  public listaPowiadomien: Array<PowiadomienieUzytkownika> = new Array();
  public czyMoznaDodacKolejnePowiadomienie: boolean;
  public czyZaladowanoDane: boolean = false;

  constructor(
    private powiadomieniaSerwis: PowiadomieniaService,
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDaneDoEkranu();

    });
  }

  private pobierzDaneDoEkranu() {
    this.powiadomieniaSerwis.pobierzDaneDoEkranuPowiadomienUzytkownika(new RequestBazowy()).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.listaPowiadomien = rezultat.element.powiadomienia;
        this.czyMoznaDodacKolejnePowiadomienie = rezultat.element.czyMoznaDodacKolejnePowiadomienie;
        this.czyZaladowanoDane = true;
        return;
      } else {
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    }
    );
  }

  public dodajPowiadomienie() {
    if (!this.czyMoznaDodacKolejnePowiadomienie) {
      //this.wyswietlInformacje("Wykorzystałeś limit powiadomień. Zmień plan abonamentowy aby dodać kolejne powiadomienie.")
      this.modalService.open(PowiadomieniaWyczerpanoLimitModalComponent, new ModalOpcje(true, 'static'));
      return;
    }
    this.modalService.open(PowiadomieniaDodajModalComponent, new ModalOpcje(true, 'static'))
      .result.then((czyZapisano) => {
        this.pobierzDaneDoEkranu();
      }, onRejected => { });
  }
  public edytujPowiadomienie(id: string) {
    var powiadomienie = this.listaPowiadomien.find(m => m.id == id);
    if (powiadomienie == undefined) {
      return;
    }
    var edytujModel = new EdytujPowiadomienieModel();

    edytujModel.nazwa = powiadomienie.nazwa;
    edytujModel.tresc = powiadomienie.tresc;
    edytujModel.telefon = powiadomienie.telefon;
    edytujModel.id = powiadomienie.id;
    edytujModel.email = powiadomienie.email;


    this.modalReference = this.modalService.open(PowiadomieniaEdytujModalComponent, new ModalOpcje(true, 'static'));
    this.modalReference.componentInstance.model = edytujModel;

    this.modalReference.result.then(czyZapisano => {
      if (czyZapisano) {
        this.pobierzDaneDoEkranu();
      }
    }, onRejected => { });
  }

  public usunPowiadomienie(id: string) {

    this.modalReference = this.modalService.open(PowiadomieniaUsunModalComponent, new ModalOpcje(true, 'static'));
    this.modalReference.componentInstance.id = id;

    this.modalReference.result.then(czyUsunieto => {
      if (czyUsunieto) {
        this.pobierzDaneDoEkranu();
      }
    }, onRejected => { });
  }

  public pokazPodpowiedzi() {
    this.modalService.open(PowiadomieniaPodpowiedzModalComponent, new ModalOpcje(true, 'static'));

  }

  pobierzTrescPowiadomienia(tresc: string) {

    return tresc.replace(/(?:\r\n|\r|\n)/g, '<br>');

    // if (tresc.length > 250) {
    //   return (tresc.substring(0, 250) + "<b>...</b>").replace(/(?:\r\n|\r|\n)/g, '<br>');
    // }
    // return tresc.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

}
