import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ZmienAdresEmailFormularz } from '../../../modele/profil/zmien-adres-email-formularz.model';
import { ProfilService } from '../../../serwisy/profil.service';

@Component({
  selector: 'app-zmiana-email-modal',
  templateUrl: './zmiana-email-modal.component.html'
})
export class ZmianaEmailModalComponent extends BazowyComponent implements OnInit {

  public zmienAdresEmailFormularz = new ZmienAdresEmailFormularz();

  constructor(
    public profilSerwis: ProfilService,
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
  }

  public onZapiszNowyEmailClick(): void {
    this._zmienEmail();
  }

  private _zmienEmail(): void {
    this.profilSerwis.zmienAdresEmailIWyslijPotwierdzenie(this.zmienAdresEmailFormularz).subscribe(
      (rezultat) => {
        this.schowajLadowacz();
        if (this.czyRezultatOk(rezultat)) {
          // this.wyswietlKomunikat('Link do zmiany adresu email został wysłany.');
          this.activeModal.close();
          return;
        }
        else {
          if (this.bladModelu(rezultat)) {
            this.bledyWalidacji = rezultat.bledyModelu;
          }
          if (rezultat.wiadomosc) {
            this.wyswietlKomunikat(rezultat.wiadomosc, true);
          }
        }
      }
    );
  }

}
