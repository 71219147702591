import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoadingService } from './loading.service';

/**
 * source: https://medium.com/swlh/angular-loading-spinner-using-http-interceptor-63c1bb76517b
 * This class is for intercepting http requests. When a request starts, we set the loadingSub property
 * in the LoadingService to true. Once the request completes and we have a response, set the loadingSub
 * property to false. If an error occurs while servicing the request, set the loadingSub property to false.
 * @class {HttpRequestInterceptor}
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(private loadingService: LoadingService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.setLoading(true, req.url);
    return next.handle(req)
      .pipe(catchError((err) => {
        this.loadingService.setLoading(false, req.url);
        return err;
      }))
      //HttpEvent<any> wywołuje błąd typowania
      .pipe(map<any, any>((evt: HttpEvent<any>) => {
        if(evt instanceof HttpResponse){
          this.loadingService.setLoading(false, req.url);
        }
        return evt;
      }));
  }
}
