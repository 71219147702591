<div class="preloader active d-flex align-items-center justify-content-center" *ngIf="czyPokazLadowacz">
  <div class="">
    <img alt="" src="/assets/img/gcoj_loader.gif">
  </div>
</div>
<ng-container *ngIf="isAuthorized(); else notAuthorized">
  <app-nawigacja *ngIf="!menuService.CzySchowacMenu"></app-nawigacja>
  <!-- <div class="wrap" role="document"> -->
  <!-- <div class="content"> -->
  <!-- <div class="container"> -->
  <router-outlet></router-outlet>
  <!-- </div> -->
  <!-- </div> -->
  <!-- </div> -->
</ng-container>

<ng-template #notAuthorized>
  <router-outlet></router-outlet>
</ng-template>