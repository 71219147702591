<div class="modal-body">
    <button aria-label="Close" class="close" data-dismiss="modal" type="button" (click)="zamknijModal()">
    </button>
    <p class="ft__clock_input text-center ft__01--01 pt-3 mb-0" *ngIf="!czyWpisywanieGodziny">
        {{dajGodzine()}}<span class="text-lg"><span class="">:</span>{{dajMinuty()}}</span>
    </p>
    <div id="clocklet-inline-containers d-flex justify-content-center" *ngIf="czyWyborGodziny&&!czyWpisywanieGodziny">
        <div class="clocklet-container clocklet-container--inline">
            <div class="clocklet clocklet--inline" data-clocklet-format="HH:mm" data-clocklet-value="22:45">
                <div class="clocklet-plate">
                    <div class="clocklet-dial clocklet-dial--minute">
                        <div class="clocklet-hand clocklet-hand--minute"
                            [ngStyle]="{'transform': 'rotate('+kalkulujObrotGodzin()+'deg)'}"
                            *ngIf="czyGodzinaPrzed12()">
                        </div>
                        <!-- class="clocklet-tick clocklet-tick--minute clocklet-tick--selected" -->
                        <button class="clocklet-tick clocklet-tick--minute"
                            [ngClass]="czyWybranaGodzina(12)?'clocklet-tick--selected':''" data-clocklet-tick-value="12"
                            style="left:50%;top:11%" type="button" (click)="wybierzGodzine(12)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="1"
                            style="left:69.5%;top:16.2%" type="button"
                            [ngClass]="czyWybranaGodzina(1)?'clocklet-tick--selected':''" (click)="wybierzGodzine(1)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="2"
                            style="left:83.8%;top:30.5%" type="button"
                            [ngClass]="czyWybranaGodzina(2)?'clocklet-tick--selected':''" (click)="wybierzGodzine(2)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="3"
                            style="left:89%;top:50%" type="button"
                            [ngClass]="czyWybranaGodzina(3)?'clocklet-tick--selected':''" (click)="wybierzGodzine(3)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="4"
                            style="left:83.8%;top:69.5%" type="button"
                            [ngClass]="czyWybranaGodzina(4)?'clocklet-tick--selected':''" (click)="wybierzGodzine(4)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="5"
                            style="left:69.5%;top:83.8%" type="button"
                            [ngClass]="czyWybranaGodzina(5)?'clocklet-tick--selected':''" (click)="wybierzGodzine(5)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="6"
                            style="left:50%;top:89%" type="button"
                            [ngClass]="czyWybranaGodzina(6)?'clocklet-tick--selected':''" (click)="wybierzGodzine(6)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="7"
                            style="left:30.5%;top:83.8%" type="button"
                            [ngClass]="czyWybranaGodzina(7)?'clocklet-tick--selected':''" (click)="wybierzGodzine(7)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="8"
                            style="left:16.2%;top:69.5%" type="button"
                            [ngClass]="czyWybranaGodzina(8)?'clocklet-tick--selected':''" (click)="wybierzGodzine(8)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute " data-clocklet-tick-value="9"
                            style="left:11%;top:50%" type="button"
                            [ngClass]="czyWybranaGodzina(9)?'clocklet-tick--selected':''" (click)="wybierzGodzine(9)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="10"
                            style="left:16.2%;top:30.5%" type="button"
                            [ngClass]="czyWybranaGodzina(10)?'clocklet-tick--selected':''" (click)="wybierzGodzine(10)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="11"
                            style="left:30.5%;top:16.2%" type="button"
                            [ngClass]="czyWybranaGodzina(11)?'clocklet-tick--selected':''" (click)="wybierzGodzine(11)">
                        </button>
                    </div>
                    <div class="clocklet-dial clocklet-dial--hour">
                        <div class="clocklet-hand clocklet-hand--hour"
                            [ngStyle]="{'transform': 'rotate('+kalkulujObrotGodzin()+'deg)'}"
                            *ngIf="!czyGodzinaPrzed12()">
                        </div>
                        <button class="clocklet-tick clocklet-tick--hour" data-clocklet-tick-value="00"
                            style="left:50%;top:11%" type="button"
                            [ngClass]="czyWybranaGodzina(0)?'clocklet-tick--selected':''" (click)="wybierzGodzine(0)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--hour " data-clocklet-tick-value="13"
                            style="left:69.5%;top:16.2%" type="button"
                            [ngClass]="czyWybranaGodzina(13)?'clocklet-tick--selected':''" (click)="wybierzGodzine(13)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--hour" data-clocklet-tick-value="14"
                            style="left:83.8%;top:30.5%" type="button"
                            [ngClass]="czyWybranaGodzina(14)?'clocklet-tick--selected':''" (click)="wybierzGodzine(14)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--hour" data-clocklet-tick-value="15"
                            style="left:89%;top:50%" type="button"
                            [ngClass]="czyWybranaGodzina(15)?'clocklet-tick--selected':''" (click)="wybierzGodzine(15)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--hour" data-clocklet-tick-value="16"
                            style="left:83.8%;top:69.5%" type="button"
                            [ngClass]="czyWybranaGodzina(16)?'clocklet-tick--selected':''" (click)="wybierzGodzine(16)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--hour" data-clocklet-tick-value="17"
                            style="left:69.5%;top:83.8%" type="button"
                            [ngClass]="czyWybranaGodzina(17)?'clocklet-tick--selected':''" (click)="wybierzGodzine(17)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--hour" data-clocklet-tick-value="18"
                            style="left:50%;top:89%" type="button"
                            [ngClass]="czyWybranaGodzina(18)?'clocklet-tick--selected':''" (click)="wybierzGodzine(18)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--hour" data-clocklet-tick-value="19"
                            style="left:30.5%;top:83.8%" type="button"
                            [ngClass]="czyWybranaGodzina(19)?'clocklet-tick--selected':''" (click)="wybierzGodzine(19)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--hour" data-clocklet-tick-value="20"
                            style="left:16.2%;top:69.5%" type="button"
                            [ngClass]="czyWybranaGodzina(20)?'clocklet-tick--selected':''" (click)="wybierzGodzine(20)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--hour" data-clocklet-tick-value="21"
                            style="left:11%;top:50%" type="button"
                            [ngClass]="czyWybranaGodzina(21)?'clocklet-tick--selected':''" (click)="wybierzGodzine(21)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--hour" data-clocklet-tick-value="22"
                            style="left:16.2%;top:30.5%" type="button"
                            [ngClass]="czyWybranaGodzina(22)?'clocklet-tick--selected':''" (click)="wybierzGodzine(22)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--hour" data-clocklet-tick-value="23"
                            style="left:30.5%;top:16.2%" type="button"
                            [ngClass]="czyWybranaGodzina(23)?'clocklet-tick--selected':''" (click)="wybierzGodzine(23)">
                        </button>
                    </div>
                    <div class="clocklet-hand-origin">
                    </div>
                </div>
            </div>
        </div>
        <input data-clocklet="format:HH:mm" data-clocklet-inline="" style="display: none;" />
    </div>

    <div id="clocklet-inline-containers d-flex justify-content-center" *ngIf="!czyWyborGodziny&&!czyWpisywanieGodziny">
        <div class="clocklet-container clocklet-container--inline">
            <div class="clocklet clocklet--inline" data-clocklet-format="HH:mm" data-clocklet-value="22:45">
                <div class="clocklet-plate">
                    <div class="clocklet-dial clocklet-dial--minute">
                        <div class="clocklet-hand clocklet-hand--minute" style="transform: rotate(0deg);"
                            [ngStyle]="{'transform': 'rotate('+kalkulujObrotMinut()+'deg)'}">
                        </div>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="00"
                            style="left:50%;top:11%" type="button"
                            [ngClass]="czyWybranaMinuta(0)?'clocklet-tick--selected':''" (click)="wybierzMinute(0)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="05"
                            style="left:69.5%;top:16.2%" type="button"
                            [ngClass]="czyWybranaMinuta(5)?'clocklet-tick--selected':''" (click)="wybierzMinute(5)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="10"
                            style="left:83.8%;top:30.5%" type="button"
                            [ngClass]="czyWybranaMinuta(10)?'clocklet-tick--selected':''" (click)="wybierzMinute(10)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="15"
                            style="left:89%;top:50%" type="button"
                            [ngClass]="czyWybranaMinuta(15)?'clocklet-tick--selected':''" (click)="wybierzMinute(15)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="20"
                            style="left:83.8%;top:69.5%" type="button"
                            [ngClass]="czyWybranaMinuta(20)?'clocklet-tick--selected':''" (click)="wybierzMinute(20)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="25"
                            style="left:69.5%;top:83.8%" type="button"
                            [ngClass]="czyWybranaMinuta(25)?'clocklet-tick--selected':''" (click)="wybierzMinute(25)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="30"
                            style="left:50%;top:89%" type="button"
                            [ngClass]="czyWybranaMinuta(30)?'clocklet-tick--selected':''" (click)="wybierzMinute(30)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="35"
                            style="left:30.5%;top:83.8%" type="button"
                            [ngClass]="czyWybranaMinuta(35)?'clocklet-tick--selected':''" (click)="wybierzMinute(35)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="40"
                            style="left:16.2%;top:69.5%" type="button"
                            [ngClass]="czyWybranaMinuta(40)?'clocklet-tick--selected':''" (click)="wybierzMinute(40)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute " data-clocklet-tick-value="45"
                            style="left:11%;top:50%" type="button"
                            [ngClass]="czyWybranaMinuta(45)?'clocklet-tick--selected':''" (click)="wybierzMinute(45)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="50"
                            style="left:16.2%;top:30.5%" type="button"
                            [ngClass]="czyWybranaMinuta(50)?'clocklet-tick--selected':''" (click)="wybierzMinute(50)">
                        </button>
                        <button class="clocklet-tick clocklet-tick--minute" data-clocklet-tick-value="55"
                            style="left:30.5%;top:16.2%" type="button"
                            [ngClass]="czyWybranaMinuta(55)?'clocklet-tick--selected':''" (click)="wybierzMinute(55)">
                        </button>
                    </div>
                    <div class="clocklet-hand-origin"></div>
                </div>
            </div>
        </div>
        <input data-clocklet="format:HH:mm" data-clocklet-inline="" style="display: none;" />
    </div>

    <!-- <div *ngIf="czyWpisywanieGodziny">
        <div class="ft__01 text-center pt-3 pb-4">Podaj czas</div>

        <div class="d-flex justify-content-between align-items-center my-4">
            <div class="col w-50">
                <input class="clock_input pb-0" type="text" [(ngModel)]="godzinaRecznie" appGodzina>
                <label class="text-center d-block pt-2" for="">godz.</label>
            </div>
            <span class="ft__clock_input pb-4 mb-2">:</span>
            <div class="col w-50">
                <input class="clock_input pb-0" type="text" [(ngModel)]="minutyRecznie" appMinuty>
                <label class="text-center d-block pt-2" for="">min.</label>
            </div>
        </div>
    </div> -->


    <div class="btn-t-l mt-4 row">
        <!-- <div class="col-4" *ngIf="!czyWpisywanieGodziny">
            <button class="btn__keybord" (click)="przejdzDoWpisywaniaRecznego()">
            </button>
        </div>
        <div class="col-4" *ngIf="czyWpisywanieGodziny">
            <button class="btn__clock" (click)="przejdzDoZegara()">
            </button>
        </div> -->

        <div class="col-4">

        </div>

        <div class="col-8 d-flex">
            <button class="btn__cancel_empty b_g mb-3" (click)="zamknijModal()">
                Anuluj
            </button>
            <button class="btn__cancel_empty b_g mb-3 pr-0 w-50" (click)="potwierdzWybor()">
                OK
            </button>
        </div>
    </div>
</div>