import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { DaneZalogowanegoUzytkownika, PotwierdzKonto } from 'src/app/sekcje/konto/modele/logowanie/daneZalogowanegoUzytkownika';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { Ciasteczko } from 'src/app/sekcje/@wspolne/modele/ciasteczka';
import { KomunikatyService } from 'src/app/serwisy/ogolne/komunikaty.service';
import { PotwierdzAktywnoscModel } from '../../modele/potwierdzAktywnoscModel';
import { KonfiguracjaComponent } from 'src/app/sekcje/konfiguracja/komponenty/konfiguracja/konfiguracja.component';
import { KonfiguracjaService } from 'src/app/sekcje/konfiguracja/serwisy/konfiguracja.service';
import { MenuService } from 'src/app/sekcje/@wspolne/serwisy/menu.service';
import { PolecModalComponent } from 'src/app/sekcje/polec/komponenty/polec-modal/polec-modal.component';
import { ModalOpcje } from 'src/app/sekcje/@wspolne/modele/modal-opcje.ogolne';

@Component({
  selector: 'app-potwierdz-aktywnosc',
  templateUrl: './potwierdz-aktywnosc.component.html',
  styleUrls: ['./potwierdz-aktywnosc.component.css']
})
export class PotwierdzAktywnoscComponent extends BazowyComponent implements OnInit {

  public kod: string;
  private daneZalogowanegoUzytkownika: DaneZalogowanegoUzytkownika;
  public czyZaladowanoDane: boolean;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private konfiguracjaSerwis: KonfiguracjaService,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService,
    public menuSerwis: MenuService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);

  }

  ngOnInit(): void {
    this.menuSerwis.schowajMenu();
    this.kod = this.activatedRoute.snapshot.paramMap.get('kod');
    if (!this.kod) {
      throw new Error("Nieprawidłowy kod potwierdzający.");
    }
    setTimeout(() => {
      this.potwierdzKonto();
    });
  }

  private potwierdzKonto() {
    try {
      var model = new PotwierdzAktywnoscModel();
      model.tokenPotwierdzenia = this.kod;
      this.konfiguracjaSerwis.potwierdzAktywnosc(model).subscribe(
        (rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.czyZaladowanoDane = true;
            this.wyswietlInformacje(rezultat.wiadomosc);
            return;
          }
          else {
            this.wyswietlKomunikat(rezultat.wiadomosc, true);
            this.router.navigate(['/']);
          }
        }
      );
    } catch (blad) {
    }
  }

  public przejdzDoAplikacji() {
    this.router.navigate(['/'])
  }

  pokazModalPolecenia() {
    this.modalReference = this.modalService.open(PolecModalComponent, new ModalOpcje(true, 'static'));
    if (!this.czyZalogowany()) {
      this.modalReference.componentInstance.tokenPotwierdzeniaAktywnosci = this.kod;
    }
  }
}
