import { Ekran } from './ekran';
import { Rola } from './rola';

export class Nawigacja {

    constructor(ekran?: Ekran, czyPrzyciskWstecz?: boolean, czyPrzyciskZapisz?: boolean) {
        this.ekran = ekran;
        this.czyPrzyciskWstecz = czyPrzyciskWstecz == undefined ? false : czyPrzyciskWstecz;
        this.czyPrzyciskZapisz = czyPrzyciskZapisz == undefined ? false : czyPrzyciskZapisz;
    }
    ekran?: Ekran;
    rolaUzytkownika: Rola;
    nazwaUzytkownika: string;
    czyAdministrator: boolean;
    czyPrzyciskWstecz: boolean = false;
    czyPrzyciskZapisz: boolean = false;
}