import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { RequestBazowy } from 'src/app/sekcje/@wspolne/modele/requestBazowy';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { AbonamentyService } from '../../serwisy/abonamenty.service';

@Component({
  selector: 'app-abonament-usun-subskrypcje-modal',
  templateUrl: './abonament-usun-subskrypcje-modal.component.html',
  styleUrls: ['./abonament-usun-subskrypcje-modal.component.css']
})
export class AbonamentUsunSubskrypcjeModalComponent extends BazowyComponent implements OnInit {


  constructor(
    public abonamentSerwis: AbonamentyService,
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    public komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
    setTimeout(() => {

    });
  }

  zrezygnuj() {
    this.abonamentSerwis.zrezygnujZSubskrypcji(new RequestBazowy()).subscribe((result) => {
      if (this.czyRezultatOk(result)) {
        this.wyswietlKomunikat("Zrezygnowano z subskrypcji i usunięto kartę.");

        this.activeModal.close(true);
      } else {
        this.wyswietlKomunikat(result.wiadomosc, true);
      }
    });
  }
}
