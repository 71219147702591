import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

export class ModalOpcje implements NgbModalOptions{
    constructor(centered?: boolean, backdrop?: boolean | 'static', keyboard?: boolean){
      this.centered = centered;
      this.backdrop = backdrop;
      this.keyboard = keyboard;
    }
    centered?: boolean; 
    backdrop?: boolean | 'static';
    keyboard?: boolean;
  }