import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { WeryfikujZmianeAdresuEmailFormularz } from '../../../modele/profil/weryfikuj-zmiane-adresu-email-formularz.model';
import { ProfilService } from '../../../serwisy/profil.service';
import { RejestracjaService } from '../../../serwisy/rejestracja.service';

@Component({
  selector: 'app-potwierdz-email',
  templateUrl: './potwierdz-email.component.html'
})
export class PotwierdzEmailComponent extends BazowyComponent implements OnInit {

  constructor(
    private profilService: ProfilService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private rejestracjaSerwis: RejestracjaService,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);

  }

  ngOnInit(): void {
    setTimeout(() => {
      this._potwierdzZmianeEmaila();
    });
  }

  private _potwierdzZmianeEmaila(): void {
    var zmianaAdresuEmailFormularz = new WeryfikujZmianeAdresuEmailFormularz();
    zmianaAdresuEmailFormularz.tokenPotwierdzajacy = this.activatedRoute.snapshot.paramMap.get('token');
    this.profilService.WeryfikujZmianeAdresuEmail(zmianaAdresuEmailFormularz)
      .subscribe((result) => {
        if (this.czyRezultatOk(result)) {
          // this.daneZalogowanegoUzytkownika = result.element;
        } else {
          this.wyswietlKomunikat(result.wiadomosc, true);
        }
      });
  }

  public onPrzejdzDoAplikacjiClick(): void {
    this.router.navigate(['zaloguj']);
  }

}
