<div class="wrap pt-5" role="document" *ngIf="czyZaladowanoDane">
    <div class="content pt-4 mt-2">
        <div class="container-fluid " [ngClass]="daneDoEkranu.karta&&!daneDoEkranu.brakAbonamentu?'mw545':'mw1366'">
            <div class="text-center ft__01 mb-1">Abonamenty</div>


            <div>
                <!-- aktywny abonament  -->
                <div *ngIf="daneDoEkranu.karta&&!daneDoEkranu.brakAbonamentu">
                    <div class="text-center ft__16bold mb-4">Aktualny plan</div>
                    <div class="mb-2 ft__n14">Aktualny plan abonamentowy: <a class="fwb text-blue"
                            routerLink="./">{{daneDoEkranu.opisAktualnegoPakietu}}, {{daneDoEkranu.pakietStandardCena}}
                            zł</a> </div>

                    <div class="mb-2 ft__n14">Do odnowienia abonamentu pozostało: <a class="fwb text-blue"
                            routerLink="./">{{daneDoEkranu.ileDniZostalo}} dni
                            ({{daneDoEkranu.sformatowanaDataWaznosciAbonamentu}})</a></div>
                    <div class="mb-2 ft__n14">Częstotliwość odnawiania: <a class="fwb text-blue"
                            routerLink="./">{{daneDoEkranu.czestotliwosc}}</a></div>



                    <div class="py-4 d-flex justify-content-center mx-3 mx-md-0">
                        <div class="card-container__main d-flex flex-column justify-content-between">
                            <div class="card-container--number">xxxx xxxx xxxx {{pobierzOstatnieCyfryKarty()}}</div>
                            <div class="card-container--date">
                                {{daneDoEkranu.karta.miesiacWygasniecia}}/{{daneDoEkranu.karta.rokWygasniecia}}
                            </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="card-container--name"> </div>
                                <div class="logo">
                                    <img src="{{daneDoEkranu.karta.urlMiniaturyDostawcyKarty}}" alt=""
                                        class="brandKarty">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-center py-3 pb-1">
                        <!-- <div class="text-center mb-2 pb-2" *ngIf="pokazPrzyciskZmienPlan()">
                            <button class="btn__green" (click)="zmienPlan()">Zmień plan abonamentowy</button>
                        </div> -->
                        <a class="ft__card_link text-green" routerLink="./" (click)="zrezygnujZSubskrypcji()">Zrezygnuj
                            z
                            subskrypcji</a>

                        <div class="text-center mb-2 pb-2 mt-4">
                            <a class="ft__card_link text-green " routerLink="./" (click)="aktywujKodRabatowy()">Aktywuj
                                kod rabatowy</a>
                        </div>
                    </div>
                </div>

                <!-- brak abonamentu lub wylaczona subskrypcja  -->
                <div class="row" *ngIf="!daneDoEkranu.karta||daneDoEkranu.brakAbonamentu">
                    <div class="col-lg-5 md-border">
                        <div class="text-center ft__16bold mb-4">Aktualny plan</div>
                        <div *ngIf="!daneDoEkranu.brakAbonamentu" class="mb-2 ft__n14">Aktualny plan abonamentowy: <a
                                class="fwb text-blue" routerLink="./">{{daneDoEkranu.opisAktualnegoPakietu}}</a> </div>
                        <div *ngIf="daneDoEkranu.brakAbonamentu" class="mb-2 ft__n14">Aktualny plan abonamentowy: <a
                                class="fwb text-blue" routerLink="./">Brak abonamentu</a> </div>
                        <div *ngIf="!daneDoEkranu.brakAbonamentu" class="mb-2 ft__n14">Do końca abonamentu pozostało:
                            <a class="fwb text-blue" routerLink="./">{{daneDoEkranu.ileDniZostalo}} dni
                                ({{daneDoEkranu.sformatowanaDataWaznosciAbonamentu}})</a>
                        </div>

                        <div *ngIf="!daneDoEkranu.karta"
                            class="mh130 d-flex text-center justify-content-center align-items-center ft__d18">
                            Brak zdefiniowanych <br>płatności
                        </div>

                        <div *ngIf="daneDoEkranu.karta" class="py-3 d-flex justify-content-center mx-3 mx-md-0">
                            <div class="card-container__main d-flex flex-column justify-content-between">
                                <div class="card-container--number">xxxx xxxx xxxx {{pobierzOstatnieCyfryKarty()}}</div>
                                <div class="card-container--date">
                                    {{daneDoEkranu.karta.miesiacWygasniecia}}/{{daneDoEkranu.karta.rokWygasniecia}}
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="card-container--name"> </div>
                                    <div class="logo">
                                        <img src="{{daneDoEkranu.karta.urlMiniaturyDostawcyKarty}}" alt=""
                                            class="brandKarty">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="text-center py-1 pb-1" *ngIf="pokazPrzyciskZmienPlan()">
                            <div class="text-center mb-2 pb-2">
                                <button class="btn__green" (click)="zmienPlan()">Zmień plan abonamentowy</button>
                            </div>
                        </div> -->

                        <div class="text-center">
                            <a class="ft__card_link text-green " routerLink="./" (click)="aktywujKodRabatowy()">Aktywuj
                                kod rabatowy</a>
                        </div>
                        <div class=" py-3 pb-1" *ngIf="daneDoEkranu.czyNieUdaloSiePobracPlatnosci">
                            <div class="mb-2 ft__n14 text-red">Próba odnowienia subskrypcji aplikacji
                                GdzieCoJest.pl zakończyła się niepowodzeniem.</div>
                            <div class="text-center mb-2 pb-2">
                                <button class="btn__green" (click)="ponowPlatnosc()">Ponów płatność</button>
                            </div>
                            <div class="text-center">
                                <a class="ft__card_link text-green " routerLink="./"
                                    (click)="zrezygnujZSubskrypcji()">Zrezygnuj
                                    z
                                    subskrypcji</a>
                            </div>

                        </div>

                        <hr class="mb-4 d-lg-none">
                    </div>
                    <div class="col-lg-7">
                        <div class="text-center ft__16bold mb-4">Wykup Plan</div>
                        <div *ngIf="daneDoEkranu.rodzajeAbonamentu" class="row">

                            <div class="col-md-6 mb-4" *ngIf="czyPakietDostepny('Standard')">
                                <div class="box box__corner22 ">
                                    <div class="ft__01--02 text-left">Standard</div>
                                    <div class="ft__n pb-2">Do 100 powiadomień</div>
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <div class="input ft__n chb chb--radio">
                                                <input id="5p_01" type="radio" name="5p"
                                                    [checked]="!wybranyAbonament || czyWybranoAbonament('Miesiąc', 'Standard')"
                                                    (change)="wybierzAbonament('Miesiąc', 'Standard')">
                                                <label for="5p_01">miesięczny</label>
                                            </div>
                                            <div class="input ft__n chb chb--radio">
                                                <input id="5p_02" type="radio" name="5p"
                                                    (change)="wybierzAbonament('Kwartał', 'Standard')">
                                                <label for="5p_02">kwartalny</label>
                                            </div>
                                            <div class="input ft__n chb chb--radio">
                                                <input id="5p_03" type="radio" name="5p"
                                                    (change)="wybierzAbonament('Rok', 'Standard')">
                                                <label for="5p_03">roczny</label>
                                            </div>
                                            <div class="input ft__n chb chb--radio">
                                                <input id="5p_04" type="radio" name="5p"
                                                    (change)="wybierzAbonament('Trzy lata', 'Standard')">
                                                <label for="5p_04">3 letni</label>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-end">
                                            <div class="text-right pb-1">
                                                <div class="ft__s18 text-blue">
                                                    {{pobierzTekstZCena(0)}}</div>
                                                <a routerLink="./" class="ft__16blod pt-1 text-green"
                                                    (click)="wyswietlFormularzPlatnosci()">Wybierz</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>