<div *ngIf="czyZaladowanoDane">
    <header class="header">
        <div class="container text-center">
            <img alt="" class="d-md-none" src="/assets/img/logo.svg" />
            <img alt="" class="d-none d-md-inline-block my-2" src="/assets/img/logo_big.svg" />
        </div>
    </header>
    <div class="wrap" role="document">
        <div class="content">
            <div class="container pt-2">
                <p class="text-center pt-2 mb-0 mb-md-2 fw-600 ft__06">Twoja aktywność została potwierdzona</p>
                <div class="text-center py-2">
                    <img src="/assets/img/ico_activate.svg" alt="" style="width:100px ;" />
                </div>
                <a class="btn__primary pointer" [routerLink]="" (click)="przejdzDoAplikacji()">Przejdź do aplikacji</a>


                <div class="text-center mt-4">
                    <a class="ft__card_link text-green " [routerLink]="" (click)="pokazModalPolecenia()">Poleć nas</a>
                </div>

                <p class="text-center pt-4 mb-0 mb-md-2 fw-600 ft__06">Sprawdź aplikację naszego partnera</p>
                <div class="text-center py-2">
                    <a href="https://motoasysta.pl" target="_blank">
                        <img src="/assets/img/motoasysta_logo.svg" alt=""
                            style="width:120px ;cursor: pointer; padding-left: 15px;" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>