import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { LogowanieService } from 'src/app/sekcje/konto/serwisy/logowanie.service';
import { ZalogujPrzezFbModel } from 'src/app/sekcje/konto/modele/logowanie/zalogujPrzezFbModel';
import { BazowyComponent } from '../../../@wspolne/komponenty/bazowy.component';
import { Router } from '@angular/router';
import { ZalogujPrzezGoogleModel } from 'src/app/sekcje/konto/modele/logowanie/zalogujPrzezGoogleModel';
import { DaneZalogowanegoUzytkownika } from 'src/app/sekcje/konto/modele/logowanie/daneZalogowanegoUzytkownika';
import { ZalogujModel } from 'src/app/sekcje/konto/modele/logowanie/zalogujModel';
import { WyslijKodWeryfikacyjnyPonownieModel } from 'src/app/sekcje/konto/modele/rejestracja/wyslijKodWeryfikacyjnyPonownieModel';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BladModelu } from 'src/app/sekcje/@wspolne/modele/bladModelu';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../@wspolne/serwisy/auth.service';
import { RejestracjaService } from '../../serwisy/rejestracja.service';
import { Ciasteczko } from 'src/app/sekcje/@wspolne/modele/ciasteczka';

@Component({
  selector: 'app-logowanie',
  templateUrl: './logowanie.component.html'
})

export class LogowanieComponent extends BazowyComponent implements OnInit {
  public zalogujModel = new ZalogujModel();
  public dokonczLogowaniePrzezFbModel = new ZalogujPrzezFbModel();

  constructor(
    private socialAuthService: SocialAuthService,
    private logowanieSerwis: LogowanieService,
    public router: Router,
    private rejestracjaSerwis: RejestracjaService,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
  }

  public zaloguj(): void {
    this.logowanieSerwis.zalogujPrzezEmail(this.zalogujModel).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.zapiszDaneOLogowaniu(rezultat.element);
          return;
        }
        else if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    );
  }


  public zalogujPrzezFb(fbModal): void {
    const fbLoginOptions = {
      scope: 'email'
    };
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID, fbLoginOptions).then(
      (daneUzytkownika) => {
        if (daneUzytkownika == null) {
          return;
        }
        if (daneUzytkownika.id !== "") {
          var model = new ZalogujPrzezFbModel();
          model.email = daneUzytkownika.email;
          model.facebookId = daneUzytkownika.id;

          this.logowanieSerwis.zalogujPrzezFb(model).subscribe(
            (rezultat) => {
              if (this.czyRezultatOk(rezultat)) {

                if (rezultat.element.brakMaila) {
                  this.pokazModal(fbModal);
                  return;
                }

                this.zapiszDaneOLogowaniu(rezultat.element);
              }
              this.wyswietlKomunikat(rezultat.wiadomosc, true);
            }
          );

        }
      }
    );
  }

  public dokonczLogowaniePrzezFb() {
    this.socialAuthService.authState.subscribe((uzytkownik) => {
      this.dokonczLogowaniePrzezFbModel.facebookId = uzytkownik.id;
    });

    this.logowanieSerwis.zalogujPrzezFb(this.dokonczLogowaniePrzezFbModel).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          if (rezultat.element.brakMaila) {
            this.bledyWalidacji = new Array();
            var blad = new BladModelu("Niepoprawny email", "email");
            this.bledyWalidacji.push(blad);
          }
          else {
            this.schowjModal();
            this.zapiszDaneOLogowaniu(rezultat.element);
          }
          return;
        }
        else if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    );
  }

  zalogujPrzezGoogle(): void {
    try {

      this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
        (daneUzytkownika) => {
          this.pokazLadowacz();
          if (daneUzytkownika == null) {
            return;
          }
          if (daneUzytkownika.id !== "") {
            var model = new ZalogujPrzezGoogleModel();
            model.email = daneUzytkownika.email;
            model.googleId = daneUzytkownika.id;

            this.logowanieSerwis.zalogujPrzezGoogle(model).subscribe(
              (rezultat) => {
                this.schowajLadowacz();
                if (!this.czyRezultatOk(rezultat)) {
                  this.wyswietlKomunikat(rezultat.wiadomosc, true);
                  return;
                }
                this.zapiszDaneOLogowaniu(rezultat.element);

                //this.przeniesNaEkranPoZalogowaniu(rezultat.element);
              }
            );
          }
        }
      );
    } catch (blad) {

    }
  }

  public wyslijLinkPonownie() {
    try {
      var model = new WyslijKodWeryfikacyjnyPonownieModel();
      model.email = this.zalogujModel.email;

      this.rejestracjaSerwis.wyslijLinkPonownie(model).subscribe(
        (rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.wyswietlKomunikat("Wysłano link aktywacyjny")
            return;
          }

          this.wyswietlKomunikat(rezultat.wiadomosc, true);
          return;
        }
      );
    } catch (blad) {
    }
  }

  private zapiszDaneOLogowaniu(dane: DaneZalogowanegoUzytkownika) {
    this.authService.logIn(Ciasteczko.DaneZalogowanegoUzytkownika, dane);
  }


}
