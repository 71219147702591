import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { RequestBazowy } from 'src/app/sekcje/@wspolne/modele/requestBazowy';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ZapiszKonfiguracjeKomunikatowModel } from '../../modele/zapiszKonfiguracjeKomunikatowModel';
import { KonfiguracjaService } from '../../serwisy/konfiguracja.service';

@Component({
  selector: 'app-konfiguracja',
  templateUrl: './konfiguracja.component.html'
})
export class KonfiguracjaComponent extends BazowyComponent implements OnInit {

  public model: ZapiszKonfiguracjeKomunikatowModel = new ZapiszKonfiguracjeKomunikatowModel();
  public czyZaladowanoDane: boolean;
  private subscription: Subscription;

  constructor(
    private konfiguracjaSerwis: KonfiguracjaService,
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDaneDoEkranu();

    });
  }

  ngOnDestroy() {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

  private pobierzDaneDoEkranu() {
    this.konfiguracjaSerwis.pobierzDaneDoEkranu(new RequestBazowy()).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.model.pierwszePrzypomnienie = rezultat.element.pierwszePrzypomnienie;
        this.model.drugiePrzypomnienie = rezultat.element.drugiePrzypomnienie;
        this.model.trzeciePrzypomnienie = rezultat.element.trzeciePrzypomnienie;
        this.czyZaladowanoDane = true;
        return;
      } else {
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    }
    );
  }

  public zapisz() {
    this.konfiguracjaSerwis.zapiszKonfiguracjeKomunikatow(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat("Dane zostały zapisane");
        this.bledyWalidacji = undefined;

        const source = interval(3000);
        this.subscription = source.subscribe(val => {
          this.router.navigate(['powiadomienia']);
        }
        );
        return;
      } else {
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
        }
        if (rezultat.wiadomosc) {
          this.wyswietlKomunikat(rezultat.wiadomosc, true);
        }
      }
    }
    );
  }
}
