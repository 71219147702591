<div class="modal-content">
    <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.dismiss()"></button>

        <p class="text-center ft__05 mb-3 pb-0 pt-2">
            Limit powiadomień
        </p>

        <p class="text-center">Wykorzystałeś limit powiadomień. Zmień plan abonamentowy aby dodać kolejne powiadomienie.
        </p>


        <div class="d-flex justify-content-around pb-3">
            <div class="itm">
                <button class="btn__primary btn__primary--cancel" (click)="przejdzDoAbonamentow()">
                    Abonamenty
                </button>
            </div>
            <div class="itm">
                <button class="btn__primary btn__primary--cancel" (click)="activeModal.dismiss()">
                    Zamknij
                </button>
            </div>
        </div>
    </div>
</div>