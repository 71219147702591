import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormularzKontaktowyModalComponent } from 'src/app/sekcje/kontakt/komponenty/formularz-kontaktowy-modal/formularz-kontaktowy-modal.component';
import { PolecModalComponent } from 'src/app/sekcje/polec/komponenty/polec-modal/polec-modal.component';
import { PolecNasModel } from 'src/app/sekcje/polec/modele/polecNasModel';
import { ModalOpcje } from '../../modele/modal-opcje.ogolne';
import { AuthService } from '../../serwisy/auth.service';
import { MenuService } from '../../serwisy/menu.service';

@Component({
  selector: 'app-nawigacja',
  templateUrl: './nawigacja.component.html'
})
export class NawigacjaComponent implements OnInit, AfterViewInit {

  constructor(
    public authService: AuthService,
    private router: Router,
    public menuService: MenuService,
    private modalService: NgbModal) { }
  ngAfterViewInit(): void {
    // wzięte z app.js od Adriana; koniecznie tutaj, bo poniższa część w app.js jest wykonywana zanim menu pojawi się w DOM
    const burgerMenu = document.getElementById("burger");
    const navbarMenu = document.getElementById("menu");
    const navbrandMenu = document.querySelector(".navbrand");
    // Responsive Navbar Toggle
    if (burgerMenu !== null) {
      burgerMenu.addEventListener("click", function () {
        navbarMenu.classList.toggle("active");
        burgerMenu.classList.toggle("active");
        navbrandMenu.classList.toggle("active");
      });
    }
  }

  ngOnInit(): void {
  }

  pokazModalPolecenia() {
    this.modalService.open(PolecModalComponent, new ModalOpcje(true, 'static'));
  }
  pokazModalKontaktu() {
    this.modalService.open(FormularzKontaktowyModalComponent, new ModalOpcje(true, 'static'));
  }
}
