import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SerwisBazowy } from 'src/app/serwisy/bazowy.service';
import { PojedynczyElementResponse } from '../../@wspolne/modele/pojedynczyElement';
import { RequestBazowy } from '../../@wspolne/modele/requestBazowy';
import { ResponseBazowy } from '../../@wspolne/modele/responseBazowy';
import { AuthService } from '../../@wspolne/serwisy/auth.service';
import { ErrorService } from '../../@wspolne/serwisy/error.service';
import { DaneZalogowanegoUzytkownika } from '../modele/logowanie/daneZalogowanegoUzytkownika';
import { EdytujDaneKontaModel } from '../modele/profil/edytujDaneKontaModel';
import { PobierzDaneKontaRezultat } from '../modele/profil/pobierzDaneKontaRezultat';
import { UzupelnijDaneKontaModel } from '../modele/profil/uzupelnijDaneKontaModel';
import { WeryfikujKodSmsDoZmianyNumeruTelefonuFormularz } from '../modele/profil/weryfikuj-kod-sms-do-zmiany-numeru-telefonu-formularz.model';
import { WeryfikujZmianeAdresuEmailFormularz } from '../modele/profil/weryfikuj-zmiane-adresu-email-formularz.model';
import { ZmienAdresEmailFormularz } from '../modele/profil/zmien-adres-email-formularz.model';
import { ZmienNumerTelefonuFormularz } from '../modele/profil/zmien-numer-telefonu-formularz.model';

@Injectable({
  providedIn: 'root'
})
export class ProfilService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService) {
    super(httpClient, authService, errorService);
  }

  public pobierzDaneKonta(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, PojedynczyElementResponse<PobierzDaneKontaRezultat>>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.PobierzDaneKonta}`, model);
  }

  public edytujDaneKonta(model: EdytujDaneKontaModel) {
    return this.wykonajAkcje<EdytujDaneKontaModel, PojedynczyElementResponse<PobierzDaneKontaRezultat>>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.EdytujDaneKonta}`, model);
  }

  public zmienAdresEmailIWyslijPotwierdzenie(formularz: ZmienAdresEmailFormularz) {
    return this.wykonajAkcje<ZmienAdresEmailFormularz, ResponseBazowy>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.ZmienAdresEmailIWyslijPotwierdzenie}`, formularz);
  }

  public WeryfikujZmianeAdresuEmail(formularz: WeryfikujZmianeAdresuEmailFormularz) {
    return this.wykonajAkcje<WeryfikujZmianeAdresuEmailFormularz, PojedynczyElementResponse<DaneZalogowanegoUzytkownika>>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.WeryfikujZmianeAdresuEmail}`, formularz);
  }

  public ZmienNumerTelefonuIWyslijKodSms(formularz: ZmienNumerTelefonuFormularz) {
    return this.wykonajAkcje<ZmienNumerTelefonuFormularz, ResponseBazowy>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.ZmienNumerTelefonuIWyslijKodSms}`, formularz);
  }

  public WeryfikujKodSmsDoZmianyNumeruTelefonu(formularz: WeryfikujKodSmsDoZmianyNumeruTelefonuFormularz) {
    return this.wykonajAkcje<WeryfikujKodSmsDoZmianyNumeruTelefonuFormularz, ResponseBazowy>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.WeryfikujKodSmsDoZmianyNumeruTelefonu}`, formularz);
  }
  public uzupelnijDaneKonta(model: UzupelnijDaneKontaModel) {
    return this.wykonajAkcje<UzupelnijDaneKontaModel, ResponseBazowy>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.UzupelnijDaneKonta}`, model);
  }

  public usunKonto(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, ResponseBazowy>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.UsunKonto}`, model);
  }
}

enum ProfilAdresy {
  Controller = "Profil",
  ZmienAdresEmailIWyslijPotwierdzenie = "ZmienAdresEmailIWyslijPotwierdzenie",
  WeryfikujZmianeAdresuEmail = "WeryfikujZmianeAdresuEmail",
  ZmienNumerTelefonuIWyslijKodSms = "ZmienNumerTelefonuIWyslijKodSms",
  WeryfikujKodSmsDoZmianyNumeruTelefonu = "WeryfikujKodSmsDoZmianyNumeruTelefonu",
  PobierzDaneKonta = "PobierzDaneKonta",
  EdytujDaneKonta = "EdytujDaneKonta",
  UzupelnijDaneKonta = "UzupelnijDaneKonta",
  UsunKonto = "UsunKonto"
}