import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/sekcje/@wspolne/komponenty/bazowy.component';
import { ModalOpcje } from 'src/app/sekcje/@wspolne/modele/modal-opcje.ogolne';
import { AuthService } from 'src/app/sekcje/@wspolne/serwisy/auth.service';
import { PolecModalComponent } from 'src/app/sekcje/polec/komponenty/polec-modal/polec-modal.component';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { DodajPowiadomienieModel } from '../../modele/dodajPowiadomienieModel';
import { PowiadomieniaService } from '../../serwisy/powiadomienia.service';
import { PowiadomieniaPotwierdzDaneModalComponent } from '../powiadomienia-potwierdz-dane-modal/powiadomienia-potwierdz-dane-modal.component';
import { PowiadomieniaZamknijEdycjeModalComponent } from '../powiadomienia-zamknij-edycje-modal/powiadomienia-zamknij-edycje-modal.component';

@Component({
  selector: 'app-powiadomienia-dodaj-modal',
  templateUrl: './powiadomienia-dodaj-modal.component.html',
  styleUrls: ['./powiadomienia-dodaj-modal.component.css']
})
export class PowiadomieniaDodajModalComponent extends BazowyComponent implements OnInit {

  public model = new DodajPowiadomienieModel();
  public ukryjModal: boolean;
  private potwierdzZamkniecie: boolean;

  constructor(
    public powiadomieniaSerwis: PowiadomieniaService,
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
  }

  public zapisz(): void {
    this.powiadomieniaSerwis.dodajPowiadomienie(this.model).subscribe(
      (rezultat) => {
        this.schowajLadowacz();
        if (this.czyRezultatOk(rezultat)) {

          if (this.model.czyPotwierdzonoDane) {
            this.wyswietlKomunikat('Powiadomienie zostało dodane');
            this.activeModal.close(true);
            if (rezultat.element.czyPierwszePowiadomienie) {
              this.modalService.open(PolecModalComponent, new ModalOpcje(true));
            }
            return;
          }
          else {
            this.ukryjModal = true;
            this.modalReference = this.modalService.open(PowiadomieniaPotwierdzDaneModalComponent, { centered: true });
            this.modalReference.componentInstance.telefon = this.model.telefon;
            this.modalReference.componentInstance.email = this.model.email;

            this.modalReference.result.then(tak => {
              if (tak) {
                this.model.czyPotwierdzonoDane = true;
                this.zapisz();
              }
            }, onRejected => {
              this.ukryjModal = false;
            });
          }
        }
        else {
          if (this.bladModelu(rezultat)) {
            this.bledyWalidacji = rezultat.bledyModelu;
          }
          if (rezultat.wiadomosc) {
            this.wyswietlKomunikat(rezultat.wiadomosc, true);
          }
        }
      }
    );
  }

  inputChanged() {
    this.potwierdzZamkniecie = true;
  }

  zamknijModal() {
    if (!this.potwierdzZamkniecie) {
      this.activeModal.dismiss();
      return;
    }

    this.modalReference = this.modalService.open(PowiadomieniaZamknijEdycjeModalComponent, new ModalOpcje(true, 'static'));

    this.modalReference.result.then(tak => {
      if (tak) {
        this.activeModal.dismiss();
      }
    }, onRejected => { });
  }
}
