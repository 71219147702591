import { Injectable } from '@angular/core';
import { ZalogujPrzezFbModel } from 'src/app/sekcje/konto/modele/logowanie/zalogujPrzezFbModel';
import { HttpClient } from '@angular/common/http';
import { SerwisBazowy } from '../../../serwisy/bazowy.service';
import { ZalogujPrzezFbRezultat } from 'src/app/sekcje/konto/modele/logowanie/zalogujPrzezFbRezultat';
import { ZalogujPrzezGoogleModel } from 'src/app/sekcje/konto/modele/logowanie/zalogujPrzezGoogleModel';
import { DaneZalogowanegoUzytkownika } from 'src/app/sekcje/konto/modele/logowanie/daneZalogowanegoUzytkownika';
import { ZalogujModel } from 'src/app/sekcje/konto/modele/logowanie/zalogujModel';
import { PojedynczyElementResponse } from '../../@wspolne/modele/pojedynczyElement';
import { RequestBazowy } from '../../@wspolne/modele/requestBazowy';
import { ErrorService } from '../../@wspolne/serwisy/error.service';
import { AuthService } from '../../@wspolne/serwisy/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LogowanieService extends SerwisBazowy {
  
  constructor(
    httpClient: HttpClient, 
    authService: AuthService,
    errorService: ErrorService) {
    super(httpClient, authService, errorService);
  }

  /**
   * zalogujPrzezFb
   */
  zalogujPrzezFb(model: ZalogujPrzezFbModel) {
    return this.wykonajAkcje<ZalogujPrzezFbModel, PojedynczyElementResponse<ZalogujPrzezFbRezultat>>(this.AdresApi + "logowanie/zalogujPrzezFb", model);

  }
  zalogujPrzezGoogle(model: ZalogujPrzezGoogleModel) {
    return this.wykonajAkcje<ZalogujPrzezGoogleModel, PojedynczyElementResponse<DaneZalogowanegoUzytkownika>>(this.AdresApi + "logowanie/zalogujPrzezGoogle", model);

  }

  zalogujPrzezEmail(model: ZalogujModel) {
    return this.wykonajAkcje<ZalogujModel, PojedynczyElementResponse<DaneZalogowanegoUzytkownika>>(this.AdresApi + "logowanie/zaloguj", model);

  }

  pobierzDaneZalogowanegoUzytkownika(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, PojedynczyElementResponse<DaneZalogowanegoUzytkownika>>
      (this.AdresApi + "logowanie/PobierzDaneZalogowanegoUzytkownika", model);

  }
}
