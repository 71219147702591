<app-naglowek-non-auth></app-naglowek-non-auth>
<div class="wrap" role="document">
    <div class="content">
        <div class="container pt-5">
            <p class="text-center pt-4 mb-0 mb-md-2 fw-600 ft__06">Adres email został zmieniony</p>
            <div class="text-center py-4">
                <img src="/assets/img/mail.svg" alt="" />
            </div>
            <a class="btn__primary pointer" [routerLink]="" (click)="onPrzejdzDoAplikacjiClick()" >Przejdź do aplikacji</a>
        </div>
    </div>
</div>