import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SerwisBazowy } from 'src/app/serwisy/bazowy.service';
import { IdModel } from '../../@wspolne/modele/id.model';
import { PojedynczyElementResponse } from '../../@wspolne/modele/pojedynczyElement';
import { RequestBazowy } from '../../@wspolne/modele/requestBazowy';
import { ResponseBazowy } from '../../@wspolne/modele/responseBazowy';
import { AuthService } from '../../@wspolne/serwisy/auth.service';
import { ErrorService } from '../../@wspolne/serwisy/error.service';
import { DodajPowiadomienieModel } from '../modele/dodajPowiadomienieModel';
import { DodajPowiadomienieRezultat } from '../modele/dodajPowiadomienieRezultat';
import { EdytujPowiadomienieModel } from '../modele/edytujPowiadomienieModel';
import { PowiadomieniaPobierzDaneRezultat } from '../modele/powiadomieniaPobierzDaneRezultat';


@Injectable({
  providedIn: 'root'
})
export class PowiadomieniaService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService) {
    super(httpClient, authService, errorService);
  }

  pobierzDaneDoEkranuPowiadomienUzytkownika(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, PojedynczyElementResponse<PowiadomieniaPobierzDaneRezultat>>
      (this.AdresApi + "Powiadomienia/PobierzDaneDoEkranuPowiadomienUzytkownika", model);

  }

  dodajPowiadomienie(model: DodajPowiadomienieModel) {
    return this.wykonajAkcje<DodajPowiadomienieModel, PojedynczyElementResponse<DodajPowiadomienieRezultat>>
      (this.AdresApi + "Powiadomienia/DodajPowiadomienie", model);

  }

  edytujPowiadomienie(model: EdytujPowiadomienieModel) {
    return this.wykonajAkcje<EdytujPowiadomienieModel, ResponseBazowy>
      (this.AdresApi + "Powiadomienia/EdytujPowiadomienie", model);

  }

  usunPowiadomienie(model: IdModel) {
    return this.wykonajAkcje<IdModel, ResponseBazowy>
      (this.AdresApi + "Powiadomienia/UsunPowiadomienie", model);

  }
}
