<div class="modal-content" *ngIf="czyZaladowanoDane">
    <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.dismiss()"></button>

        <p class="text-center ft__05 mb-3 pb-0 pt-2">Zmiana abonamentu</p>
        <p class="text-center ft__16bold">{{pakietDoZmiany}}</p>
        <p>Kwota do zapłaty <strong>{{formatujCene(daneDoEkranuZmianyPlanu.kwota)}}</strong></p>
        <small>Kwota zawiera dopłatę za pozostałe {{daneDoEkranuZmianyPlanu.pozostaleDniAbonamentu}} dni niższego
            abonamentu</small> <br>
        <small *ngIf="!karta">Płatność jednorazowa. Nowy plan abonamentowy nie będzie odnawiany cyklicznie.</small>
        <div class="py-2">

            <div class="py-4" *ngIf="!karta">
                <section _ngcontent-rob-c115="" class="container-card">
                    <div _ngcontent-rob-c115="" class="card-container">
                        <aside _ngcontent-rob-c115="">Numer Karty</aside>
                        <div _ngcontent-rob-c115="" id="payu-card-number"
                            class="payu-card-form payu-secure-form payu-secure-form-empty">
                        </div>
                        <div _ngcontent-rob-c115="" class="card-details clearfix">
                            <div _ngcontent-rob-c115="" class="expiration">
                                <aside _ngcontent-rob-c115="">Ważna do</aside>
                                <div _ngcontent-rob-c115="" id="payu-card-date"
                                    class="payu-card-form payu-secure-form payu-secure-form-empty">
                                </div>
                            </div>
                            <div _ngcontent-rob-c115="" class="cvv">
                                <aside _ngcontent-rob-c115="">CVV</aside>
                                <div _ngcontent-rob-c115="" id="payu-card-cvv"
                                    class="payu-card-form payu-secure-form payu-secure-form-empty">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div _ngcontent-rob-c115="" id="responseTokenize" style="color: black !important;"></div>
                </section>
            </div>

            <div class="py-2 d-flex justify-content-center mx-3 mx-md-0" *ngIf="karta">
                <div class="card-container__main d-flex flex-column justify-content-between">
                    <div class="card-container--number">xxxx xxxx xxxx {{pobierzOstatnieCyfryKarty()}}</div>
                    <div class="card-container--date">
                        {{pobierzMiesiacKarty()}}/{{pobierzRokKarty()}}
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="card-container--name"> </div>
                        <div class="logo">
                            <img width="64" src="{{pobierzUrlIkonyDostawcyKarty()}}" alt="" class="brandKarty">
                        </div>
                    </div>
                </div>
            </div>


            <div class="text-center">
                <small>Nie przechowujemy danych Twojej karty. Informacje podane w formularzu zostaną przekazane do
                    systemu
                    płatności.</small>
            </div>

            <div class="d-flex flex-column pb-3 pt-3">
                <div class="itm pb-2">
                    <button id="tokenizeButton" _ngcontent-rob-c115="" class="btn__primary" *ngIf="karta"
                        (click)="przejdzDoPlatnosci()">
                        Przejdź do płatności
                    </button>
                    <button id="tokenizeButton" _ngcontent-rob-c115="" class="btn__primary" *ngIf="!karta"
                        (click)="tokenizuj()">
                        Przejdź do płatności
                    </button>
                </div>
                <div class="itm">
                    <button class="btn__primary btn__primary--cancel" (click)="activeModal.dismiss()">
                        Zamknij
                    </button>
                </div>
            </div>

        </div>
    </div>