import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SerwisBazowy } from 'src/app/serwisy/bazowy.service';
import { PojedynczyElementResponse } from '../../@wspolne/modele/pojedynczyElement';
import { RequestBazowy } from '../../@wspolne/modele/requestBazowy';
import { ResponseBazowy } from '../../@wspolne/modele/responseBazowy';
import { AuthService } from '../../@wspolne/serwisy/auth.service';
import { ErrorService } from '../../@wspolne/serwisy/error.service';
import { DaneZalogowanegoUzytkownika } from '../../konto/modele/logowanie/daneZalogowanegoUzytkownika';
import { PotwierdzAktywnoscModel } from '../../konto/modele/potwierdzAktywnoscModel';
import { PobierzKonfiguracjeKomunikatowRezultat } from '../modele/pobierzKonfiguracjeKomunikatowRezultat';
import { ZapiszKonfiguracjeKomunikatowModel } from '../modele/zapiszKonfiguracjeKomunikatowModel';

@Injectable({
  providedIn: 'root'
})
export class KonfiguracjaService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService) {
    super(httpClient, authService, errorService);
  }

  pobierzDaneDoEkranu(model: RequestBazowy) {
    return this.wykonajAkcje<RequestBazowy, PojedynczyElementResponse<PobierzKonfiguracjeKomunikatowRezultat>>
      (this.AdresApi + "Komunikaty/PobierzKonfiguracjeKomunikatow", model);

  }

  zapiszKonfiguracjeKomunikatow(model: ZapiszKonfiguracjeKomunikatowModel) {
    return this.wykonajAkcje<ZapiszKonfiguracjeKomunikatowModel, ResponseBazowy>
      (this.AdresApi + "Komunikaty/ZapiszKonfiguracjeKomunikatow", model);

  }

  potwierdzAktywnosc(model: PotwierdzAktywnoscModel) {
    return this.wykonajAkcje<PotwierdzAktywnoscModel, ResponseBazowy>
      (this.AdresApi + "Komunikaty/PotwierdzAktywnosc", model);

  }
}
