<div class="modal-body">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss()"></button>

    <p class="text-center ft__05 mb-3 pb-0 pt-2">
        Potwierdzenie zmiany telefonu
    </p>
    <p class="text-center pb-3">Wprowadź 5 cyfrowy kod weryfikacyjny z SMS.</p>

    <form action="">
        <div class="f-input pb-3" [ngClass]="czyBladWalidacyjny('kodSms')?'error':''">
            <input type="text" placeholder="Kod weryfikacyjny" name="kodSms"
                [(ngModel)]="weryfikujKodSmsDoZmianyNumeruTelefonuFormularz.kodSms" />
            <div class="pt-2" [hidden]="!czyBladWalidacyjny('kodSms')"> {{pobierzBladWalidacyjny("kodSms")}}</div>
        </div>
        <div class="d-flex flex-column mt-3">
            <button class="btn__primary" (click)="onWeryfikujKodSms()">Weryfikuj</button> <!-- disbled -->
            <button class="btn__cancel mt-3" (click)="activeModal.dismiss()">Zamknij</button>
        </div>
    </form>
</div>