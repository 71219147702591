import { Time } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-godziny-modal',
  templateUrl: './godziny-modal.component.html',
  styleUrls: ['./godziny-modal.component.css']
})
export class GodzinyModalComponent implements OnInit {
  @Input() godzina: Time;
  wybranaGodzna: Time;
  czyWyborGodziny: boolean = true;
  czyWpisywanieGodziny: boolean = false;

  godzinaRecznie: number;
  minutyRecznie: number;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.godzina == undefined) {
        this.wybranaGodzna = JSON.parse(`{"hours":0,"minutes":0}`);
      }
      else {
        this.wybranaGodzna = JSON.parse(JSON.stringify(this.godzina));
      }

      this.godzinaRecznie = this.wybranaGodzna.hours;
      this.minutyRecznie = this.wybranaGodzna.minutes;
    });
  }

  czyGodzinaPrzed12() {
    if (this.wybranaGodzna == undefined || this.wybranaGodzna.hours == 0) {
      return false;
    }

    return this.wybranaGodzna.hours <= 12;
  }

  czyWybranaGodzina(godzina: number) {
    if (this.wybranaGodzna == undefined) {
      return false;
    }

    return this.wybranaGodzna.hours == godzina;
  }

  kalkulujObrotGodzin() {
    if (this.wybranaGodzna == undefined || this.wybranaGodzna.hours == 0 || this.wybranaGodzna.hours == 12) {
      return 0;
    }
    if (this.wybranaGodzna.hours > 12) {
      return (this.wybranaGodzna.hours - 12) * 30;
    }
    else {
      return this.wybranaGodzna.hours * 30;
    }
  }

  dajGodzine() {
    if (this.wybranaGodzna == undefined) {
      return "00";
    }
    var godzina = this.wybranaGodzna.hours < 10 ? "0" + this.wybranaGodzna.hours : this.wybranaGodzna.hours;

    return godzina;
  }
  dajMinuty() {
    if (this.wybranaGodzna == undefined) {
      return "00";
    }
    var minuty = this.wybranaGodzna.minutes < 10 ? "0" + this.wybranaGodzna.minutes : this.wybranaGodzna.minutes;

    return minuty;
  }

  zamknijModal() {
    this.activeModal.dismiss();
  }

  wybierzGodzine(godzina: number) {
    this.wybranaGodzna.hours = godzina;
    this.czyWyborGodziny = false;
    this.godzinaRecznie = godzina;
  }

  potwierdzWybor() {
    if (this.czyWpisywanieGodziny) {
      this.wybranaGodzna.hours = this.godzinaRecznie;
      this.wybranaGodzna.minutes = this.minutyRecznie;

      this.activeModal.close(this.wybranaGodzna);
    }
    else {
      this.activeModal.close(this.wybranaGodzna);
    }
  }

  kalkulujObrotMinut() {
    if (this.wybranaGodzna == undefined || this.wybranaGodzna.minutes == 0) {
      return 0;
    }
    return this.wybranaGodzna.minutes / 5 * 30;
  }

  czyWybranaMinuta(minuta: number) {
    if (this.wybranaGodzna == undefined) {
      return false;
    }

    return this.wybranaGodzna.minutes == minuta;
  }

  wybierzMinute(minuta: number) {
    this.wybranaGodzna.minutes = minuta;
    this.potwierdzWybor();
    this.minutyRecznie = minuta;
  }

  przejdzDoWpisywaniaRecznego() {
    this.czyWpisywanieGodziny = true;
  }
  przejdzDoZegara() {
    this.czyWpisywanieGodziny = false;
  }
}
